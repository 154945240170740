import React from 'react';
import { Link } from 'react-router-dom';
import Footer from "./Footer";
import Navbar from "./Navbar";
import RelatedProject from "./related";

const SoftwareArchitectDesignPage = () => {
    const keyword = "AI"; // Example: Filter projects with 'AI' in the title

    return (
        <div>
            <Navbar/>
            {/* Hero Section */}
            <section className="relative w-full h-96 bg-cover bg-center" style={{ backgroundImage: "url('https://example.com/your-banner-image.jpg')" }}>
                <div className="absolute inset-0 bg-black opacity-50"></div>
                <div className="absolute inset-0 flex flex-col justify-center items-center text-white text-center px-6">
                    <h1 className="text-4xl md:text-6xl font-semibold leading-tight mb-4">Building Scalable, Maintainable Systems Through Thoughtful Architecture</h1>
                    <p className="text-lg md:text-2xl mb-6">Crafting robust software architecture and design that ensures your application is built to scale, adapt, and evolve with your business needs.</p>
                    {/*<Link to="/contact" className="bg-[#0EA288] text-white font-semibold py-2 px-6 rounded-lg hover:bg-[#0EA288]/90 transition duration-300">*/}
                    {/*    Get in Touch*/}
                    {/*</Link>*/}
                </div>
            </section>

            {/* Introduction Section */}
            <section className="container mx-auto px-6 py-12">
                <h2 className="text-3xl font-semibold text-[#0EA288] mb-6">Why Software Architecture and Design Matters</h2>
                <p className="text-justify text-lg text-[#4F6370] mb-6">
                    A strong foundation starts with solid architecture and design. At NovaSync, we work closely with you to design systems that are scalable, maintainable, and optimized for performance. Our expert architects ensure that your software can grow as your business evolves, providing you with the flexibility to stay ahead of the competition. Whether it’s microservices, monolithic architectures, or event-driven systems, we provide solutions tailored to your business needs.
                </p>

                {/* Key Features Section */}
                <h3 className="text-2xl font-semibold text-[#0EA288] mb-6">Our Software Architecture & Design Solutions</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">Scalable Architecture Design</h4>
                        <p className="text-justify text-[#4F6370]">
                            We design systems that can scale seamlessly, ensuring they can handle increased traffic, data, and users without compromising performance.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">Microservices Architecture</h4>
                        <p className="text-justify text-[#4F6370]">
                            For complex applications, we adopt microservices architecture to decouple components, enabling flexibility, scalability, and ease of maintenance.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">Design Patterns Implementation</h4>
                        <p className="text-justify text-[#4F6370]">
                            We utilize proven design patterns that provide common solutions to recurring design problems, ensuring the system is efficient and easily maintainable.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">Cloud-Native Design</h4>
                        <p className="text-justify text-[#4F6370]">
                            Our design approach embraces cloud-native principles, ensuring your application is optimized for cloud environments, ensuring scalability, flexibility, and cost-efficiency.
                        </p>
                    </div>
                </div>
            </section>

            {/* Technologies Section */}
            <section className="bg-[#f9f9f9] py-12">
                <div className="container mx-auto px-6">
                    <h3 className="text-2xl font-semibold text-[#0EA288] mb-6">Technologies We Use in Software Architecture & Design</h3>
                    <p className="text-justify text-lg text-[#4F6370] mb-6">
                        We leverage the latest tools and technologies to design software architectures that meet your business requirements:
                    </p>
                    <ul className="text-justify list-disc pl-6 text-[#4F6370]">
                        <li>Cloud Platforms: AWS, Azure, Google Cloud</li>
                        <li>Architecture Styles: Microservices, Monolithic, Serverless</li>
                        <li>Containers & Orchestration: Docker, Kubernetes</li>
                        <li>Databases: SQL, NoSQL, Graph Databases</li>
                        <li>APIs: RESTful APIs, GraphQL</li>
                        <li>Message Queues: Kafka, RabbitMQ, ActiveMQ</li>
                    </ul>
                </div>
            </section>

            {/* Design Process Section */}
            <section className="container mx-auto px-6 py-12">
                <h3 className="text-2xl font-semibold text-[#0EA288] mb-6">Our Software Architecture & Design Process</h3>
                <div className="space-y-6">
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">1. Requirement Analysis</h4>
                        <p className="text-justify text-[#4F6370]">
                            We begin by understanding your business goals, the project requirements, and potential challenges to ensure a solid foundation for the design process.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">2. Architecture Design</h4>
                        <p className="text-justify text-[#4F6370]">
                            Our architects design the system architecture, ensuring scalability, flexibility, and robustness in line with your specific requirements.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">3. Technology Stack Selection</h4>
                        <p className=" text-justify text-[#4F6370]">
                            We select the best-fit technologies, frameworks, and tools that align with your business needs, ensuring performance, security, and cost-effectiveness.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">4. Prototyping & Proof of Concept</h4>
                        <p className="text-justify text-[#4F6370]">
                            We create prototypes and proof of concepts to test the feasibility of the architecture and ensure it meets the functional and non-functional requirements.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">5. Iterative Refinement & Final Design</h4>
                        <p className="text-justify text-[#4F6370]">
                            We iteratively refine the architecture design, considering feedback and evolving requirements, before finalizing the architecture blueprint.
                        </p>
                    </div>
                </div>
            </section>
            <RelatedProject  keyword={keyword} />
            <Footer />
        </div>
    );
};

export default SoftwareArchitectDesignPage;
