import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaPlus } from 'react-icons/fa';
import CeoFahadImage from '../assets/images/CeoFahad.png';
import DocIcon from '../assets/images/docIcon.png';

const EditUser = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [showBankDetails, setShowBankDetails] = useState(false);
  const [showExperiencePopup, setShowExperiencePopup] = useState(false);
  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState('Upload picture');
  const pictureInputRef = useRef(null);
  const resumeInputRef = useRef(null);
  const [pictureFileName, setPictureFileName] = useState('Upload picture');
  const [resumeFileName, setResumeFileName] = useState('Upload resume');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setSidebarOpen(true);
      } else {
        setSidebarOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const handlePictureIconClick = () => {
    pictureInputRef.current.click();
  };

  const handleResumeIconClick = () => {
    resumeInputRef.current.click();
  };

  const handleFileInputChange = (e, setFileName) => {
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name);
    }
  };

  return (
    <div className="flex flex-col md:flex-row min-h-screen bg-gray-100">
      <aside
        className={`fixed z-30 md:z-10 top-0 left-0 md:static transition-transform transform ${
          sidebarOpen ? 'translate-x-0' : '-translate-x-full'
        } w-64 bg-[#0EA288] text-white flex flex-col items-center py-4 h-full md:h-auto`}
      >
        <img src={CeoFahadImage} alt="Fahad Shahzad" className="w-24 h-24 rounded-full mb-4" />
        <h2 className="text-xl font-bold mb-2">Fahad Shahzad</h2>
        <p className="mb-8">CEO</p>
        <nav className="flex flex-col space-y-4 w-full px-4">
          <Link to="/dashboard">
            <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288] transition duration-300">
              Home
            </button>
          </Link>
          <Link to="/attendance">
            <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288] transition duration-300">
              Attendance
            </button>
          </Link>
          <Link to="/leaverequest">
            <button className="w-full py-2 bg-white border-2 border-[#0EA288] rounded hover:bg-[#0EA288] hover:text-white relative font-bold text-[#0EA288] transition duration-300">
              Leave Requests
              <span
                className="absolute -top-1 -right-1 bg-[#0EA288] text-white rounded-full flex items-center justify-center border-2 border-white"
                style={{ width: '24px', height: '24px', lineHeight: '22px' }}
              >
                2
              </span>
            </button>
          </Link>
          <Link to="/salaries">
            <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288] transition duration-300">
              Salaries
            </button>
          </Link>
          <button className="w-full py-2 bg-white border-2 border-[#0C8B76] rounded hover:bg-white font-bold text-black transition duration-300">
            Users Management
          </button>
          <Link to="/all-projects">
            <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288] transition duration-300">
              Project Management
            </button>
          </Link>
          <Link to="/all-customer">
            <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288] transition duration-300">
              Customers
            </button>
          </Link>
          <Link to="/reports">
            <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288] transition duration-300">
              Reports
            </button>
          </Link>
          <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288] transition duration-300">
            Promotion
          </button>
        </nav>
        <div className="flex-1 flex flex-col justify-end w-full px-4 mb-4">
          <button className="w-full py-2 bg-[#0C8B76] rounded hover:bg-white hover:text-[#0C8B76] font-bold text-white transition duration-300">
            Logout
          </button>
        </div>
      </aside>

      <main className="flex-1 p-4 md:p-8 transition-all duration-300">
        <header className="mb-8">
          <div className="flex justify-between items-center">
            <h1 className="text-2xl font-bold">User Management Portal</h1>
            <button
              className="md:hidden fixed top-4 right-4 z-50 bg-white text-[#0EA288] rounded-full p-3 shadow-lg"
              onClick={() => setSidebarOpen(!sidebarOpen)}
              aria-label="Toggle Sidebar"
            >
              <FaBars size={24} />
            </button>
          </div>
        </header>

        <div className="bg-white rounded-lg shadow-md p-6 border border-[#0EA288] text-[#808080]">
          <form className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div className="flex flex-col md:col-span-1">
                <input type="text" placeholder="Full Name" className="p-3 bg-[#D9D9D9] rounded w-full" value="John Doe" />
              </div>
              <div className="flex flex-col md:col-span-1">
                <input type="text" placeholder="Employee ID" className="p-3 bg-[#D9D9D9] rounded w-full" value="EMP12345" />
              </div>
              <div className="flex flex-col md:col-span-1">
                <div className="relative">
                  <input
                    ref={pictureInputRef}
                    type="file"
                    className="hidden"
                    onChange={(e) => handleFileInputChange(e, setPictureFileName)}
                  />
                  <label htmlFor="pictureInput" className="cursor-pointer">
                    <div className="p-3 bg-[#D9D9D9] rounded w-full">Upload picture</div>
                    <img
                      src={DocIcon}
                      alt="Upload picture"
                      className="absolute right-3 top-1/2 transform -translate-y-1/2 h-8 w-8 cursor-pointer"
                      onClick={handlePictureIconClick}
                    />
                  </label>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div className="flex justify-between items-center md:col-span-1">
                <div className="relative flex flex-col md:col-span-1">
                  <select className="p-3 bg-[#D9D9D9] rounded w-[350px]">
                    <option>Employee Department</option>
                    {/* Add more options as needed */}
                  </select>
                </div>
                <FaPlus className="ml-2 w-8 h-8 bg-[#0EA288] text-white rounded-full p-1 font-normal" />
              </div>
              <div className="flex flex-col md:col-span-1">
                <input type="text" placeholder="Employee Position" className="p-3 bg-[#D9D9D9] rounded w-full" value="Senior Developer" />
              </div>
              <div className="flex flex-col md:col-span-1">
                <input type="email" placeholder="Employee Email" className="p-3 bg-[#D9D9D9] rounded w-full" value="john.doe@example.com" />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div className="flex flex-col md:col-span-1">
                <input type="text" placeholder="Employee Cnic" className="p-3 bg-[#D9D9D9] rounded w-full" value="42101-1234567-8" />
              </div>
              <div className="flex flex-col md:col-span-1">
                <input type="text" placeholder="Employee Basic Salary" className="p-3 bg-[#D9D9D9] rounded w-full" value="150,000 PKR" />
              </div>
              <div className="flex flex-col md:col-span-1">
                <input type="text" placeholder="Employee Marital Status" className="p-3 bg-[#D9D9D9] rounded w-full" value="Single" />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div className="flex flex-col md:col-span-1">
                <input type="date" placeholder="Date of Birth" className="p-3 bg-[#D9D9D9] rounded w-full" value="1999-01-01" />
              </div>
              <div className="flex flex-col md:col-span-1">
                <input type="text" placeholder="Employee Phone No" className="p-3 bg-[#D9D9D9] rounded w-full" value="+92 300 1234567" />
              </div>
              <div className="flex flex-col md:col-span-1">
                <input type="text" placeholder="Employee Emergency Contact" className="p-3 bg-[#D9D9D9] rounded w-full" value="+92 300 7654321" />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div className="flex flex-col md:col-span-1">
                <input type="text" placeholder="Employee Address" className="p-3 bg-[#D9D9D9] rounded w-full" value="123 Street, City Name" />
              </div>
              <div className="flex flex-col md:col-span-1">
                <input type="text" placeholder="Employee City" className="p-3 bg-[#D9D9D9] rounded w-full" value="Lahore" />
              </div>
              <div className="flex flex-col md:col-span-1">
                <input type="text" placeholder="Employee Zipcode" className="p-3 bg-[#D9D9D9] rounded w-full" value="54000" />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div className="flex flex-col md:col-span-1">
                <input type="text" placeholder="Employee Ntn" className="p-3 bg-[#D9D9D9] rounded w-full" value="1234567-8" />
              </div>
              <div className="flex flex-col md:col-span-1">
                <input type="text" placeholder="Employee Bank Name" className="p-3 bg-[#D9D9D9] rounded w-full" value="UBL Bank" />
              </div>
              <div className="flex flex-col md:col-span-1">
                <input type="text" placeholder="Employee Bank Acc No" className="p-3 bg-[#D9D9D9] rounded w-full" value="1234567890123" />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div className="flex flex-col md:col-span-1">
                <input type="text" placeholder="Employee Bank Title" className="p-3 bg-[#D9D9D9] rounded w-full" value="John Doe" />
              </div>
              <div className="flex flex-col md:col-span-1">
                <input type="text" placeholder="Employee Is Filer" className="p-3 bg-[#D9D9D9] rounded w-full" value="Yes" />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div className="flex flex-col md:col-span-1">
                <div className="relative">
                  <input
                    ref={resumeInputRef}
                    type="file"
                    className="hidden"
                    onChange={(e) => handleFileInputChange(e, setResumeFileName)}
                  />
                  <label htmlFor="resumeInput" className="cursor-pointer">
                    <div className="p-3 bg-[#D9D9D9] rounded w-full">{resumeFileName}</div>
                    <img
                      src={DocIcon}
                      alt="Upload resume"
                      className="absolute right-3 top-1/2 transform -translate-y-1/2 h-8 w-8 cursor-pointer"
                      onClick={handleResumeIconClick}
                    />
                  </label>
                </div>
              </div>
            </div>

            <div className="flex items-center justify-center">
              <button
                type="submit"
                className="py-3 px-6 bg-[#0EA288] text-white rounded hover:bg-white hover:text-[#0EA288] font-bold transition duration-300"
              >
                Update User
              </button>
            </div>
          </form>
        </div>
      </main>
    </div>
  );
};

export default EditUser;
