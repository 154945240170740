import React from 'react'
import companyLogo from '../assets/company/image 5.png';
import { FaSignOutAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import UserIcon from '../assets/images/userIcon.png';
import unotificationIcon from '../assets/images/notificationsIcon.png'



const Nav = ({mainHeading}) => {
  return (
    <header className="w-full py-4">
                <div className="max-w-7xl mx-auto px-4 flex flex-col sm:flex-row items-center justify-between">
                    {/* Logo */}
                    {/* <div className="mb-4 sm:mb-0">
                        <Link to="/">
                        <img src={companyLogo} alt="Company Logo" className="w-32 sm:w-40 lg:w-48 flex-shrink-0" />
                        </Link>
                    </div> */}

                    {/* Middle Text */}
                    <div className="text-center sm:flex-1 sm:ml-4">
                        {/* Main heading */}
                        <h1 className="text-[#0EA288] text-xl sm:text-2xl lg:text-3xl font-bold">{mainHeading}</h1>
                        {/* common heading */}
                        <h2 className="text-base sm:text-lg lg:text-xl text-gray-600">Technology in motion, Innovation in action</h2>
                    </div>

                    {/* Logout Section */}
                    <div className="flex flex-col items-center sm:items-end mt-4 sm:mt-0 sm:ml-4">
                    <div className="flex justify-between items-center w-full">
                        <img
                            src={unotificationIcon}
                            alt="notification-icon"
                            className="w-10 h-10 rounded-full"
                        />
                        
                        <img
                            src={UserIcon}
                            alt="user-icon"
                            className="w-16 h-16 rounded-full"
                        />
                    </div>



                    </div>
                </div>
            </header>
  )
}

export default Nav