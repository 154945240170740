import React, { useState } from 'react';
import { FaSignOutAlt } from 'react-icons/fa';
import companyLogo from '../assets/company/image 5.png';
import { Link } from 'react-router-dom';
import Nav from '../components/Nav';
import Notes from '../components/Notes';

const SprintTicket = () => {
  const tasks = [
    { id: 1, title: "Implement login feature", code: "NS-00001", priority: "High", description: "Create login functionality for the application." },
    { id: 2, title: "Implement UI for Attendance page", priority: "High", code: "NS-00001", description: "Design and develop the UI for the attendance page." },
    { id: 3, title: "Implement UI for Sprint Portal of Admin", priority: "Medium", code: "NS-00001", description: "Create the sprint portal interface for admin users." },
    { id: 4, title: "Implement UI for Sprint Portal of Employee", priority: "Low", code: "NS-00001", description: "Develop the sprint portal interface for employee users." },
  ];

  const [openTask, setOpenTask] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleTask = (taskId) => {
    setOpenTask(openTask === taskId ? null : taskId);
  };

  const getPriorityColor = (priority) => {
    switch (priority) {
      case "High":
        return "text-red-500";
      case "Medium":
        return "text-yellow-500";
      case "Low":
        return "text-green-500";
      default:
        return "";
    }
  };

  return (
    <div className="min-h-screen">
      {/* Header Section */}
      <Nav mainHeading="My Sprint Ticket" />

      {/* Report links */}
      <div className="p-4">
        <div className='p-8'>
          <div className='flex justify-between mt-4'>
            <a href="#" className="text-lg sm:text-base text-[#0EA288] hover:underline">
              My Calender
            </a>
            <Link to="/update" className="text-lg sm:text-base text-[#0EA288] hover:underline">
              Submit Report
            </Link>
          </div>

          {/* Time section */}
          <div className="mt-8">
            <div className="text-right">
              <div className="text-lg">
                Remaining Time: <span className='text-[#0EA288]'>2d, 2h, 3m </span>
              </div>
              {/* Notes */}
              <div className='mt-6'>
                <button onClick={() => setIsModalOpen(true)} className="text-[#0EA288] hover:underline">
                  Notes
                </button>
              </div>
            </div>
          </div>

          {/* Ticket */}
          <p className='text-lg font-bold'>My Today’s Assignment</p>
          <div className="grid grid-cols-2 text-lg p-2 mt-4">
            <div className='font-semibold'>Task</div>
            <div className='font-semibold'>Priority</div>
          </div>
          {tasks.map((task) => (
            <div key={task.id} className="bg-[#D9D9D999]/60 p-4 border rounded-lg mb-4">
              <div className="grid grid-cols-2 items-center">
                <div className="flex items-center">
                  <div className="w-6 h-6 bg-[#0EA288] rounded-full mr-2"></div>
                  <div>
                    <div className="text-lg font-semibold">{task.title}</div>
                    {task.code && <div className="text-sm text-[#0EA288]">{task.code}</div>}
                  </div>
                </div>
                <div className="flex justify-between">
                  <div className={`text-lg ${getPriorityColor(task.priority)} mr-4`}>
                    {task.priority}
                  </div>
                  <button
                    className="text-[#0EA288]"
                    onClick={() => toggleTask(task.id)}
                  >
                    <svg className={`w-6 h-6 transform ${openTask === task.id ? 'rotate-180' : ''}`} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                    </svg>
                  </button>
                </div>
              </div>
              {openTask === task.id && (
                <div className="mt-2 text-gray-700">
                  {task.description}
                </div>
              )}
            </div>
          ))}
          {/* Button */}
          <div className="text-right">
              <button type="submit" className="px-6 py-3 bg-[#0EA288] text-white rounded-lg hover:bg-[#2e6158]">
              Submit Daily Report
              </button>
            </div>
        </div>
      </div>

      {/* Modal */}
      <Notes show={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </div>
  );
};

export default SprintTicket;
