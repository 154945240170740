import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaBars, FaPlus } from 'react-icons/fa';
import CeoFahadImage from '../assets/images/CeoFahad.png';
import LogoutIcon from '../assets/images/LogoutIcon.png';
import UserIcon from '../assets/images/userIcon.png';
import { IoSettingsOutline, IoChevronDown, IoChevronUp } from "react-icons/io5";
import plus from '../assets/images/plus.png';
import Nav from '../components/Nav';

const AllProjects = ({ projects, updateProject }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 768) {
                setSidebarOpen(true);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleAddProject = () => {
        navigate('/add-project');
    };

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const handleEditProject = (key) => {
        navigate(`/edit-project/${key}`);
    };

    const handleSprint = (key) => {
        key=1; // temporary 
        navigate(`/all-sprints/${key}`);
        // navigate(`/ticket-sprint/${key}`);
    };



    return (
        <div className="flex flex-col md:flex-row min-h-screen">
            {/* Sidebar */}
            <aside
                className={`fixed z-30 md:z-10 top-0 left-0 md:static transition-transform transform ${
                    sidebarOpen ? 'translate-x-0' : '-translate-x-full'
                } w-64 bg-[#0EA288] text-white flex flex-col items-center py-4 h-full md:h-auto`}
            >
                <img
                    src={CeoFahadImage}
                    alt="Fahad Shahzad"
                    className="w-24 h-24 rounded-full mb-4"
                />
                <h2 className="text-xl font-bold mb-2">Fahad Shahzad</h2>
                <p className="mb-8">CEO</p>
                <nav className="flex flex-col space-y-4 w-full px-4">
                    <Link to="/dashboard">
                        <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">
                            Home
                        </button>
                    </Link>
                    <Link to="/attendance">
                        <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">
                            Attendance
                        </button>
                    </Link>
                    <Link to="/leaverequest">
                        <button className="w-full py-2 bg-white border-2 border-[#0EA288] rounded hover:bg-[#0EA288] hover:text-white relative font-bold text-[#0EA288]">
                            Leave Requests
                            <span
                                className="absolute -top-1 -right-1 bg-[#0EA288] text-white rounded-full flex items-center justify-center border-2 border-white"
                                style={{ width: '24px', height: '24px', lineHeight: '22px' }}
                            >
                                2
                            </span>
                        </button>
                    </Link>
                    <Link to="/salaries">
                        <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">
                            Salaries
                        </button>
                    </Link>
                    <Link to="/user-management">
                        <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">
                            Users Management
                        </button>
                    </Link>
                    <button className="w-full py-2 bg-white border-2 border-[#0C8B76] rounded hover:bg-white font-bold text-black">
                        Project Management
                    </button>
                    <Link to="/all-customer">
                        <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">
                            Customers
                        </button>
                    </Link>
                    <Link to="/reports">
                    <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">
                        Reports
                    </button>
                    </Link>
                    <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">
                        Promotion
                    </button>
                </nav>
                <div className="flex-1 flex flex-col justify-end w-full px-4 mb-4">
                    <img src={LogoutIcon} alt="logout-icon" className="w-6 h-6 mb-2" />
                </div>
            </aside>

            {/* Main content */}
            <main className="flex-1 p-4 md:p-8 transition-all duration-300">
                <header className="mb-8">
                    <div className="flex justify-center items-center mb-4">
                        <Nav mainHeading="Project Management Portal" />
                        <button
                            className="md:hidden fixed top-4 right-4 z-50 bg-white text-[#0EA288] rounded-full p-3 shadow-lg"
                            onClick={() => setSidebarOpen(!sidebarOpen)}
                        >
                            <FaBars size={24} />
                        </button>
                    </div>
                </header>

                {/* Main Content of AllProjects */}
                <div className='p-8 sm:p-8'>
               
                    <button className="bg-[#0EA288] text-white px-4 py-2 rounded-lg mb-4" onClick={handleAddProject}>Add Another Project</button>

                    <div className="mb-6  flex flex-col sm:flex-row justify-between items-center">
                        <h2 className="text-2xl sm:text-2xl font-bold">All Projects</h2>
                        <div className="relative mt-4 sm:mt-0">
                            <button
                                className="bg-[#D9D9D966]/40 border border-[#0EA288] px-4 py-2 rounded-lg flex items-center"
                                onClick={toggleDropdown}
                            >
                                Filter Projects
                                {dropdownOpen ? <IoChevronUp className="ml-2 text-[#0EA288] " /> : <IoChevronDown className="ml-2 text-[#0EA288]" />}
                            </button>
                            {dropdownOpen && (
                                <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-300 rounded-lg shadow-lg z-10">
                                    <button className="block px-4 py-2 w-full text-left hover:bg-gray-100">In Progress</button>
                                    <button className="block px-4 py-2 w-full text-left hover:bg-gray-100">Done</button>
                                    <button className="block px-4 py-2 w-full text-left hover:bg-gray-100">Completed</button>
                                </div>
                            )}
                        </div>
                    </div>

                    {projects.length === 0 ? (
                        <div className="text-center text-gray-500">No projects available. Add a new project.</div>
                    ) : (
                        projects.map((project, index) => (
                            <div key={index} className="border border-[#0EA288] rounded-lg p-4 bg-[#D9D9D94D]/30 mb-6 relative">
                                <div className="flex flex-col md:flex-row md:items-center md:justify-end gap-2 md:gap-10 space-x-0 md:space-x-4">
                                    <span className="text-green-500">In Progress!</span>
                                    <p className="text-gray-600 text-lg underline">Client Name: {project.clientName}</p>
                                    {/* Manage sprint */}
                                    <button onClick={() => handleSprint(project.key)} className="px-4 py-2 bg-[#0EA288] text-white rounded-3xl hover:underline">Manage Sprints</button>
                                </div>
                                <div className="max-w-4xl flex flex-col md:flex-row items-center mt-4 md:mt-0">
                                    <div className="w-16 h-16 bg-white border border-[#0EA288] rounded-full flex items-center justify-center text-xl font-bold text-[#000000] ">
                                        {project.key}
                                    </div>
                                    <div className="ml-0 md:ml-6 flex-grow mt-4 md:mt-0 text-center md:text-left">
                                        <div className="text-xl font-bold text-[#000000]">{project.title} <span className="text-gray-500 font-bold">({project.key})</span>
                                        </div>
                                        <div className="text-lg text-gray-600 mt-2 flex justify-center md:justify-start">
                                            Technologies:
                                            <div className='ml-2'>{project.technologies.split(',').map((tech, i) => (
                                                <span key={i} className="inline-block bg-teal-600 text-white rounded-full px-3 py-1 text-sm font-semibold mx-1">{tech.trim()}</span>
                                            ))}</div>
                                        </div>
                                    </div>
                                </div>
                                {/* Duration and budget */}
                                <div className='flex flex-col md:flex-row justify-end mt-4 md:mt-0 mr-0 md:mr-36 gap-2 md:gap-12 text-center md:text-left'>
                                    <p className="text-gray-600 text-lg underline">Duration: {project.duration} Days</p>
                                    <p className="text-gray-600 text-lg underline">Budget: ${project.budget}</p>
                                </div>
                                {/* Setting or update previous project */}
                                <IoSettingsOutline className="absolute font-bold right-2 bottom-1 text-[#0EA288] cursor-pointer " size={26} onClick={() => handleEditProject(project.key)} />
                            </div>
                        ))
                    )}

                    <div className="flex justify-center items-center w-full mt-4">
                        <div
                            className="flex items-center justify-center bg-[#D9D9D94D]/30 rounded-lg py-2 px-4 cursor-pointer w-full md:w-[500px] h-[70px]"
                            onClick={handleAddProject}
                        >
                            <img src={plus} alt="Plus" className="w-8 mr-3" />
                            <span className="text-black font-semibold text-lg">Add another project</span>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default AllProjects;
