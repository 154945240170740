import React, { useState, useEffect } from 'react';
import deleteVector from '../assets/images/delete.png';
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import { Link, useNavigate, useParams } from 'react-router-dom';
import Select, { components } from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { IoMdArrowDropdown } from 'react-icons/io';
import { FaCalendarAlt, FaBars } from 'react-icons/fa';
import Nav from '../components/Nav';
import CeoFahadImage from '../assets/images/CeoFahad.png';
import LogoutIcon from '../assets/images/LogoutIcon.png';
import ReactQuill from "react-quill";

const options = [
    { value: 'Fahad', label: 'Fahad' },
    { value: 'Ali', label: 'Ali' },
    { value: 'Ahmad', label: 'Ahmad' },
    // Add more options as needed
];

const DropdownIndicator = (props) => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <IoMdArrowDropdown className='text-gray-500' />
            </components.DropdownIndicator>
        )
    );
};

const AddSprint = ({ addSprint }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const { key } = useParams();
    const navigate = useNavigate();
    const [value, setValue] = useState('');
    const [sprintData, setSprintData] = useState({
        title: '',
        key: '',
        assignTo: [],
        description: '',
        clientName: '',
        status: '',
        startDate: '',
        endDate: '',
    });
    const handleChangeDescription = (content, delta, source, editor) => {
        setValue(content);
    };


    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 768) {
                setSidebarOpen(true);
            } else {
                setSidebarOpen(false);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        addSprint({ ...sprintData, assignTo: selectedOptions.map(option => option.label), startDate, endDate });
        navigate(`/all-sprints/${key}`);
    };

    const handleChangeSpr = (e) => {
        e.preventDefault();
        setSprintData({ ...sprintData, [e.target.name]: e.target.value });
    };

    const handleStatusChange = (status) => {
        setSprintData({ ...sprintData, status });
    };

    const handleChange = (selected) => {
        setSelectedOptions(selected);
    };

    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        setSprintData({ ...sprintData, startDate: start, endDate: end });
    };

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    return (
        <div className="flex flex-col md:flex-row min-h-screen bg-gray-100">
            {/* Sidebar */}
            <aside
                className={`fixed z-30 md:z-10 top-0 left-0 md:static transition-transform transform ${
                    sidebarOpen ? 'translate-x-0' : '-translate-x-full'
                } w-64 bg-[#0EA288] text-white flex flex-col items-center py-4 h-full md:h-auto`}
            >
                <img
                    src={CeoFahadImage}
                    alt="Fahad Shahzad"
                    className="w-24 h-24 rounded-full mb-4"
                />
                <h2 className="text-xl font-bold mb-2">Fahad Shahzad</h2>
                <p className="mb-8">CEO</p>
                <nav className="flex flex-col space-y-4 w-full px-4">
                    <Link to="/dashboard">
                        <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">
                            Home
                        </button>
                    </Link>
                    <Link to="/attendance">
                        <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">
                            Attendance
                        </button>
                    </Link>
                    <Link to="/leaverequest">
                        <button className="w-full py-2 bg-white border-2 border-[#0EA288] rounded hover:bg-[#0EA288] hover:text-white relative font-bold text-[#0EA288]">
                            Leave Requests
                            <span
                                className="absolute -top-1 -right-1 bg-[#0EA288] text-white rounded-full flex items-center justify-center border-2 border-white"
                                style={{ width: '24px', height: '24px', lineHeight: '22px' }}
                            >
                                2
                            </span>
                        </button>
                    </Link>
                    <Link to="/salaries">
                        <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">
                            Salaries
                        </button>
                    </Link>
                    <Link to="/user-management">
                        <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">
                            Users Management
                        </button>
                    </Link>
                    <button className="w-full py-2 bg-white border-2 border-[#0C8B76] rounded hover:bg-white font-bold text-black">
                        Project Management
                    </button>
                    <Link to="/all-customer">
                        <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">
                            Customers
                        </button>
                    </Link>
                    <Link to="/reports">
                    <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">
                        Reports
                    </button>
                    </Link>
                    <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">
                        Promotion
                    </button>
                </nav>
                <div className="flex-1 flex flex-col justify-end w-full px-4 mb-4">
                    <img src={LogoutIcon} alt="logout-icon" className="w-6 h-6 mb-2" />
                </div>
            </aside>

            {/* Main content */}
            <main className="flex-1 p-4 md:p-8 transition-all duration-300">
                <header className="mb-8">
                    <div className="flex justify-center items-center mb-4">
                        <Nav mainHeading="Project Management Portal" />
                        <button
                            className="md:hidden fixed top-4 right-4 z-50 bg-white text-[#0EA288] rounded-full p-3 shadow-lg"
                            onClick={toggleSidebar}
                        >
                            <FaBars size={24} />
                        </button>
                    </div>
                </header>
                <div className='p-4 sm:p-8'>
                    {/* Header */}
                    <div className="p-4 sm:p-6 md:p-8">
                        {/* delete and title */}
                        <div className="flex justify-between items-center mb-6">
                            <p className='text-lg font-semibold'>Manage Sprint (NovaSync Dynamics Project)</p>
                            <div>
                                <img src={deleteVector} alt="Plus" className="w-8 cursor-pointer" />
                            </div>
                        </div>
                        {/* Form */}
                        <form onSubmit={handleSubmit}>
                            {/* title and status */}
                            <div className="mb-6 flex flex-col md:flex-row justify-between items-center">
                                <div className="flex-1 mr-0 md:mr-4 mb-4 md:mb-0">
                                    <label className="block text-lg mb-2 font-semibold" htmlFor="sprintTitle">Sprint Title (PT-001)</label>
                                    <input
                                        name='title'
                                        type='text'
                                        id="sprintTitle"
                                        className="w-full p-3 border rounded-lg bg-[#D9D9D980]/50 focus:outline-none"
                                        maxLength={100}
                                        value={sprintData.title}
                                        onChange={handleChangeSpr}
                                    />
                                </div>
                                {/* Status */}
                                <div className="relative w-full md:w-auto">
                                    <p className='pb-2 font-semibold text-lg'>Change status</p>
                                    <button
                                        type="button"
                                        className="w-full md:w-auto bg-[#D9D9D966]/40 border border-[#0EA288] px-4 py-2 rounded-lg flex items-center justify-between"
                                        onClick={toggleDropdown}
                                    >
                                        {sprintData.status || "Select Status"}
                                        {dropdownOpen ? <IoChevronUp className="ml-2 text-[#0EA288]" /> : <IoChevronDown className="ml-2 text-[#0EA288]" />}
                                    </button>
                                    {dropdownOpen && (
                                        <div className="absolute right-0 mt-2 w-full md:w-48 bg-white border border-gray-300 rounded-lg shadow-lg z-10">
                                            <Link
                                                className="block px-4 py-2 w-full text-left hover:bg-gray-100"
                                                onClick={() => handleStatusChange('In Progress')}
                                            >
                                                In Progress
                                            </Link>
                                            <Link
                                                className="block px-4 py-2 w-full text-left hover:bg-gray-100"
                                                onClick={() => handleStatusChange('Done')}
                                            >
                                                Done
                                            </Link>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="mb-6">
                                <label className="block text-lg mb-2 font-semibold" htmlFor="sprintDescription">Sprint Description</label>
                                <ReactQuill value={value} onChange={handleChangeDescription} />

                            </div>
                            {/* Dropdown and date picker */}
                            <div className="mb-6 flex flex-col md:flex-row justify-between items-center">
                                {/* AssignTo */}
                                <div className='w-full md:w-auto mb-4 md:mb-0'>
                                    <p className='text-lg font-semibold'>Assign to</p>
                                    <div className='relative'>
                                        <Select
                                            isMulti
                                            options={options}
                                            components={{ DropdownIndicator }}
                                            classNamePrefix='react-select'
                                            className='bg-[#D9D9D980]/50 rounded-lg'
                                            onChange={handleChange}
                                            value={selectedOptions}
                                        />
                                    </div>
                                </div>
                                {/* Date Picker */}
                                <div className="relative flex items-center w-full md:w-auto bg-[#0EA288] rounded-lg border">
                                    {!startDate && !endDate && (
                                        <label
                                            className="absolute left-4 top-2 text-lg font-semibold text-white cursor-pointer"
                                            htmlFor="dateRange"
                                            onClick={() => document.getElementById('dateRange').focus()}
                                        >
                                            Date Range
                                        </label>
                                    )}
                                    <DatePicker
                                        name='date'
                                        id="dateRange"
                                        selected={startDate}
                                        onChange={handleDateChange}
                                        startDate={startDate}
                                        endDate={endDate}
                                        selectsRange
                                        className="w-full p-3 bg-transparent focus:outline-none peer cursor-pointer"
                                    />
                                    {!startDate && !endDate && (
                                        <FaCalendarAlt
                                            className="absolute right-3 top-4 text-white cursor-pointer"
                                            onClick={() => document.getElementById('dateRange').focus()}
                                        />
                                    )}
                                </div>
                            </div>
                            {/* button */}
                            <div className="text-right">
                                <button type="submit" className="px-6 py-3 bg-[#0EA288] text-white rounded-lg hover:bg-[#2e6158]">
                                    Save Sprint
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default AddSprint;
