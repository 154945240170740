import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import CeoFahadImage from '../assets/images/CeoFahad.png';
import LogoutIcon from '../assets/images/LogoutIcon.png';
import UserIcon from '../assets/images/userIcon.png';
import AddCustomer from '../components/AddCustomer';
import AddInvite from '../components/AddInvite';
import inviteIcon from '../assets/images/invite.png';
import { FaEdit } from 'react-icons/fa'; // Import edit icon
import { useNavigate } from 'react-router-dom';
import Nav from '../components/Nav';

const useModal = () => {
  const [isVisible, setIsVisible] = useState(false);

  const showModal = () => setIsVisible(true);
  const hideModal = () => setIsVisible(false);

  return {
    isVisible,
    showModal,
    hideModal,
  };
};

const AllCustomer = ({ customers, addCustomer }) => {
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setSidebarOpen(true);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const { isVisible: isAddCustomerModalVisible, showModal: showAddCustomerModal, hideModal: hideAddCustomerModal } = useModal();
  const { isVisible: isInviteModalVisible, showModal: showInviteModal, hideModal: hideInviteModal } = useModal();

  return (
    <div className="flex flex-col md:flex-row min-h-screen">
      {/* Sidebar */}
      <aside
        className={`fixed z-30 md:z-10 top-0 left-0 md:static transition-transform transform ${
          sidebarOpen ? 'translate-x-0' : '-translate-x-full'
        } w-64 bg-[#0EA288] text-white flex flex-col items-center py-4 h-full md:h-auto`}
      >
        <img
          src={CeoFahadImage}
          alt="Fahad Shahzad"
          className="w-24 h-24 rounded-full mb-4"
        />
        <h2 className="text-xl font-bold mb-2">Fahad Shahzad</h2>
        <p className="mb-8">CEO</p>
        <nav className="flex flex-col space-y-4 w-full px-4">
          <Link to="/dashboard">
            <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">
              Home
            </button>
          </Link>
          <Link to="/attendance">
            <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">
              Attendance
            </button>
          </Link>
          <Link to="/leaverequest">
            <button className="w-full py-2 bg-white border-2 border-[#0EA288] rounded hover:bg-[#0EA288] hover:text-white relative font-bold text-[#0EA288]">
              Leave Requests
              <span
                className="absolute -top-1 -right-1 bg-[#0EA288] text-white rounded-full flex items-center justify-center border-2 border-white"
                style={{ width: '24px', height: '24px', lineHeight: '22px' }}
              >
                2
              </span>
            </button>
          </Link>
          <Link to="/salaries">
            <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">
              Salaries
            </button>
          </Link>
          <Link to="/user-management">
            <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">
              Users Management
            </button>
          </Link>
          <Link to="/all-projects">
            <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">
              Project Management
            </button>
          </Link>
          <Link to="/all-customer">
            <button className="w-full py-2 bg-white border-2 border-[#0C8B76] rounded hover:bg-white hover:border-white font-bold text-black">
              Customers
            </button>
          </Link>
          <Link to="/reports">
          <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">
            Reports
          </button>
          </Link>
          <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">
            Promotion
          </button>
        </nav>
        <div className="flex-1 flex flex-col justify-end w-full px-4 mb-4">
          <img src={LogoutIcon} alt="logout-icon" className="w-6 h-6 mb-2" />
        </div>
      </aside>

      {/* Main content */}
      <main className="flex-1 p-4 md:p-8 transition-all duration-300">
        <header className="mb-8">
          <div className="flex justify-end items-center mb-4">
          <Nav mainHeading="Customer Management Portal" />
            <button
              className="md:hidden fixed top-4 right-4 z-50 bg-white text-[#0EA288] rounded-full p-3 shadow-lg"
              onClick={() => setSidebarOpen(!sidebarOpen)}
            >
              <FaBars size={24} />
            </button>
            {/* <img
              src={UserIcon}
              alt="user-icon"
              className="w-16 h-16 rounded-full flex justify-end items-center"
            /> */}
          </div>
        </header>

        {/* Main Form */}
        <div className="p-4">
          <div className="p-8">
            <div className="flex items-center justify-center">
              <div className="w-full">
                {/* All Customer Content */}
                <div className="p-4 sm:p-6 lg:p-8">
                  <h2 className="text-2xl font-bold underline mb-4">All Customers</h2>
                  <div className="flex flex-col md:flex-row justify-end md:space-x-4 space-y-4 md:space-y-0  space-x-0 mb-6">
                    <button
                      onClick={showInviteModal}
                      className="flex items-center justify-center w-full md:w-40 h-12 bg-[#0EA288] text-white rounded-lg hover:bg-[#2e6158] font-bold"
                    >
                      <img src={inviteIcon} alt="Invite" className="w-6 h-6 mr-2" /> Invite
                    </button>
                    <button
                      onClick={showAddCustomerModal}
                      className="flex items-center justify-center w-full md:w-40 h-12 bg-[#0EA288] text-white rounded-lg hover:bg-[#2e6158] font-bold"
                    >
                      Add Customer
                    </button>
                  </div>

                  {customers.length === 0 ? (
                    <div className="text-center text-gray-500">No customers available. Add a new customer.</div>
                  ) : (
                    customers.map((customer, index) => (
                      <div key={index} className="border border-[#0EA288] rounded-lg p-4 bg-[#D9D9D94D]/30 mb-6 relative">
                        <div className="flex items-center justify-end gap-36 mb-2">
                          <div>
                            <p className="text-gray-600 text-lg underline font-bold">{customer.cusEmail}</p>
                            <p className="text-gray-600 text-lg underline font-bold">{customer.cusNo}</p>
                          </div>
                          {/* Edit customer */}
                          <FaEdit className="text-[#1CA78F] cursor-pointer w-6 h-6" />
                        </div>
                        {/* Customer img */}
                        <div className="flex flex-col md:flex-row items-center mb-2">
                          {customer.image && (
                            <div className="flex items-center mb-2">
                              <img src={URL.createObjectURL(customer.image)} alt="Customer" className="w-20 h-20 rounded-full object-cover mr-4" />
                              {/* Additional details */}
                              <div>
                                <p className="text-gray-600 text-lg underline font-bold">{customer.cusName}</p>
                                <p className="text-gray-600 text-lg underline font-bold">Country: {customer.cusCountry}</p>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="flex justify-end items-center gap-36">
                          <Link to={`/projects/${customer.id}`} className="text-[#0EA288] underline text-lg font-bold">View Projects</Link>
                          {/* status */}
                          <span className={`text-${customer.status === 'Active' ? 'green' : 'red'}-500`}>{customer.status}</span>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <AddCustomer isVisible={isAddCustomerModalVisible} onClose={hideAddCustomerModal} addCustomer={addCustomer} />
      <AddInvite isVisible={isInviteModalVisible} onClose={hideInviteModal} />
    </div>
  );
};

export default AllCustomer;
