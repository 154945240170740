import React, { forwardRef, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import CeoFahadImage from '../assets/images/CeoFahad.png';
import DatePickerIcon from '../assets/images/date-picker.png';
import LogoutIcon from '../assets/images/LogoutIcon.png';
import Nav from '../components/Nav';
import FeedbackModal from '../components/AddFeeback';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const LeaveRequest = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isClicked, setIsClicked] = useState(false);
  const [isFeedbackModalVisible, setIsFeedbackModalVisible] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [feedbackRowIndex, setFeedbackRowIndex] = useState(null);

  const hasClickedRef = useRef(false);

  useEffect(() => {
    if (!hasClickedRef.current && isClicked) {
      handleClick();
      hasClickedRef.current = true;
    }
  }, [isClicked]);

  const handleClick = () => {
    setIsClicked(true);
  };

  const [rows, setRows] = useState([
    {
      employeeName: 'Ahmad Sadiq',
      leaveDates: 'March 26, 2024 to March 26, 2024',
      leaveDuration: '(1 day)',
      leaveMessage: 'I am writing to request a one-day leave to attend my graduation ceremony. This event marks a significant milestone in my academic journey. I will ensure that all my responsibilities are covered prior to my absence and will be back at work on.',
      status: ''
    },
    {
      employeeName: 'Ali Raza',
      leaveDates: 'March 26, 2024 to March 26, 2024',
      leaveDuration: '(1 day)',
      leaveMessage: 'I am writing to request a one-day leave to attend my graduation ceremony. This event marks a significant milestone in my academic journey. I will ensure that all my responsibilities are covered prior to my absence and will be back at work on.',
      status: ''
    }
  ]);

  const [sidebarOpen, setSidebarOpen] = useState(true);

  // Custom input component including an icon
  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <button
      className="flex flex-row justify-between items-center bg-[#D9D9D980]/50 rounded-lg pl-4 pr-4 py-2 w-full sm:w-[300px] h-[50px] border border-gray-300 text-gray-700"
      onClick={onClick}
      ref={ref}
    >
      <span>{value || 'Select a date range'}</span>
      <img src={DatePickerIcon} alt="date-picker" className="w-6 h-6" />
    </button>
  ));

  const handleApprove = (rowIndex) => {
    setFeedbackRowIndex(rowIndex);
    setFeedbackMessage(`Dear ${rows[rowIndex].employeeName},\n\nCongratulations on reaching this significant milestone in your academic journey! Your request for leave on ${rows[rowIndex].leaveDates}, to attend your graduation ceremony has been approved. We appreciate your initiative in ensuring all responsibilities are covered prior to your absence. Enjoy your graduation day!\n\nBest regards,`);
    setIsFeedbackModalVisible(true);
  };

  const handleReject = (rowIndex) => {
    setFeedbackRowIndex(rowIndex);
    setFeedbackMessage(`Dear ${rows[rowIndex].employeeName},\n\nWe regret to inform you that your request for leave on ${rows[rowIndex].leaveDates} has been rejected. Please ensure that you attend work on this date and complete your pending tasks.\n\nBest regards,`);
    setIsFeedbackModalVisible(true);
  };

  const handleSubmitFeedback = () => {
    if (feedbackRowIndex !== null) {
      const newRows = [...rows];
      const status = feedbackMessage.includes('approved') ? 'approved' : 'rejected';
      newRows[feedbackRowIndex].status = status;
      setRows(newRows);
      setIsFeedbackModalVisible(false);
    }
  };

  // Convert date to a readable format
  const formatDateRange = (start, end) => {
    if (!start || !end) return '';
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return `${start.toLocaleDateString(undefined, options)} to ${end.toLocaleDateString(undefined, options)}`;
  };

  return (
    <div className="flex flex-col md:flex-row min-h-screen">
      {/* Sidebar */}
      <aside
        className={`fixed z-30 md:z-10 top-0 left-0 md:static transition-transform transform ${sidebarOpen ? 'translate-x-0' : '-translate-x-full'} w-64 bg-[#0EA288] text-white flex flex-col items-center py-4 h-full md:h-auto`}
      >
        <img
          src={CeoFahadImage}
          alt="Fahad Shahzad"
          className="w-24 h-24 rounded-full mb-4"
        />
        <h2 className="text-xl font-bold mb-2">Fahad Shahzad</h2>
        <p className="mb-8">CEO</p>
        <nav className="flex flex-col space-y-4 w-full px-4">
          <Link to="/dashboard">
            <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">Home</button>
          </Link>
          <Link to="/attendance">
            <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">Attendance</button>
          </Link>
          <Link to="/leaverequest">
            <button onClick={handleClick} className="w-full py-2 bg-white border-2 border-[#0C8B76] rounded hover:bg-white font-bold text-black relative">
              <div className="flex justify-center items-center relative">
                Leave Requests
                {!isClicked && (
                  <span
                    className="absolute -top-1 -right-1 bg-[#0C8B76] text-white rounded-full flex items-center justify-center border-2 border-white"
                    style={{ width: '24px', height: '24px', lineHeight: '22px' }}
                  >
                    2
                  </span>
                )}
              </div>
            </button>
          </Link>
          <Link to="/salaries">
            <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">
              Salaries
            </button>
          </Link>
          <Link to="/user-management">
            <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">Users Management</button>
          </Link>
          <Link to="/all-projects">
            <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">
              Project Management
            </button>
          </Link>
          <Link to="/customers">
            <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">Customers</button>
          </Link>
          <Link to="/reports">
            <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">
              Reports
            </button>
          </Link>
          <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">Promotion</button>
        </nav>
        <div className="flex-1 flex flex-col justify-end w-full px-4 mb-4">
          <img
            src={LogoutIcon}
            alt="logout-icon"
            className="w-6 h-6 mb-2"
          />
        </div>
      </aside>

      {/* Main Content */}
      <main className={`flex-1 p-4 md:p-8 transition-all duration-300`}>
        <header className="mb-8">
          <div className="flex justify-end items-center mb-4">
            <Nav mainHeading="Leave Request Management Portal" />
            <button
              className="md:hidden fixed top-4 right-4 z-50 bg-white text-[#0EA288] rounded-full p-3 shadow-lg"
              onClick={() => setSidebarOpen(!sidebarOpen)}
            >
              <FaBars size={24} />
            </button>
          </div>
        </header>
        <section className="p-4 max-w-7xl mx-auto my-6">
          <div className="flex flex-row justify-end items-center w-full">
            <DatePicker
              selectsRange
              startDate={startDate}
              endDate={endDate}
              onChange={(update) => {
                if (update) {
                  const [start, end] = update;
                  setStartDate(start);
                  setEndDate(end);
                }
              }}
              isClearable
              dateFormat="MMMM d, yyyy"
              customInput={<CustomInput value={startDate && endDate ? `${formatDateRange(startDate, endDate)}` : 'to'} />} // Use the custom input here
            />

            
          </div>
        </section>

        <section className="p-4 max-w-7xl mx-auto my-6">
          <div className="grid grid-cols-1 gap-4">
            {rows
              .filter((row) => {
                const [start, end] = [startDate, endDate].map(d => d ? new Date(d).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) : '');
                const [rowStart, rowEnd] = row.leaveDates.split(' to ').map(date => date.trim());
                return (startDate && endDate)
                  ? (start <= rowEnd && end >= rowStart)
                  : true;
              })
              .map((row, rowIndex) => (
                <div key={rowIndex} className="border-2 border-gray-400 p-4 rounded-lg" data-row={rowIndex}>
                  <h3 className="font-bold text-lg">{row.employeeName}</h3>
                  <p className="text-[#0EA288]">{row.leaveDates} {row.leaveDuration}</p>
                  <p className="my-2">{row.leaveMessage}</p>
                  <p>Thank you for considering my request. I look forward to your approval.</p>
                  <p>Warm regards,</p>
                  <h3 className="font-bold text-lg">March 22, 2024</h3>
                  {row.status === '' ? (
                    <div className="flex justify-end space-x-2 mt-4">
                      <button
                        className="text-green-700 py-2 px-4"
                        onClick={() => handleApprove(rowIndex)}
                      >
                        Approve
                      </button>
                      <button
                        className="text-red-700 py-2 px-4"
                        onClick={() => handleReject(rowIndex)}
                      >
                        Reject
                      </button>
                    </div>
                  ) : (
                    <div className={`mt-2 py-2 px-4 rounded text-sm ${row.status === 'approved' ? 'bg-green-200 text-black-800 font-bold' : 'bg-red-200 text-black-800 font-bold'} float-right`}>
                      {row.status.charAt(0).toUpperCase() + row.status.slice(1)}
                    </div>
                  )}
                </div>
              ))}
          </div>
        </section>
      </main>

      <FeedbackModal
        isVisible={isFeedbackModalVisible}
        onClose={() => setIsFeedbackModalVisible(false)}
        feedbackMessage={feedbackMessage}
        onSubmitFeedback={handleSubmitFeedback}
      />
    </div>
  );
};

export default LeaveRequest;
