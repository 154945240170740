import React from 'react';
import Slider from 'react-slick';
import clientDice from '../assets/images/avatar-ali.png';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { FaStar, FaRegStar } from 'react-icons/fa';

// Testimonials data
const testimonials = [
  {
    name: "Peter Wong",
    position: "CEO Impros.io",
    text: "This was an excellent experience for me. Fahad was professional, a great communicator, delivered way more than expected.",
    clientImg: "https://res.cloudinary.com/df3ok5t8p/image/upload/v1724779665/novasync/atptbszkhqqozkjt9i7c.png",
    rating: 5,
  },
  {
    name: "Peter Wong",
    position: "CEO Impros.io",
    text: "We worked for this new feature for an entire month, was worth it. The best part was that he did not use a template or anything like 90% of other freelancers. Thanks for the effort!!",
    clientImg: "https://res.cloudinary.com/df3ok5t8p/image/upload/v1724779665/novasync/atptbszkhqqozkjt9i7c.png",
    rating: 5,
  },
  {
    name: "Peter Wong",
    position: "CEO Impros.io",
    text: "Fahad has done a terrific work. The delivery is fast and get all the details I want for the project. Great job!",
    clientImg: "https://res.cloudinary.com/df3ok5t8p/image/upload/v1724779665/novasync/atptbszkhqqozkjt9i7c.png",
    rating: 5,
  },
  {
    name: "skwprofessionne",
    position: "France Client",
    text: "Very good experience he very professional. Reussis to make my site perfectly. I recommend this person for your future site design.",
    clientImg: "https://res.cloudinary.com/df3ok5t8p/image/upload/v1724779665/novasync/ukdltqinidavg6azr5bg.png",
    rating: 5,
  },
  {
    name: "charlesj436",
    position: "United States",
    text: "Very experienced seller with great knowledge of react js. If you ever need help with projects, this is the right person for you. Do not hesitate to reach out or contact him for all your needs. I enjoyed working with him and I can see why he is suitable for any job.",
    clientImg: "https://res.cloudinary.com/df3ok5t8p/image/upload/v1724779646/novasync/zecgogyuojlst0a4hc8j.png",
    rating: 5,
  },
  {
    name: "kalatawi",
    position: "United States",
    text: "Fahad is a great coder, he did a great job for me in my backend with Java Spring Boot. Also, he delivered my order so fast. When I needed to add some extra work he was ready for it with the same quality. I suggest Fahad to anyone who needs to work on Backend projects..",
    clientImg: "https://res.cloudinary.com/df3ok5t8p/image/upload/v1724779658/novasync/wuuprobsngdfqm2eyse7.png",
    rating: 5,
  },
  {
    name: "Noa Salih",
    position: "Netherlands",
    text: "My first and best experience on Fiverr, delivered quality work with cooperation and hard work. It was very urgent for me and he did a very good job. Appreciated and highly recommended for quality work",
    clientImg: "https://res.cloudinary.com/df3ok5t8p/image/upload/v1724779663/novasync/nfq0vvbcmor4ejyca7ag.png",
    rating: 5,
  }
];

// Custom settings for the carousel
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

const Testimonials = () => {
  return (
      <div id="reviews" className="container mx-auto py-8">
        <h2 className="text-[#4F6370] text-5xl font-bold leading-[60px] text-center mb-6">
          See what our clients say
        </h2>

        <div className="flex flex-col lg:flex-row gap-6 justify-center items-center">
          <div className="video-container mb-8 lg:mb-0 lg:mr-4">
            <iframe
                src="https://drive.google.com/file/d/1BDvG8LN58PgCRj6eS0-Eb7Qp9pb9hJY8/preview"
                width="320"
                height="400"
                allow="autoplay"
                className="mx-auto rounded-lg border border-gray-300 shadow-lg"
            ></iframe>
          </div>

          <div className="slider-container w-full lg:w-2/3">
            <Slider {...settings}>
              {testimonials.map((testimonial, index) => (
                  <div key={index} className="p-4">
                    <div className="bg-white border border-gray-200 rounded-lg shadow-lg p-4 h-full flex flex-col justify-between max-w-sm mx-auto">
                      <div className="flex items-center justify-center">
                        <img
                            src={testimonial.clientImg}
                            alt={testimonial.name}
                            className="w-20 h-20 rounded-full border-2 border-[#0EA288]"
                        />
                      </div>
                      <div className="text-center mt-4 mb-2">
                        <div className="flex justify-center gap-1 mb-2">
                          {Array.from({ length: 5 }).map((_, i) => (
                              i < testimonial.rating ? (
                                  <FaStar key={i} className="text-[#9FA20E]" />
                              ) : (
                                  <FaRegStar key={i} className="text-gray-300" />
                              )
                          ))}
                        </div>
                        <h3 className="text-lg font-semibold text-[#0EA288]">
                          {testimonial.name}
                        </h3>
                        <span className="text-sm text-[#4F6370]">{testimonial.position}</span>
                      </div>
                      <p className="text-sm text-[#4F6370] text-center mt-4">
                        {testimonial.text}
                      </p>
                    </div>
                  </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
  );
};

export default Testimonials;

