import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Nav from '../components/Nav';
import ReactQuill from 'react-quill';

import AddCustomer from '../components/AddCustomer';
import CeoFahadImage from '../assets/images/CeoFahad.png';
import { FaBars } from 'react-icons/fa';
import LogoutIcon from '../assets/images/LogoutIcon.png';

const AddProject = ({ addProject }) => {
  const [projectData, setProjectData] = useState({
    title: '',
    key: '',
    technologies: '',
    description: '',
    resources: '',
    clientName: '',
    budget: '',
    startDate: '',
    endDate: '',
  });

  const [isModalVisible, setIsModalVisible] = useState(false);  // State for modal visibility
  const [sidebarOpen, setSidebarOpen] = useState(true);  // State for sidebar visibility

  const navigate = useNavigate();
  const [value, setValue] = useState('');

  const handleChangeDescription = (content, delta, source, editor) => {
    setValue(content);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Calculate duration in days
    const start = new Date(projectData.startDate);
    const end = new Date(projectData.endDate);
    const duration = Math.ceil((end - start) / (1000 * 60 * 60 * 24));

    addProject({ ...projectData, duration });
    navigate('/all-projects');
  };

  const handleChange = (e) => {
    e.preventDefault();
    setProjectData({...projectData, [e.target.name]: e.target.value});
  };

  const showModal = () => setIsModalVisible(true);
  const hideModal = () => setIsModalVisible(false);

  // Client list
  const clients = ['Client A', 'Client B', 'Client C'];

  return (
    <div className="flex flex-col md:flex-row min-h-screen">
      <button
        className="md:hidden fixed top-4 right-4 z-50 bg-white text-[#0EA288] rounded-full p-3 shadow-lg"
        onClick={() => setSidebarOpen(!sidebarOpen)}
      >
        <FaBars size={24} />
      </button>
      {/* Sidebar */}
      <aside className={`fixed z-30 top-0 left-0 md:relative transition-transform transform ${sidebarOpen ? 'translate-x-0' : '-translate-x-full'} w-64 bg-[#0EA288] text-white flex flex-col items-center py-4 h-full md:h-auto`}>
        <img src={CeoFahadImage} alt="Fahad Shahzad" className="w-24 h-24 rounded-full mb-4" />
        <h2 className="text-xl font-bold mb-2">Fahad Shahzad</h2>
        <p className="mb-8">CEO</p>
        <nav className="flex flex-col space-y-4 w-full px-4">
          {/* Navigation links */}
          <button onClick={() => navigate('/dashboard')} className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">Home</button>
          <button onClick={() => navigate('/attendance')} className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">Attendance</button>
          <button onClick={() => navigate('/leaverequest')} className="w-full py-2 bg-white border-2 border-[#0EA288] rounded hover:bg-[#0EA288] hover:text-white relative font-bold text-[#0EA288]">
            Leave Requests
            <span className="absolute -top-1 -right-1 bg-[#0EA288] text-white rounded-full flex items-center justify-center border-2 border-white" style={{ width: '24px', height: '24px', lineHeight: '22px' }}>2</span>
          </button>
          <button onClick={() => navigate('/generate-salary-slip')} className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">Salaries</button>
          <button onClick={() => navigate('/user-management')} className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">Users Management</button>
          <button onClick={() => navigate('/all-projects')} className="w-full py-2 bg-white border-2 border-[#0C8B76] rounded hover:bg-white font-bold text-black">Project Management</button>
          <button onClick={() => navigate('/all-customer')} className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">Customers</button>
          <button onClick={() => navigate('/reports')} className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">Reports</button>
          <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">Promotion</button>
        </nav>
        <div className="flex-1 flex flex-col justify-end w-full px-4 mb-4">
          <img src={LogoutIcon} alt="logout-icon" className="w-6 h-6 mb-2" />
        </div>
      </aside>

      {/* Main Content */}
      <div className="flex-1">
        <Nav mainHeading="Project Management Portal" />
        <div className="p-4">
          <div className="p-4 sm:p-8">
            <h2 className="text-xl sm:text-2xl font-bold mb-6">Add Project</h2>
            <form onSubmit={handleSubmit}>
              {/* Form fields */}
              <div className="mb-6">
                <label className="block text-base sm:text-lg mb-2 font-semibold" htmlFor="projectTitle">Project Title</label>
                <input name='title' type="text" id="projectTitle" className="w-full p-2 sm:p-3 border rounded-lg bg-[#D9D9D980]/50 focus:outline-none" maxLength={100} value={projectData.title}
                  onChange={handleChange} />
              </div>

              <div className="mb-6">
                <label className="block text-base sm:text-lg mb-2 font-semibold" htmlFor="projectKey">Project Key</label>
                <input name='key' type="text" id="projectKey" className="w-full p-2 sm:p-3 border rounded-lg bg-[#D9D9D980]/50 focus:outline-none" maxLength={50} value={projectData.key}
                  onChange={handleChange} />
              </div>

              <div className="mb-6">
                <label className="block text-base sm:text-lg mb-2 font-semibold" htmlFor="projectTechnologies">Project Technologies (Comma Separated)</label>
                <input name='technologies' type="text" id="projectTechnologies" className="w-full p-2 sm:p-3 border rounded-lg bg-[#D9D9D980]/50 focus:outline-none" value={projectData.technologies}
                  onChange={handleChange} />
              </div>

              <div className="mb-6">
                <label className="block text-base sm:text-lg mb-2 font-semibold" htmlFor="projectDescription">Project Description</label>
                <ReactQuill value={value} onChange={handleChangeDescription} />

              </div>

              <div className="mb-6">
                <label className="block text-base sm:text-lg mb-2 font-semibold" htmlFor="resourceUrls">Resources or Documents URLs</label>
                <input name="resources" type="text" id="resourceUrls" className="w-full p-2 sm:p-3 border rounded-lg bg-[#D9D9D980]/50 focus:outline-none" value={projectData.resources}
                  onChange={handleChange} placeholder='https://drive.google.com/mydrive/docs/abc.pdf' />
              </div>

              {/* Add customer button and client details */}
              <div className='flex justify-between items-center mb-6'>
                <h3 className="text-lg sm:text-xl font-bold">Client Details</h3>
                <button type="button" onClick={showModal} className="px-4 sm:px-6 py-2 sm:py-3 bg-[#0EA288] text-white rounded-lg hover:bg-[#2e6158]">
                  Add Customer
                </button>
              </div>

              {/* Select client */}
              <div className="mb-6">
                <label className="block text-base sm:text-lg mb-2 font-semibold" htmlFor="clientName">Client Name</label>
                <select
                  name="clientName"
                  id="clientName"
                  className="w-full p-2 sm:p-3 border rounded-lg bg-[#D9D9D980]/50 focus:outline-none"
                  value={projectData.clientName}
                  onChange={handleChange}
                >
                  <option value="" className='font-bold'>Select Client</option>
                  {clients.map(client => (
                    <option key={client} value={client}>{client}</option>
                  ))}
                </select>
              </div>
              <div className="mb-6">
                <label className="block text-base sm:text-lg mb-2 font-semibold" htmlFor="projectBudget">Project Budget</label>
                <input name="budget" type="number" id="projectBudget" className="w-full p-2 sm:p-3 border rounded-lg bg-[#D9D9D980]/50 focus:outline-none" value={projectData.budget}
                  onChange={handleChange} />
              </div>

              <div className="mb-6">
                <label className="block text-base sm:text-lg mb-2 font-semibold" htmlFor="projectStartDate">Project Start Date</label>
                <input name="startDate" type="date" id="projectStartDate" className="w-full p-2 sm:p-3 border rounded-lg bg-[#D9D9D980]/50 focus:outline-none" value={projectData.startDate}
                  onChange={handleChange} />
              </div>

              <div className="mb-6">
                <label className="block text-base sm:text-lg mb-2 font-semibold" htmlFor="projectEndDate">Project End Date</label>
                <input name="endDate" type="date" id="projectEndDate" className="w-full p-2 sm:p-3 border rounded-lg bg-[#D9D9D980]/50 focus:outline-none" value={projectData.endDate}
                  onChange={handleChange} />
              </div>

              <div className="flex justify-center sm:justify-end">
                <button type="submit" className="w-full sm:w-auto px-6 py-3 bg-[#0EA288] text-white rounded-lg hover:bg-[#2e6158]">
                  Save Project
                </button>
              </div>
            </form>
          </div>
        </div>
        {/* AddCustomer Modal */}
        <AddCustomer isVisible={isModalVisible} onClose={hideModal} />
      </div>

      {/* Mobile Sidebar Toggle Button */}

    </div>
  );
};

export default AddProject;
