import React from 'react';
import { useParams } from 'react-router-dom';

const InvoicePage = () => {
    const { id } = useParams();
    const invoiceNumber = prompt('Please enter the invoice number:');
    return (
        <div>
            <h1>Invoice Number: {invoiceNumber}</h1>
        </div>
    );
};

export default InvoicePage;
