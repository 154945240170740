import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CeoFahadImage from '../assets/images/CeoFahad.png';
import LogoutIcon from '../assets/images/LogoutIcon.png';
import { FaBars } from 'react-icons/fa';
import Nav from '../components/NavForAdminDashboard';
import Select from 'react-select';
import userIcon from '../assets/images/userIcon.png';
import makeAnimated from 'react-select/animated';
import '../assets/style/AdminDashboard.css';
import ExpandImg from "../assets/images/expand.png"; // Import the CSS file

const animatedComponents = makeAnimated();

const AdminDashboard = () => {
  const [tasks, setTasks] = useState([
    { id: 'NS-00001', title: 'Project Title 01', priority: 'High', details: 'Implement UI for Attendance page', status: 'In Progress' },
    { id: 'NS-00002', title: 'Project Title 02', priority: 'Medium', details: 'Implement UI for Attendance page', status: 'Pending' },
    { id: 'NS-00003', title: 'Project Title 03', priority: 'Low', details: 'Implement UI for Attendance page', status: 'Completed' },
    { id: 'NS-00004', title: 'Project Title 04', priority: 'Low', details: 'Implement UI for Attendance page', status: 'Completed' },
    { id: 'NS-00005', title: 'Project Title 05', priority: 'Low', details: 'Implement UI for Attendance page', status: 'Completed' },
    { id: 'NS-00006', title: 'Project Title 06', priority: 'Low', details: 'Implement UI for Attendance page', status: 'Completed' },
  ]);

  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [selectedPriorities, setSelectedPriorities] = useState([]);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setSidebarOpen(true);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const filteredTasks = tasks.filter((task) => {
    const projectMatch = selectedProjects.length ? selectedProjects.some(proj => proj.value === task.id) : true;
    const statusMatch = selectedStatuses.length ? selectedStatuses.some(status => status.value === task.status) : true;
    const priorityMatch = selectedPriorities.length ? selectedPriorities.some(priority => priority.value === task.priority) : true;
    return projectMatch && statusMatch && priorityMatch;
  });

  const projectOptions = tasks.map((task) => ({ value: task.id, label: task.title }));
  const statusOptions = [
    { value: 'In Progress', label: 'In Progress' },
    { value: 'Pending', label: 'Pending' },
    { value: 'Completed', label: 'Completed' },
  ];
  const priorityOptions = [
    { value: 'High', label: 'High' },
    { value: 'Medium', label: 'Medium' },
    { value: 'Low', label: 'Low' },
  ];

  function logout() {
    sessionStorage.removeItem('user')
    navigate('/');
  }

  const enterFullScreen = () => {
    const element = document.getElementById("task-list-container");
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) { /* Firefox */
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) { /* IE/Edge */
      element.msRequestFullscreen();
    }
    setIsFullScreen(true);
  };

  const exitFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) { /* Firefox */
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) { /* IE/Edge */
      document.msExitFullscreen();
    }
    setIsFullScreen(false);
  };

  const toggleFullScreen = () => {
    if (isFullScreen) {
      exitFullScreen();
    } else {
      enterFullScreen();
    }
  };

  return (
      <div className="flex flex-col md:flex-row min-h-screen">
        <aside
            className={`fixed z-30 md:z-10 top-0 left-0 md:static transition-transform transform ${
                sidebarOpen ? 'translate-x-0' : '-translate-x-full'
            } w-64 bg-[#0EA288] text-white flex flex-col items-center py-4 h-full md:h-auto`}
        >
          <img
              src={CeoFahadImage}
              alt="Fahad Shahzad"
              className="w-24 h-24 rounded-full mb-4"
          />
          <h2 className="text-xl font-bold mb-2">Fahad Shahzad</h2>
          <p className="mb-8">CEO</p>
          <nav className="flex flex-col space-y-4 w-full px-4">
            <Link to="/dashboard">
              <button className="w-full py-2 bg-white border-2 border-[#0C8B76] rounded hover:bg-white font-bold text-black">
                Home
              </button>
            </Link>
            <Link to="/attendance">
              <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">
                Attendance
              </button>
            </Link>
            <Link to="/leaverequest">
              <button className="w-full py-2 bg-white border-2 border-[#0EA288] rounded hover:bg-[#0EA288] hover:text-white relative font-bold text-[#0EA288]">
                Leave Requests
                <span
                    className="absolute -top-1 -right-1 bg-[#0EA288] text-white rounded-full flex items-center justify-center border-2 border-white"
                    style={{ width: '24px', height: '24px', lineHeight: '22px' }}
                >
                2
              </span>
              </button>
            </Link>
            <Link to="/salaries">
              <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">
                Salaries
              </button>
            </Link>
            <Link to="/user-management">
              <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288] ">Users Management</button>
            </Link>
            <Link to="/all-projects">
              <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">
                Project Management
              </button>
            </Link>
            <Link to="/all-customer">
              <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">Customers</button>
            </Link>
            <Link to="/reports">
              <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">
                Reports
              </button>
            </Link>
            <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">
              Promotion
            </button>
          </nav>
          <div className="flex-1 flex flex-col justify-end w-full px-4 mb-4">
            <img onClick={logout} src={LogoutIcon} alt="logout-icon" className="w-6 h-6 mb-2" />
          </div>
        </aside>

        <main className="flex-1 p-4 md:p-8 transition-all duration-300">
          <header className="mb-8">
            <div className="flex justify-end items-center mb-4">
              <button
                  className="md:hidden fixed top-4 right-4 z-50 bg-white text-[#0EA288] rounded-full p-3 shadow-lg"
                  onClick={() => setSidebarOpen(!sidebarOpen)}
              >
                <FaBars size={24} />
              </button>
            </div>
            <Nav mainHeading="NovaSync Dynamics Private Limited" />
            <div className="flex justify-between items-center">
              <h1 className="text-3xl font-bold text-[#4F6370]">Today's Activities</h1>
              <div>
                <span className="cursor-pointer underline">Reports</span>
              </div>
            </div>
            <div className="mt-8 flex flex-col md:flex-row justify-between mb-8 gap-4">
              <div className="flex-grow text-center text-[#0EA288] font-bold text-lg md:text-xl border border-[#0C8B76] p-4 rounded-xl flex items-center justify-center">Present: 7</div>
              <div className="flex-grow text-center text-red-600 font-bold text-lg md:text-xl border border-[#0C8B76] p-4 rounded-xl flex items-center justify-center">Absent: 0</div>
              <div className="flex-grow text-center text-[#D4D700] font-bold text-lg md:text-xl border border-[#0C8B76] p-4 rounded-xl flex items-center justify-center">Leave: 0</div>
            </div>
          </header>

          <div className="mb-4 flex flex-col md:flex-row justify-between gap-4">
            <Select
                value={selectedProjects}
                onChange={setSelectedProjects}
                options={projectOptions}
                isMulti
                components={animatedComponents}
                className="w-full md:w-1/3 mb-2 md:mb-0 border border-[#0EA288] rounded-md"
                placeholder="Select Projects"
            />
            <Select
                value={selectedStatuses}
                onChange={setSelectedStatuses}
                options={statusOptions}
                isMulti
                components={animatedComponents}
                className="w-full md:w-1/3 mb-2 md:mb-0 border border-[#0EA288] rounded-md"
                placeholder="Select Status"
            />
            <Select
                value={selectedPriorities}
                onChange={setSelectedPriorities}
                options={priorityOptions}
                isMulti
                components={animatedComponents}
                className="w-full md:w-1/3 mb-2 md:mb-0 border border-[#0EA288] rounded-md"
                placeholder="Filter Priority"
            />
          </div>

          <div id="task-list-container" className={`overflow-x-auto border border-[#0EA288] rounded-md ${isFullScreen ? 'bg-white' : ''}`} style={{ height: "38%" }}>
            <div className="flex items-end justify-end w-full">
              <img
                  src={ExpandImg}
                  className="m-2 w-5 h-5 cursor-pointer"
                  onClick={toggleFullScreen}
              />
            </div>

            <table className="min-w-full overflow-hidden rounded-lg shadow task-row" style={{ borderSpacing: '0 10px' }}>
              <tbody>
              {filteredTasks.map((task, index) => (
                  <tr
                      key={index}
                      className={`bg-[#D9D9D9] bg-opacity-70 text-[#4F6370] mb-4 rounded-lg `}
                      style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}
                  >
                    <td className="p-4" style={{ paddingBottom: '20px' }}>
                      <div className="flex justify-between items-center">
                        <div className="flex items-center">
                          <div className="mr-4 w-8 h-8 bg-[#0EA288] border-2 border-white rounded-full"></div>
                          <div>
                            <div className="text-xl font-bold">{task.title}</div>
                            <div className="text-lg">{task.details}</div>
                            <div className="text-[#0EA288] font-bold">{task.id}</div>
                            <div className="flex items-center space-x-2">
                              {/* Example users - replace with dynamic data as needed */}
                              <img src={userIcon} alt="User 1"
                                   className="w-8 h-8 rounded-full border-2 border-black bg-white" />
                              <img src={userIcon} alt="User 2"
                                   className="w-8 h-8 rounded-full border-2 border-black bg-white" />
                              <img src={userIcon} alt="User 3"
                                   className="w-8 h-8 rounded-full border-2 border-black bg-white" />
                              {/* Add more images as needed */}
                            </div>
                          </div>
                        </div>
                        <div
                            className={`flex flex-col ml-4 ${!sidebarOpen ? 'md:absolute md:top-0 md:right-0 md:flex md:flex-col md:items-end md:gap-2' : 'md:flex md:flex-col md:items-start'}`}>
                          <div className="flex items-center">
                            <div className="text-lg mr-4">Manager: Fahad</div>
                            <div
                                className={`bg-[#0EA288] text-white font-bold py-1 px-3 rounded-full whitespace-nowrap ${!sidebarOpen ? 'text-xs md:py-1 md:px-2' : 'md:py-1 md:px-3'}`}>
                              Front end Web
                            </div>
                          </div>
                          <div className="text-lg mt-2">Status: <span className="text-[#0EA288]">{task.status}</span>
                          </div>
                        </div>
                        <div className="flex flex-col justify-end items-end">
                          <div
                              className={`text-xl ${task.priority === 'High' ? 'text-red-600' : task.priority === 'Medium' ? 'text-yellow-500' : 'text-green-500'}`}>
                            {task.priority}
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
              ))}
              </tbody>
            </table>
          </div>
        </main>
      </div>
  );
};

export default AdminDashboard;
