import React,{useState,useEffect} from 'react';
import ExpandImg from '../assets/images/expand.png';
import { Link } from 'react-router-dom';  // Import Link for navigation
import ProjectImg from '../assets/images/projectimg.jpg';
import Project from '../projects.json';



const RecentProjects = () => {

  const [projects, setProjects] = useState([]);
    useEffect(() => {
        // Simulate fetching the projects from the Projects module
        setProjects(Project);
    }, []);

    console.log("Projects Data: ", projects); // Debugging line
  return (
      <div id="portfolio"  className="container mx-auto  py-8 mt-8">
          <h1 className="mb-6 text-center font-bold text-4xl leading-tight text-[#4F6370] font-inter">Our Recent
              Projects</h1>
          <div className="flex justify-end mr-4 items-center mb-10 sm:px-4">
              <Link to="/all-projects-page" className="text-[#0EA288] font-semibold underline text-lg md:text-2xl">
                  See all projects ({projects.length})
              </Link>
          </div>
          <div className="grid m-5 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 sm:px-4">
              {projects.slice(0, 6).map((project)  => (
                  <div   key={project.Id}
                       className="h-full rounded-xl shadow-cla-blue border border-[#0EA288] overflow-hidden">
                      <Link to={`/projects/${project.title}`}>
                          <img src={project.pImg}  alt={project.title}
                               className="w-full   object-center scale-110 transition-all duration-400 hover:scale-100 "/>
                      </Link>
                      <div className="p-6">
                          <div className="flex justify-between items-center mb-4">
                              <Link to={`/projects/${project.title}`}>
                                 <h2 className="text-[#4F6370] text-lg font-semibold ">{project.title}</h2>
                              </Link>
                              <Link to={`/projects/${project.title}`}>
                                  <img src={ExpandImg} alt={`${project.title} icon`}
                                       className="w-5 h-5 cursor-pointer"/>
                              </Link>
                          </div>
                          <span className="text-[#4F6370]">{project.subTitle}</span>
                          <div className="text-right">
                              <p className="text-[#0EA288] text-lg font-semibold">{project.duration}</p>
                          </div>
                      </div>
                  </div>
              ))}
          </div>
      </div>
  );
};

export default RecentProjects;
