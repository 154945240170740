import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import CeoFahadImage from '../assets/images/CeoFahad.png';
import LogoutIcon from '../assets/images/LogoutIcon.png';
import Nav from './Nav';
import BackIcon from '../assets/images/backIcon.png';

const GenerateSalarySlip = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setSidebarOpen(true);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);



  const [rows, setRows] = useState([{ title: '', amount: '', description: '' }]);

  const addRow = () => {
    setRows([...rows, { title: '', amount: '', description: '' }]);
  };

  const handleChange = (index, field, value) => {
    const updatedRows = rows.map((row, i) => 
      i === index ? { ...row, [field]: value } : row
    );
    setRows(updatedRows);
  };

  return (
    <div className="flex flex-col md:flex-row min-h-screen bg-white">
      {/* Sidebar */}
      <aside
        className={`fixed z-30 md:z-10 top-0 left-0 md:static transition-transform transform ${
          sidebarOpen ? 'translate-x-0' : '-translate-x-full'
        } w-64 bg-[#0EA288] text-white flex flex-col items-center py-4 h-full md:h-auto`}
      >
        <img
          src={CeoFahadImage}
          alt="Fahad Shahzad"
          className="w-24 h-24 rounded-full mb-4"
        />
        <h2 className="text-xl font-bold mb-2">Fahad Shahzad</h2>
        <p className="mb-8">CEO</p>
        <nav className="flex flex-col space-y-4 w-full px-4">
          <Link to="/dashboard">
            <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">
              Home
            </button>
          </Link>
          <Link to="/attendance">
            <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">
              Attendance
            </button>
          </Link>
          <Link to="/leaverequest">
            <button className="w-full py-2 bg-white border-2 border-[#0EA288] rounded hover:bg-[#0EA288] hover:text-white relative font-bold text-[#0EA288]">
              Leave Requests
              <span
                className="absolute -top-1 -right-1 bg-[#0EA288] text-white rounded-full flex items-center justify-center border-2 border-white"
                style={{ width: '24px', height: '24px', lineHeight: '22px' }}
              >
                2
              </span>
            </button>
          </Link>
          <Link to="/salaries">
            <button className="w-full py-2 bg-white border-2 border-[#0C8B76] rounded hover:bg-white font-bold text-black">
              Salaries
            </button>
          </Link>
          <Link to="/user-management">
            <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">
              Users Management
            </button>
          </Link>
          <Link to="/all-projects">
          <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">
            Project Management
          </button>
          </Link>
          <Link to="/all-customer">
            <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">
              Customers
            </button>
          </Link>
          <Link to="/reports">
          <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">
            Reports
          </button>
          </Link>
          <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">
            Promotion
          </button>
        </nav>
        <div className="flex-1 flex flex-col justify-end w-full px-4 mb-4">
          <img src={LogoutIcon} alt="logout-icon" className="w-6 h-6 mb-2" />
        </div>
      </aside>

      {/* Main content */}
      <main className="flex-1 p-4 md:p-8 transition-all duration-300">
        <header className="mb-8">
          <div className="flex justify-end items-center mb-4">
          <Nav mainHeading="Salary Management Portal" />
            <button
              className=" md:hidden fixed top-4 right-4 z-50 bg-white text-[#0EA288] rounded-full p-3 shadow-lg"
              onClick={() => setSidebarOpen(!sidebarOpen)}
            >
              <FaBars size={24} />
            </button>
              </div>
        </header>

        {/* Main Form */}
        <div className="p-4">
          <div className="p-8">
            <div className="flex items-center justify-center">
              <div className="w-full">
              <div className="flex flex-row justify-between items-end">
                   <Link to="/salaries">
                   <img src={BackIcon} alt="back-icon" className="w-16 h-16 mb-3" />
                   </Link>
                   <Link to="/generated-page">
                   <button type="button" className="bg-[#0EA28880]/50 text-black font-bold py-4 px-12 rounded-lg mt-1">
                     Generate
                   </button></Link>
                 </div>


                <form className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>                 
                    {/* Select */}
                    <div className="mb-4">
                      <label htmlFor="employee" className="block text-gray-700 font-bold">Select Employee</label>
                      <select id="employee" className="custom-select p-2  rounded-lg mt-1 w-full md:w-[720px] bg-[#D9D9D980]/50">
                        <option value="">Select Employee</option>
                        {/* Add employee options here */}
                      </select>
                    </div>
                    {/* date */}
                    <div className="mb-4 relative">
                      <label htmlFor="date" className="block text-gray-700 font-bold">Date</label>
                      <input type="date" id="date" className="custom-date-input p-2 border border-[#0EA288] focus:outline-none rounded-lg mt-1 w-full md:w-[720px] " />
                    </div>

                    <p className='text-lg font-bold mb-3'>Amount Details</p>

                    {rows.map((row, index) => (
                      <div key={index}>
                        <div className="mb-4">
                          <label htmlFor={`title-${index}`} className="block text-gray-700">Title</label>
                          <input
                            type="text"
                            id={`title-${index}`}
                            value={row.title}
                            onChange={(e) => handleChange(index, 'title', e.target.value)}
                            className="w-full p-2 border border-[#0EA288] focus:outline-none rounded-lg mt-1 bg-[#D9D9D980]/50"
                          />
                        </div>

                        <div className="mb-4">
                          <label htmlFor={`amount-${index}`} className="block text-gray-700">Amount</label>
                          <input
                            type="number"
                            id={`amount-${index}`}
                            value={row.amount}
                            onChange={(e) => handleChange(index, 'amount', e.target.value)}
                            className="w-full p-2 border border-[#0EA288] focus:outline-none rounded-lg mt-1 bg-[#D9D9D980]/50"
                          />
                        </div>

                        <div className="mb-4">
                          <label htmlFor={`description-${index}`} className="block text-gray-700">Description</label>
                          <textarea
                            id={`description-${index}`}
                            value={row.description}
                            onChange={(e) => handleChange(index, 'description', e.target.value)}
                            className="w-full p-2 border border-[#0EA288] focus:outline-none rounded-lg mt-1 bg-[#D9D9D980]/50"
                            rows="3"
                          ></textarea>
                        </div>
                      </div>
                    ))}
                  </div>

                
                </form>

                <div className="mt-2">
                  <button type="button" onClick={addRow} className="text-black hover:underline">
                    Add another row
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default GenerateSalarySlip;
