import React from 'react';
import footterlogo from '../assets/newlogos/PNG-5-ONLY-ND-LOGO-WHOUT-BG.png';
// import linkdin from '../assets/images/linkdin.png';
import linkdin from '../assets/images/linkdin.png';
import facebook from '../assets/images/facebook.png';
import twitter from '../assets/images/twitter.png';
import youtube from '../assets/images/youtube.png';

const Footer = () => {
  return (
      <footer className="bg-gray-700 text-white py-8">
        <div className="container mx-auto flex flex-col lg:flex-row justify-between items-center lg:items-start px-4">
          {/* Logo Section */}
          <div className=" footer-icon mb-8 lg:mb-0 lg:flex lg:items-center lg:flex-col">
            <img src={footterlogo} height={200} width={200} alt="footer logo"/>
          </div>

          {/* Services Section */}
          <div className="footer-content-div lg:flex-row flex justify-between lg:justify-start w-full lg:w-auto">
            <div className="pr-0 lg:pr-40 mb-8 lg:mb-0 flex flex-col items-center lg:items-start text-center lg:text-left">
              <h1 className="  footer-services font-size text-2xl font-bold mb-4">Services</h1>
              <ul className="footer-service-list space-y-2">
                <li>AI</li>
                <li>Data Science</li>
                <li>Applications Development</li>
              </ul>
            </div>
            <div className="pb-4 mb-8 lg:mb-0 flex flex-col items-center lg:items-start text-center lg:text-left">
              <h2 className=" footer-services font-size text-2xl font-bold mb-4">Blogs</h2>
              <ul className="footer-service-list space-y-2">
                <li>Innovative</li>
                <li>Technology Based</li>
              </ul>
            </div>
          </div>
          {/* Social Media Section */}
          <div className="mb-8 lg:mb-0 text-center lg:text-left">
            <h2 className=" socil-media-text font-size text-2xl font-bold mb-4">Social Media</h2>
            <ul className="space-y-4 social-list">
              <li className="flex items-center justify-center lg:justify-start">
                <img src={linkdin} alt="LinkedIn" className="h-8 w-8 mr-2 bg-white rounded-full" />
                <span className="pl-1 text-base">LinkedIn</span>
              </li>
              <li className="flex items-center justify-center lg:justify-start">
                <img src={facebook} alt="Twitter" className="h-8 w-8 mr-2 p-1 bg-white rounded-full" />
                <span className="pl-1 text-base">Twitter</span>
              </li>
              <li className="flex items-center justify-center lg:justify-start">
                <img src={twitter} alt="Facebook" className="h-8 w-8 mr-2 p-1 bg-white rounded-full" />
                <span className="pl-1 text-base">Facebook</span>
              </li>
              <li className="flex items-center justify-center lg:justify-start">
                <img src={youtube} alt="YouTube" className="h-8 w-8 mr-2 bg-white rounded-full" />
                <span className="pl-1 text-base">YouTube</span>
              </li>
            </ul>
          </div>
        </div>

        <div className="container mx-auto text-center mt-8 text-sm">
          <p>All Rights are Reserved @2024 by NovaSync Dynamics Private Limited</p>
        </div>
      </footer>
  );
};

export default Footer;
