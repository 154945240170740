import React, { forwardRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import CeoFahadImage from '../assets/images/CeoFahad.png';
import LogoutIcon from '../assets/images/LogoutIcon.png';
import Nav from '../components/Nav';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import DatePickerIcon from '../assets/images/date-picker.png';
import DownloadIcon from '../assets/images/downloadIcon.png';

const Salaries = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <button
        className="flex flex-row justify-between items-center pl-4 pr-4 py-2 w-full sm:w-[300px] md:pl-6 md:pr-6 md:py-3 md:h-[60px] border border-gray-300 rounded-lg"
        onClick={onClick}
        ref={ref}
    >
        <span>{value || 'Date range'}</span>
        <img src={DatePickerIcon} alt="date-picker" className="w-6 h-6 md:w-8 md:h-8" />
    </button>
  ));

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setSidebarOpen(true);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const formatDateRange = (start, end) => {
    if (!start || !end) return '';
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return `${start.toLocaleDateString(undefined, options)} to ${end.toLocaleDateString(undefined, options)}`;
  };

  return (
    <div className="flex flex-col md:flex-row min-h-screen bg-white">
      <aside
        className={`fixed z-30 md:z-10 top-0 left-0 md:static transition-transform transform ${
          sidebarOpen ? 'translate-x-0' : '-translate-x-full'
        } w-64 bg-[#0EA288] text-white flex flex-col items-center py-4 h-full md:h-auto`}
      >
        <img
          src={CeoFahadImage}
          alt="Fahad Shahzad"
          className="w-24 h-24 rounded-full mb-4"
        />
        <h2 className="text-xl font-bold mb-2">Fahad Shahzad</h2>
        <p className="mb-8">CEO</p>
        <nav className="flex flex-col space-y-4 w-full px-4">
          <Link to="/dashboard">
            <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">
              Home
            </button>
          </Link>
          <Link to="/attendance">
            <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">
              Attendance
            </button>
          </Link>
          <Link to="/leaverequest">
            <button className="w-full py-2 bg-white border-2 border-[#0EA288] rounded hover:bg-[#0EA288] hover:text-white relative font-bold text-[#0EA288]">
              Leave Requests
              <span
                className="absolute -top-1 -right-1 bg-[#0EA288] text-white rounded-full flex items-center justify-center border-2 border-white"
                style={{ width: '24px', height: '24px', lineHeight: '22px' }}
              >
                2
              </span>
            </button>
          </Link>
          <Link to="/salaries">
            <button className="w-full py-2 bg-white border-2 border-[#0C8B76] rounded hover:bg-white font-bold text-black">
              Salaries
            </button>
          </Link>
          <Link to="/user-management">
            <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">
              Users Management
            </button>
          </Link>
          <Link to="/all-projects">
            <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">
              Project Management
            </button>
          </Link>
          <Link to="/all-customer">
            <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">
              Customers
            </button>
          </Link>
          <Link to="/reports">
            <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">
              Reports
            </button>
          </Link>
          <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">
            Promotion
          </button>
        </nav>
        <div className="flex-1 flex flex-col justify-end w-full px-4 mb-4">
          <img src={LogoutIcon} alt="logout-icon" className="w-6 h-6 mb-2" />
        </div>
      </aside>

      <main className="flex-1 p-4 md:p-8 transition-all duration-300">
        <header className="mb-8">
          <div className="flex justify-between items-center mb-4">
            <Nav mainHeading="Salary Management Portal" />
            <button
              className="md:hidden fixed top-4 right-4 z-50 bg-white text-[#0EA288] rounded-full p-3 shadow-lg"
              onClick={() => setSidebarOpen(!sidebarOpen)}
            >
              <FaBars size={24} />
            </button>
          </div>
        </header>

        <div className="flex flex-col justify-between md:flex-row space-y-4 md:space-y-0 md:space-x-4">
          <div className="bg-[#0EA28880] text-[#4F6370] font-bold rounded-lg mt-2">
            <DatePicker
              selectsRange
              startDate={startDate}
              endDate={endDate}
              onChange={(update) => {
                if (update) {
                  const [start, end] = update;
                  setStartDate(start);
                  setEndDate(end);
                }
              }}
              isClearable
              dateFormat="MMMM d, yyyy"
              customInput={<CustomInput value={startDate && endDate ? `${formatDateRange(startDate, endDate)}` : 'to'} />}
            />
          </div>
          <Link to="/generate-salary-slip">
            <button type="button" className="bg-[#0EA28880]/50 font-bold text-[#4F6370] py-4 px-8 rounded-lg mt-1">
              Generate New Salary
            </button>
          </Link>
        </div>

        <div className="mt-8">
          <div className="flex flex-col md:flex-row justify-center items-center mb-4 gap-8">
            <div className="bg-white py-2 px-12 rounded-lg border border-[#0EA288] text-center w-full md:w-auto">
              <h1 className='text-[#4F6370] font-bold'>20</h1>
              <p className='text-[#1CA78F] font-bold'>Resources</p>
            </div>
            <div className="bg-white py-2 px-12 rounded-lg border border-[#0EA288] text-center w-full md:w-auto">
              <h1 className='text-[#4F6370] font-bold'>Rs. 50,000</h1>
              <p className='text-[#1CA78F] font-bold'>Amount</p>
            </div>
          </div>

          <div className="space-y-4 mt-4">
            {Array(10).fill(0).map((_, index) => (
              <div key={index} className="flex flex-col md:flex-row justify-between items-center bg-[#D9D9D9] shadow-md rounded-lg p-4 border border-[#0EA288]">
                <div className="flex items-center mb-4 md:mb-0">
                  <div className="bg-white rounded-full border border-[#0EA288] h-8 w-8 flex items-center justify-center ">
                  </div>
                  <div className="ml-4 flex items-center">
                    <p className="text-[#000000] mr-4">Employee Name (NS-00001)</p>
                    <p className="text-[#000000] underline">Front end Developer</p>
                  </div>
                </div>
                <div className="text-right mt-4 md:mt-4">
                  <p className="text-[#000000]">Rs. 50,000</p>
                </div>
                <div className="flex items-center mt-4 md:mt-0">
                  <p className="text-[#0EA288]">June 20, 2024</p>
                </div>
                <button className="ml-4 mt-4 md:mt-0">
                  <img src={DownloadIcon} alt="download-icon" className="w-10 h-10" />
                </button>
              </div>
            ))}
          </div>
        </div>
      </main>
    </div>
  );
};

export default Salaries;
