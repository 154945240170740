import React from 'react';
import Fahad from '../assets/images/CeoFahad.png';
import LinkedIn from '../assets/images/linkedin.png';
import YT from '../assets/images/yt.png';
import Calendly from '../assets/images/calendly.png';

const teamMembers = [
  {
    name: "Mr. Fahad Shahzad",
    experience: "5+ Years in Tech Industry",
    certification: "Certified Software Engineer",
    priorPositions: "Prior CTO in Augier.ai, Impros.io, FasTech Systems Pvt. Ltd",
    role: "CEO & Founder NovaSync Dynamics",
    imgSrc: Fahad,
    linkedIn: LinkedIn,
    youtube: YT,
    website: Calendly,
    socialLinks: {
      linkedIn: "https://www.linkedin.com/in/fahadqureshi786/",
      youtube: "https://www.youtube.com/@NovaSyncDynamics-sg9hm",
      website: "https://calendly.com/fahadqureshi/meeting-request"
    }
  },
  {
    name: "Ms. Zoya",
    experience: "6+ Years in Tech Industry",
    certification: "Certified Software Engineer",
    priorPositions: "Prior Senior Software Engineer in UK based firm",
    role: "Managing Director @NovaSync Dynamics",
    imgSrc: "https://media.licdn.com/dms/image/C4D03AQGa-Omm98NQgA/profile-displayphoto-shrink_200_200/0/1647255840790?e=2147483647&v=beta&t=ZOZRUH9Jm08JlxfdpqxfsQQCczoIdCJ30BWq_Bx4lQE", 
    linkedIn: LinkedIn,
    youtube: YT,
    website: Calendly,
    socialLinks: {
      linkedIn: "https://www.linkedin.com/in/zoya-memon-3b50891b1/",
      youtube: "https://www.youtube.com/@NovaSyncDynamics-sg9hm",
      website: "https://calendly.com/fahadqureshi/meeting-request"
    }
  },
];

const Team = () => {
  return (
    <div id="about-us" className="py-10 px-4 md:px-0">
      <h2 className="font-bold text-center text-[#4F6370] text-4xl leading-[60px]  mb-6">Meet Us</h2>
      <div className="flex flex-wrap justify-center gap-8">
        {teamMembers.map((member, index) => (
          <div key={index} className="bg-[#0EA288] p-6 rounded-lg shadow-lg w-full md:w-96 sm:w-[23rem]">
            <img className="w-24 h-24 rounded-full mx-auto" src={member.imgSrc} alt={member.name} />
            <h3 className="text-xl font-bold text-left mt-4 text-[#FFFFFF]">{member.name}</h3>
            <ul className="mt-4 text-[#FFFFFF] text-left list-disc pl-5">
              <li>{member.experience}</li>
              <li>{member.certification}</li>
              <li>{member.priorPositions}</li>
            </ul>
            <p className="text-left font-semibold mt-4 text-[#FFFFFF] whitespace-nowrap">{member.role}</p>
            <div className="flex justify-between mt-4 space-x-4">
              <a href={member.socialLinks.linkedIn} target="_blank" rel="noopener noreferrer" className="transform hover:translate-y-1 transition-transform duration-300">
                <img src={member.linkedIn} alt="LinkedIn" className="w-6 h-6" />
              </a>
              <a href={member.socialLinks.youtube} target="_blank" rel="noopener noreferrer" className="transform hover:translate-y-1 transition-transform duration-300">
                <img src={member.youtube} alt="YouTube" className="w-6 h-6" />
              </a>
              <a href={member.socialLinks.website} target="_blank" rel="noopener noreferrer" className="transform hover:translate-y-1 transition-transform duration-300">
                <img src={member.website} alt="Website" className="w-6 h-6" />
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Team;
