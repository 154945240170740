import React from 'react';
import { Link } from 'react-router-dom';
import Footer from "./Footer";
import Navbar from "./Navbar";
import RelatedProject from "./related";

const UIDesigningPage = () => {
    const keyword = "AI"; // Example: Filter projects with 'AI' in the title

    return (
        <div>
            <Navbar/>
            {/* Hero Section */}
            <section className="relative w-full h-96 bg-cover bg-center" style={{ backgroundImage: "url('https://example.com/your-banner-image.jpg')" }}>
                <div className="absolute inset-0 bg-black opacity-50"></div>
                <div className="absolute inset-0 flex flex-col justify-center items-center text-white text-center px-6">
                    <h1 className="text-4xl md:text-6xl font-semibold leading-tight mb-4">Crafting Beautiful & Intuitive UI/UX Designs</h1>
                    <p className="text-lg md:text-2xl mb-6">Transforming user experiences through innovative and user-centric designs that engage and delight.</p>
                    {/*<Link to="/contact" className="bg-[#0EA288] text-white font-semibold py-2 px-6 rounded-lg hover:bg-[#0EA288]/90 transition duration-300">*/}
                    {/*    Get in Touch*/}
                    {/*</Link>*/}
                </div>
            </section>

            {/* Introduction Section */}
            <section className="container mx-auto px-6 py-12">
                <h2 className="text-3xl font-semibold text-[#0EA288] mb-6">Why UI/UX Design Matters</h2>
                <p className="text-justify text-lg text-[#4F6370] mb-6">
                    The design of your website or application plays a critical role in user engagement and conversion. A great UI/UX design is not just about looking beautiful, it’s about creating an experience that’s intuitive, efficient, and enjoyable. At NovaSync, we focus on designing interfaces that are aesthetically appealing while ensuring seamless user experiences. Whether it’s a mobile app, a website, or an enterprise solution, our team will work with you to design an interface that fits your brand, meets your users’ needs, and drives your business goals.
                </p>

                {/* Key Features Section */}
                <h3 className="text-2xl font-semibold text-[#0EA288] mb-6">Our UI/UX Design Services</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">User Research</h4>
                        <p className="text-justify text-[#4F6370]">
                            We conduct in-depth user research to understand the behavior, pain points, and needs of your target audience. This helps us create designs that resonate with users and solve their problems effectively.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">Wireframing & Prototyping</h4>
                        <p className="text-justify text-[#4F6370]">
                            We create wireframes and interactive prototypes to visualize the structure and flow of your product. This helps to validate ideas early and make changes before full-scale development begins.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">Visual Design</h4>
                        <p className="text-justify text-[#4F6370]">
                            Our design team creates visually stunning interfaces that align with your brand’s identity. We focus on color theory, typography, and layout to ensure that your design is not only functional but also visually appealing.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">Usability Testing</h4>
                        <p className="text-justify text-[#4F6370]">
                            We conduct usability testing to identify any usability issues and gather feedback from real users. This helps us refine the design to ensure an intuitive and frictionless user experience.
                        </p>
                    </div>
                </div>
            </section>

            {/* Technologies Section */}
            <section className="bg-[#f9f9f9] py-12">
                <div className="container mx-auto px-6">
                    <h3 className="text-2xl font-semibold text-[#0EA288] mb-6">Design Tools We Use</h3>
                    <p className="text-justify text-lg text-[#4F6370] mb-6">
                        We use the latest design tools and technologies to deliver modern, responsive, and engaging UI/UX designs:
                    </p>
                    <ul className="text-justify list-disc pl-6 text-[#4F6370]">
                        <li>Figma, Sketch, Adobe XD for wireframing, prototyping, and visual design</li>
                        <li>InVision for prototyping and collaboration with clients and developers</li>
                        <li>Axure for advanced wireframes and high-fidelity prototypes</li>
                        <li>Zeplin for handoff and collaboration with development teams</li>
                    </ul>
                </div>
            </section>

            {/* UI/UX Design Process Section */}
            <section className="container mx-auto px-6 py-12">
                <h3 className="text-2xl font-semibold text-[#0EA288] mb-6">Our UI/UX Design Process</h3>
                <div className="space-y-6">
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">1. Discovery & Research</h4>
                        <p className="text-justify text-[#4F6370]">
                            We begin by understanding your business goals, target audience, and the problems your product solves. This helps inform the design strategy and ensures we create the best user experience.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">2. Wireframing & Prototyping</h4>
                        <p className="text-justify text-[#4F6370]">
                            Our designers create wireframes and interactive prototypes that provide a clear visual direction and allow early testing of the user flow.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">3. Visual Design</h4>
                        <p className="text-justify text-[#4F6370]">
                            Once the wireframes and prototypes are approved, we move on to the visual design phase where we design the final look and feel of the interface, ensuring it aligns with your brand’s identity.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">4. Usability Testing</h4>
                        <p className="text-justify text-[#4F6370]">
                            We conduct usability testing with real users to identify any pain points or friction in the design. This helps us refine the interface and improve user satisfaction.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">5. Handoff & Collaboration</h4>
                        <p className="text-justify text-[#4F6370]">
                            We collaborate closely with developers to ensure the final design is implemented accurately, providing all necessary assets and guidelines for a seamless handoff.
                        </p>
                    </div>
                </div>
            </section>
            <RelatedProject keyword={keyword}/>

            <Footer />
        </div>
    );
};

export default UIDesigningPage;
