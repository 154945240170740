import React from 'react';
import { FaTimes } from 'react-icons/fa';

const AddInvite = ({ isVisible, onClose }) => {
  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg shadow-lg w-1/2 relative">
        <h2 className="text-xl font-bold text-center mb-4">Invite Customer</h2>
        <div className="mb-4">
          <label className="block text-sm font-semibold mb-2" htmlFor="customerEmail">Customer Email</label>
          <input 
            id="customerEmail" 
            type="email" 
            className="p-2 border rounded w-full border-[#0EA288] focus:outline-none bg-[#D9D9D980]/50" 
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-semibold mb-2" htmlFor="customMessage">Customize Message</label>
          <textarea 
            id="customMessage" 
            className="p-2 border rounded w-full border-[#0EA288] focus:outline-none  bg-[#D9D9D980]/50" 
            rows="4"
          />
        </div>
        <div className="flex justify-end">
          <button className="bg-[#0EA288] text-white py-2 px-4 rounded mr-4">Send Invite</button>
        </div>
        <button
          className="absolute top-4 right-4 text-gray-600 hover:text-gray-800"
          onClick={onClose}
        >
          <FaTimes size={24} />
        </button>
      </div>
    </div>
  );
};

export default AddInvite;
