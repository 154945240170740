import React, { useState } from "react";

const Signup = ({ showSignupModal, setShowSignupModal, setShowModal }) => {
    const [signupData, setSignupData] = useState({
        username: '',
        email: '',
        password: '',
        contact: '',
        address: '',
    });

    const handleSignup = () => {
        // Handle signup logic here
        alert("Signup logic not implemented");
        setShowSignupModal(false);
    };

    const handleShowLogin = () => {
        setShowSignupModal(false);
        setShowModal(true);
    };

    return (
        <>
            {showSignupModal && (
                <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="relative my-6 mx-auto w-full max-w-md lg:max-w-lg">
                        <div
                            className="shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none rounded-lg">
                            <div className="flex items-end p-5 justify-end">
                                <button
                                    className="bg-transparent border-2 rounded-full text-black hover:bg-gray-300 flex justify-center items-center"
                                    onClick={() => setShowSignupModal(false)}
                                    style={{ width: '2rem', height: '2rem' }}
                                >
                                    <span className="text-black opacity-7 text-xl">x</span>
                                </button>
                            </div>
                            <div className="flex content-center justify-center">
                                <h3 className="font-bold text-2xl text-center">Sign Up</h3>
                            </div>
                            <div className="relative p-6 flex-auto">
                                <form className="px-4 pt-3 w-full">
                                    <label className="block text-black text-sm font-bold mb-2"
                                           htmlFor="signup_username">
                                        Full Name
                                    </label>
                                    <input
                                        className="shadow bg-[rgba(217,217,217,0.5)] appearance-none border rounded w-full py-2 px-3 text-black"
                                        type="text"
                                        id="signup_username"
                                        value={signupData.username}
                                        onChange={(e) => setSignupData({ ...signupData, username: e.target.value })}
                                    />
                                    <label className="block text-black text-sm font-bold mb-2 pt-4"
                                           htmlFor="signup_email">
                                        Email
                                    </label>
                                    <input
                                        className="shadow bg-[rgba(217,217,217,0.5)] appearance-none border rounded w-full py-2 px-3 text-black"
                                        type="email"
                                        id="signup_email"
                                        value={signupData.email}
                                        onChange={(e) => setSignupData({ ...signupData, email: e.target.value })}
                                    />
                                    <label className="block text-black text-sm font-bold mb-2"
                                           htmlFor="signup_contact">
                                        Contact
                                    </label>
                                    <input
                                        className="shadow bg-[rgba(217,217,217,0.5)] appearance-none border rounded w-full py-2 px-3 text-black"
                                        type="text"
                                        id="signup_contact"
                                        value={signupData.contact}
                                        onChange={(e) => setSignupData({ ...signupData, contact: e.target.value })}
                                    />
                                    <label className="block text-black text-sm font-bold mb-2"
                                           htmlFor="signup_address">
                                        Address
                                    </label>
                                    <input
                                        className="shadow bg-[rgba(217,217,217,0.5)] appearance-none border rounded w-full py-2 px-3 text-black"
                                        type="text"
                                        id="signup_address"
                                        value={signupData.address}
                                        onChange={(e) => setSignupData({ ...signupData, address: e.target.value })}
                                    />
                                    <label className="block  text-black text-sm font-bold mb-2 pt-4"
                                           htmlFor="signup_password">
                                        Password
                                    </label>
                                    <input
                                        className="shadow bg-[rgba(217,217,217,0.5)] appearance-none border rounded w-full py-2 px-3 text-black"
                                        type="password"
                                        id="signup_password"
                                        value={signupData.password}
                                        onChange={(e) => setSignupData({ ...signupData, password: e.target.value })}
                                    />
                                    <label className="block text-black text-sm font-bold mb-2 pt-4"
                                           htmlFor="signup_confirm_password">
                                        Confirm Password
                                    </label>
                                    <input
                                        className="shadow bg-[rgba(217,217,217,0.5)] appearance-none border rounded w-full py-2 px-3 text-black"
                                        type="password"
                                        id="signup_confirm_password"
                                        value={signupData.password}
                                        onChange={(e) => setSignupData({ ...signupData, password: e.target.value })}
                                    />
                                    <div className="flex justify-end mt-6">
                                        <button
                                            className="text-white bg-[#0EA288] w-full md:w-[250px] active:bg-[#0EA288] font-bold text-sm px-8 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none"
                                            type="button"
                                            onClick={handleSignup}
                                        >
                                            Signup
                                        </button>
                                    </div>
                                    <div className="flex flex-row justify-between">
                                        <div className="flex justify-start mt-4 ml-4">
                                            <p className="">Already have an account</p>
                                            <a
                                                href="#"
                                                className="text-sm  pl-2 pt-1 font-bold bold text-[#0EA288] hover:text-[#0EA288] hover:underline"
                                                onClick={handleShowLogin}
                                            >
                                                Login
                                            </a>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Signup;
