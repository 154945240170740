import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import CompanyLogo from "../assets/company/image 5.png";
import Signup from './Signup';
import ResetPassword from './Reset';
import {APIs} from "../constants/APIs";
import axios from "axios";

const Login = ({ showLoginModal, setShowLoginModal }) => {
    const [showSignupModal, setShowSignupModal] = useState(false);
    const [showResetModal, setShowResetModal] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const [loginData, setLoginData] = useState({
        email: '',
        password: '',
        role: ''
    });

    const navigate = useNavigate();

    const handleLogin = async () => {
        setLoading(true)
        await axios.post(APIs.LOGIN, loginData).then((response) => {
            sessionStorage.setItem('user', JSON.stringify(response.data));
            setLoading(false)
            setError('')
            navigate('/dashboard');
        }, (error) => {
            setError('Invalid Credentials')
            setLoading(false)
        })
    };

    return (
        <>
            {showLoginModal && (
                <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="relative my-6 mx-auto w-full max-w-md lg:max-w-lg">
                        <div className="shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none rounded-lg">
                            <div className="flex items-end p-5 justify-end">
                                <button
                                    className="bg-transparent border-2 rounded-full text-black hover:bg-gray-300 flex justify-center items-center"
                                    onClick={() => setShowLoginModal(false)}
                                    style={{ width: '2rem', height: '2rem' }}
                                >
                                    <span className="text-black opacity-7 text-xl">x</span>
                                </button>
                            </div>
                            <div className="flex content-center justify-center">
                                <img src={CompanyLogo} alt="Logo" className="w-[179.96px] h-[100px]" />
                            </div>
                            {
                                error && <h3 className="text-center text-[#fe0101]">{error}</h3>
                            }
                            <div className="relative p-6 flex-auto">
                                <form className="px-4 pt-3 w-full">
                                    <label className="block text-black text-sm font-bold mb-2" htmlFor="user_email">
                                        Username or Email
                                    </label>
                                    <input
                                        className="shadow bg-[rgba(217,217,217,0.5)] appearance-none border rounded w-full py-2 px-3 text-black"
                                        type="text"
                                        id="user_email"
                                        value={loginData.email}
                                        onChange={(e) => setLoginData({ ...loginData, email: e.target.value })}
                                    />
                                    <label className="block text-black text-sm font-bold mb-2 pt-4" htmlFor="password">
                                        Password
                                    </label>
                                    <input
                                        className="shadow bg-[rgba(217,217,217,0.5)] appearance-none border rounded w-full py-2 px-3 text-black"
                                        type="password"
                                        id="password"
                                        value={loginData.password}
                                        onChange={(e) => setLoginData({ ...loginData, password: e.target.value })}
                                    />
                                    <div className="flex justify-end mt-6">
                                        <button disabled={loading || !loginData.email || !loginData.password}
                                            className="text-white bg-[#0EA288] w-full md:w-[250px] active:bg-[#0EA288] font-bold text-sm px-8 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none"
                                            type="button"
                                            onClick={handleLogin}
                                        >
                                            {loading ? 'Please wait...': 'Login'}
                                        </button>
                                    </div>
                                </form>
                                <div className="flex flex-row justify-between">
                                    <div className="flex justify-start mt-4 ml-4">
                                        <p className=""> Don't have an Account ?</p>
                                        <a
                                            href="#"
                                            className="text-sm pl-2 pt-1 font-bold bold text-[#0EA288] hover:text-[#0EA288] hover:underline"
                                            onClick={() => {
                                                setShowLoginModal(false);
                                                setShowSignupModal(true);
                                            }}
                                        >
                                            Signup
                                        </a>
                                    </div>
                                    <div className="flex justify-start mt-4 ml-4">
                                        <p className="">Forget Password?</p>
                                        <a
                                            href="#"
                                            className="text-sm  pl-2 pt-1 font-bold bold text-[#0EA288] hover:text-[#0EA288] hover:underline"
                                            onClick={() => {
                                                setShowLoginModal(false);
                                                setShowResetModal(true);
                                            }}
                                        >
                                            Reset
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <Signup showSignupModal={showSignupModal} setShowSignupModal={setShowSignupModal} setShowModal={setShowLoginModal} />
            <ResetPassword showResetModal={showResetModal} setShowResetModal={setShowResetModal} />
        </>
    );
};

export default Login;
