import React, { useState } from 'react';
import AddNotes from './AddNotes';
import { FaEdit, FaTrash } from 'react-icons/fa';

const Notes = ({ show, onClose }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [currentNote, setCurrentNote] = useState(null);
  const [notes, setNotes] = useState([]);

  const handleAddNote = (note) => {
    if (isEdit) {
      setNotes(notes.map((n, index) => (index === currentNote.index ? note : n)));
    } else {
      setNotes([...notes, note]);
    }
    setIsEdit(false);
    setCurrentNote(null);
    setIsModalOpen(false);
  };

  const handleEditNote = (index) => {
    setCurrentNote({ ...notes[index], index });
    setIsEdit(true);
    setIsModalOpen(true);
  };

  const handleDeleteNote = (index) => {
    setNotes(notes.filter((_, i) => i !== index));
  };

  return (
    <div className={`fixed inset-0 z-50 ${show ? 'opacity-100' : 'opacity-0 pointer-events-none'} transition-opacity duration-300 flex items-center justify-end`}>
      {/* Background overlay */}
      <div className="fixed inset-0 bg-black opacity-50" onClick={onClose}></div>
      
      {/* Modal content */}
      <div className={`bg-white w-full md:w-3/4 h-full shadow-lg transform transition-transform duration-300 ease-in-out ${show ? 'translate-x-0' : 'translate-x-full'} flex flex-col`}>
        <div className="p-4 md:p-6 h-full flex flex-col relative">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-bold">Add Daily Notes</h2>
            <button onClick={onClose} className="text-red-500 hover:text-red-700 text-3xl font-bold">
              &times;
            </button>
          </div>
          {/* All notes */}
          <div className="mt-4 flex-grow overflow-y-auto">
            {notes.map((note, index) => (
              <div key={index} className="border border-gray-300 p-4 mb-4 rounded relative">
                <h3 className="text-lg font-semibold">Title: {note.title}</h3>
                <p className="text-sm md:text-base">Description: {note.content}</p>
                <div className="absolute top-2 right-2 flex space-x-2 gap-3">
                  <button onClick={() => handleEditNote(index)} className="text-[#0EA288] hover:text-[#2e6158] w-6 h-6 text-xl md:text-2xl">
                    <FaEdit />
                  </button>
                  <button onClick={() => handleDeleteNote(index)} className="text-red-500 hover:text-red-700 w-6 h-6 text-xl md:text-2xl mr-2">
                    <FaTrash />
                  </button>
                </div>
              </div>
            ))}
            {/* Add button at the bottom of all notes */}
            <div className='flex justify-center mt-4'>
              <button
                type="button"
                className="bg-[#0EA288] text-white py-2 px-4 rounded hover:bg-[#2e6158] md:w-32"
                onClick={() => {
                  setIsEdit(false);
                  setIsModalOpen(true);
                }}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
      <AddNotes
        show={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onAddNote={handleAddNote}
        note={isEdit ? currentNote : null}
      />
    </div>
  );
};

export default Notes;
