import React, { useState } from "react";

const ResetPassword = ({ showResetModal, setShowResetModal }) => {
    const [email, setEmail] = useState('');
    const [showOtpModal, setShowOtpModal] = useState(false);
    const [otp, setOtp] = useState(new Array(6).fill(""));
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const handleReset = () => {
        // Handle sending the OTP here
        alert("OTP sent to your email");
        setShowResetModal(false);
        setShowOtpModal(true);
    };

    const handleOtpChange = (e, index) => {
        if (isNaN(e.target.value)) return false;
        const newOtp = [...otp];
        newOtp[index] = e.target.value;
        setOtp(newOtp);

        // Automatically move to the next input field
        if (e.target.value !== "" && index < otp.length - 1) {
            document.getElementById(`otp-input-${index + 1}`).focus();
        }
    };

    const handleOtpSubmit = () => {
        // Handle OTP validation and password reset logic here
        if (newPassword !== confirmPassword) {
            alert("Passwords do not match");
            return;
        }
        alert("Password reset logic not implemented");
        setShowOtpModal(false);
    };

    return (
        <>
            {showResetModal && (
                <div className="flex justify-center items-center fixed inset-0 z-50 overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
                    <div className="relative w-full max-w-md lg:max-w-lg mx-auto my-6">
                        <div className="bg-white rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none">
                            <div className="flex items-center justify-end p-5 border-b border-solid border-gray-200 rounded-t">
                                <button
                                    className="bg-transparent border-2 rounded-full text-black hover:bg-gray-300 flex justify-center items-center"
                                    onClick={() => setShowResetModal(false)}
                                    style={{ width: '2rem', height: '2rem' }}
                                    aria-label="Close modal"
                                >
                                    <span className="text-black opacity-7 text-xl">×</span>
                                </button>
                            </div>
                            <div className="flex justify-center p-5">
                                <h3 className="text-2xl font-bold text-center">Reset Password</h3>
                            </div>
                            <div className="relative p-6 flex-auto">
                                <form className="px-4 pt-3 w-full">
                                    <label className="block text-black text-sm font-bold mb-2" htmlFor="reset_email">
                                        Enter your email
                                    </label>
                                    <input
                                        className="shadow bg-[rgba(217,217,217,0.5)] appearance-none border rounded w-full py-2 px-3 text-black"
                                        type="email"
                                        id="reset_email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    <div className="flex justify-end mt-6">
                                        <button
                                            className="text-white bg-[#0EA288] w-full md:w-[250px] active:bg-[#0EA288] font-bold text-sm px-8 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none"
                                            type="button"
                                            onClick={handleReset}
                                        >
                                            Next
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {showOtpModal && (
                <div className="flex justify-center items-center fixed inset-0 z-50 overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
                    <div className="relative w-full max-w-md lg:max-w-lg mx-auto my-6">
                        <div className="bg-white rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none">
                            <div className="flex items-center justify-end p-5 border-b border-solid border-gray-200 rounded-t">
                                <button
                                    className="bg-transparent border-2 rounded-full text-black hover:bg-gray-300 flex justify-center items-center"
                                    onClick={() => setShowOtpModal(false)}
                                    style={{ width: '2rem', height: '2rem' }}
                                    aria-label="Close modal"
                                >
                                    <span className="text-black opacity-7 text-xl">×</span>
                                </button>
                            </div>
                            {/*<div className="flex justify-center p-5">*/}
                            {/*    <h3 className="text-2xl font-bold text-center">Enter OTP</h3>*/}
                            {/*</div>*/}
                            <div className="relative p-6 flex-auto">
                                <form className="px-4 pt-3 w-full">
                                    <label className="block  text-black text-sm font-bold mb-2 mt-4" htmlFor="new_password">
                                        Password
                                    </label>
                                    <input
                                        className="shadow bg-[rgba(217,217,217,0.5)] appearance-none border rounded w-full py-2 px-3 text-black"
                                        type="password"
                                        id="new_password"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                    />
                                    <label className="block text-black text-sm font-bold mb-2 mt-4" htmlFor="confirm_password">
                                        Confirm Password
                                    </label>
                                    <input
                                        className="shadow bg-[rgba(217,217,217,0.5)] appearance-none border rounded w-full py-2 px-3 text-black"
                                        type="password"
                                        id="confirm_password"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                    />
                                    <label className="block text-black text-sm font-bold mb-2 mt-4">
                                        Enter 6 digit OTP sent to your email
                                    </label>
                                    <div className="px-6">
                                        <div className="flex justify-evenly  items-center ">
                                            {otp.map((data, index) => (
                                                <input
                                                    key={index}
                                                    className=" appearance-none border border-[#0EA288] rounded w-12 py-2  text-black text-center"
                                                    type="text"
                                                    id={`otp-input-${index}`}
                                                    value={data}
                                                    maxLength="1"
                                                    onChange={(e) => handleOtpChange(e, index)}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                    <div className="flex justify-end mt-6">
                                        <button
                                            className="text-white bg-[#0EA288] w-full md:w-[150px] active:bg-[#0EA288] font-bold text-sm px-8 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none"
                                            type="button"
                                            onClick={handleOtpSubmit}
                                        >
                                            Confirm
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ResetPassword;
