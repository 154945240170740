import React, { useEffect, useState } from 'react';
import deleteVector from '../assets/images/delete.png';
import { useNavigate, useParams } from 'react-router-dom';
import Nav from './Nav';
import ReactQuill from "react-quill";

const EditProject = ({ projects, updateProject }) => {
  const { key } = useParams();

  const [projectData, setProjectData] = useState({
    title: '',
    key: '',
    technologies: '',
    description: '',
    resources: '',
    clientName: '',
    budget: '',
    startDate: '',
    endDate: '',
    status: '', // Added status here
  });

  // Define possible statuses
  const statuses = ['Todo', 'In Progress', 'Done', 'Under Client Review'];

  // Navigate
  const navigate = useNavigate();
  const [value, setValue] = useState('');
  useEffect(() => {
    const project = projects.find((proj) => proj.key === key);
    if (project) {
      setProjectData(project);
    }
  }, [key, projects]);
  const handleChangeDescription = (content, delta, source, editor) => {
    setValue(content);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Calculate duration in days
    const start = new Date(projectData.startDate);
    const end = new Date(projectData.endDate);
    const duration = Math.ceil((end - start) / (1000 * 60 * 60 * 24));

    updateProject({ ...projectData, duration });
    navigate('/all-projects');
  };

  const handleChange = (e) => {
    e.preventDefault();
    setProjectData({ ...projectData, [e.target.name]: e.target.value });
  };

  return (
    <div className="min-h-screen">
      {/* Header */}
      <Nav mainHeading="Project Management Portal" />
      {/* Main Form */}
      <div className="p-4">
        <div className="p-4 sm:p-8">
          {/* delete and title */}
          <div className="flex justify-between px-1">
            <p className="text-xl sm:text-2xl font-bold">Project Title</p>
            <div>
              <img src={deleteVector} alt="Delete" className="w-6 sm:w-8 cursor-pointer" />
            </div>
          </div>
          {/* Form */}
          <form className="mt-4" onSubmit={handleSubmit}>
            <div className="mb-6">
              <label className="block text-base sm:text-lg mb-2 font-semibold" htmlFor="projectTitle">Project Title</label>
              <input
                name="title"
                type="text"
                id="projectTitle"
                className="w-full p-2 sm:p-3 border rounded-lg bg-[#D9D9D980]/50 focus:outline-none"
                maxLength={100}
                onChange={handleChange}
                value={projectData.title}
              />
            </div>
            {/* Project key and status */}
            <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 mb-4">
              <div className="flex-1">
                <label className="block text-base sm:text-lg mb-2 font-semibold" htmlFor="projectKey">Project Key</label>
                <input
                  name="key"
                  type="text"
                  id="projectKey"
                  onChange={handleChange}
                  value={projectData.key}
                  className="w-full p-2 sm:p-3 border rounded-lg bg-[#D9D9D980]/50 focus:outline-none"
                />
              </div>
              {/* Status */}
              <div className="flex-1">
                <label className="block text-base sm:text-lg mb-2 font-semibold" htmlFor="projectStatus">Project Status</label>
                <select
                  name="status"
                  id="projectStatus"
                  onChange={handleChange}
                  value={projectData.status}
                  className="w-full p-2 sm:p-3 border rounded-lg bg-[#D9D9D980]/50 focus:outline-none"
                >
                  {statuses.map((status, index) => (
                    <option key={index} value={status}>{status}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="mb-6">
              <label className="block text-base sm:text-lg mb-2 font-semibold" htmlFor="projectTechnologies">Project Technologies (Comma Separated)</label>
              <input
                name="technologies"
                type="text"
                id="projectTechnologies"
                className="w-full p-2 sm:p-3 border rounded-lg bg-[#D9D9D980]/50 focus:outline-none"
                onChange={handleChange}
                value={projectData.technologies}
                placeholder="React.js, Next.js, Node.js"
              />
            </div>

            <div className="mb-6">
              <label className="block text-base sm:text-lg mb-2 font-semibold" htmlFor="projectDescription">Project Description</label>
              <ReactQuill value={value} onChange={handleChangeDescription} />
            </div>

            <div className="mb-6">
              <label className="block text-base sm:text-lg mb-2 font-semibold" htmlFor="resourceUrls">Resources or Documents URLs</label>
              <input
                name="resources"
                type="text"
                id="resourceUrls"
                className="w-full p-2 sm:p-3 border rounded-lg bg-[#D9D9D980]/50 focus:outline-none"
                placeholder="https://drive.google.com/mydrive/docs/abc.pdf"
                onChange={handleChange}
                value={projectData.resources}
              />
            </div>

            <h3 className="text-lg sm:text-xl font-bold mb-4">Client Details</h3>

            <div className="mb-6">
              <label className="block text-base sm:text-lg mb-2 font-semibold" htmlFor="clientName">Client Name</label>
              <input
                name="clientName"
                type="text"
                id="clientName"
                className="w-full p-2 sm:p-3 border rounded-lg bg-[#D9D9D980]/50 focus:outline-none"
                maxLength={100}
                onChange={handleChange}
                value={projectData.clientName}
              />
            </div>

            <div className="mb-6">
              <label className="block text-base sm:text-lg mb-2 font-semibold" htmlFor="projectBudget">Project Budget</label>
              <input
                name="budget"
                type="text"
                id="projectBudget"
                className="w-full p-2 sm:p-3 border rounded-lg bg-[#D9D9D980]/50 focus:outline-none"
                onChange={handleChange}
                value={projectData.budget}
              />
            </div>

            <div className="mb-6">
              <label className="block text-base sm:text-lg mb-2 font-semibold">Project Deadline</label>
              <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
                <div className="flex-1">
                  <label className="block text-base sm:text-lg mb-2" htmlFor="startDate">From date</label>
                  <input
                    name="startDate"
                    type="date"
                    id="startDate"
                    value={projectData.startDate}
                    onChange={handleChange}
                    className="w-full p-2 sm:p-3 border rounded-lg bg-[#D9D9D980]/50 focus:outline-none"
                  />
                </div>
                <div className="flex-1">
                  <label className="block text-base sm:text-lg mb-2" htmlFor="endDate">To date</label>
                  <input
                    name="endDate"
                    type="date"
                    id="endDate"
                    value={projectData.endDate}
                    onChange={handleChange}
                    className="w-full p-2 sm:p-3 border rounded-lg bg-[#D9D9D980]/50 focus:outline-none"
                  />
                </div>
              </div>
            </div>

            <div className="text-right">
              <button type="submit" className="px-4 sm:px-6 py-2 sm:py-3 bg-[#0EA288] text-white rounded-lg hover:bg-[#2e6158]">
                Save Project
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditProject;
