import React from 'react';
import { Link } from 'react-router-dom';
import Footer from "./Footer";
import Navbar from "./Navbar";
import RelatedProject from "./related";

const CustomSoftwareDevelopmentPage = () => {
    const keyword = "AI"; // Example: Filter projects with 'AI' in the title
    return (
        <div>
            <Navbar/>
            {/* Hero Section */}
            <section className="relative w-full h-96 bg-cover bg-center" style={{ backgroundImage: "url('https://example.com/your-banner-image.jpg')" }}>
                <div className="absolute inset-0 bg-black opacity-50"></div>
                <div className="absolute inset-0 flex flex-col justify-center items-center text-white text-center px-6">
                    <h1 className="text-4xl md:text-6xl font-semibold leading-tight mb-4">Tailored Software Solutions for Your Unique Business Needs</h1>
                    <p className="text-lg md:text-2xl mb-6">Building custom software applications that are scalable, secure, and perfectly aligned with your business objectives.</p>
                    {/*<Link to="/contact" className="bg-[#0EA288] text-white font-semibold py-2 px-6 rounded-lg hover:bg-[#0EA288]/90 transition duration-300">*/}
                    {/*    Get in Touch*/}
                    {/*</Link>*/}
                </div>
            </section>

            {/* Introduction Section */}
            <section className="container mx-auto px-6 py-12">
                <h2 className="text-3xl font-semibold text-[#0EA288] mb-6">Why Custom Software Development?</h2>
                <p className="text-justify text-lg text-[#4F6370] mb-6">
                    Off-the-shelf software solutions often fail to meet the unique requirements of your business. Custom software development allows you to create applications that are tailored to your exact needs. At NovaSync, we build custom software that increases efficiency, enhances user experience, and supports business growth. Whether it's a web application, desktop software, or mobile app, our team has the expertise to bring your ideas to life.
                </p>

                {/* Key Features Section */}
                <h3 className="text-2xl font-semibold text-[#0EA288] mb-6">Our Custom Software Development Solutions</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">Tailored Web Applications</h4>
                        <p className="text-justify text-[#4F6370]">
                            We specialize in building custom web applications that perfectly align with your business processes, integrating seamlessly with your existing systems and databases.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">Mobile Application Development</h4>
                        <p className="text-justify text-[#4F6370]">
                            Whether it's iOS or Android, we create custom mobile applications that engage users and solve specific business challenges, with a focus on performance, design, and usability.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">Enterprise Solutions</h4>
                        <p className="text-justify text-[#4F6370]">
                            Our custom software solutions scale to meet the complex needs of large enterprises. From ERP systems to CRM platforms, we help automate processes and improve internal operations.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">Cloud-Based Solutions</h4>
                        <p className="text-justify text-[#4F6370]">
                            We develop cloud-native applications that leverage the power of cloud computing for greater flexibility, scalability, and cost efficiency, ensuring your systems are future-ready.
                        </p>
                    </div>
                </div>
            </section>

            {/* Technologies Section */}
            <section className="bg-[#f9f9f9] py-12">
                <div className="container mx-auto px-6">
                    <h3 className="text-2xl font-semibold text-[#0EA288] mb-6">Technologies We Use</h3>
                    <p className="text-justify text-lg text-[#4F6370] mb-6">
                        Our team uses the latest technologies to build robust and scalable custom software solutions:
                    </p>
                    <ul className="list-disc pl-6 text-[#4F6370]">
                        <li>Frontend: React.js, Angular, Vue.js, Tailwind CSS, HTML5, CSS3, JavaScript</li>
                        <li>Backend: Node.js, Express.js, Python, Ruby on Rails, Java</li>
                        <li>Database: MongoDB, PostgreSQL, MySQL, Microsoft SQL Server</li>
                        <li>Mobile: React Native, Flutter, Swift, Kotlin</li>
                        <li>Cloud: AWS, Microsoft Azure, Google Cloud</li>
                    </ul>
                </div>
            </section>

            {/* Custom Software Development Process Section */}
            <section className="container mx-auto px-6 py-12">
                <h3 className="text-2xl font-semibold text-[#0EA288] mb-6">Our Custom Software Development Process</h3>
                <div className="space-y-6">
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">1. Requirement Gathering</h4>
                        <p className="text-justify text-[#4F6370]">
                            We start by understanding your business goals and challenges, gathering detailed requirements to ensure we are on the same page from the outset.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">2. Design & Prototyping</h4>
                        <p className="text-justify text-[#4F6370]">
                            Our team creates wireframes and prototypes to visualize your software before development begins, allowing for feedback and iterations.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">3. Development</h4>
                        <p className="text-justify text-[#4F6370]">
                            Once the design is approved, our developers build the solution, following best practices for scalability, security, and performance.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">4. Testing & QA</h4>
                        <p className="text-justify text-[#4F6370]">
                            We perform rigorous testing to ensure the software works flawlessly, including functional, performance, security, and usability testing.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">5. Deployment & Maintenance</h4>
                        <p className="text-justify text-[#4F6370]">
                            Once deployed, we provide ongoing maintenance to keep your software up-to-date, secure, and running smoothly as your business evolves.
                        </p>
                    </div>
                </div>
            </section>
            <RelatedProject keyword={keyword}/>

            <Footer />
        </div>
    );
};

export default CustomSoftwareDevelopmentPage;
