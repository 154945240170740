import Navbar  from "./Navbar";
import Footer from  "./Footer"
import React, { useState, useEffect } from 'react';
import ExpandImg from '../assets/images/expand.png';
import Project from '../projects.json';
import { Link } from 'react-router-dom';  // Import Link for navigation

const AllProjectPage = () => {

    const [projects, setProjects] = useState([]);

    useEffect(() => {
        // Simulate fetching the projects from the Projects module
        setProjects(Project);
    }, []);

    console.log("All Projects Data: ", projects); // Debugging line


    return (
        <>
            <Navbar/>
            <div className="container mx-auto py-8 mt-2">
                <h1 className="m-5 mb-6 text-center font-bold text-4xl leading-tight text-[#4F6370] font-inter">
                    See How We Collaborated in Our Previous Work
                </h1>

                <div className="grid m-5 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 sm:px-4">
                    {projects.map((project) => (
                        <div
                            key={project.Id}
                            className="h-full rounded-xl shadow-cla-blue border border-[#0EA288] overflow-hidden"
                        >
                            <Link to={`/projects/${project.title}`}>
                            <img
                                src={project.pImg}
                                alt={project.title}
                                className="w-full object-center scale-110 transition-all duration-400 hover:scale-100"
                            />
                            </Link>

                            <div className="p-6">
                                <div className="flex justify-between items-center mb-4">
                                    <Link to={`/projects/${project.title}`}>
                                        <h2 className="text-[#4F6370] text-lg font-semibold ">
                                            {project.title}
                                        </h2>
                                    </Link>
                                    <Link to={`/projects/${project.title}`}>
                                        <img
                                            src={ExpandImg}
                                            alt={`${project.title} icon`}
                                            className="w-5 h-5 cursor-pointer"
                                        />
                                    </Link>
                                </div>
                                <span className="text-[#4F6370]">{project.subTitle}</span>
                                <div className="text-right">
                                    <p className="text-[#0EA288] text-lg font-semibold">
                                        {project.duration}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Footer/>
        </>
    );
};

export default AllProjectPage;
