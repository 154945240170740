import React from 'react';
import { Link } from 'react-router-dom';
import Footer from "./Footer";
import Navbar from "./Navbar";
import RelatedProject from "./related";

const DeploymentPage = () => {
    const keyword = "AI"; // Example: Filter projects with 'AI' in the title

    return (
        <div>
            <Navbar/>
            {/* Hero Section */}
            <section className="relative w-full h-96 bg-cover bg-center" style={{ backgroundImage: "url('https://example.com/your-banner-image.jpg')" }}>
                <div className="absolute inset-0 bg-black opacity-50"></div>
                <div className="absolute inset-0 flex flex-col justify-center items-center text-white text-center px-6">
                    <h1 className="text-4xl md:text-6xl font-semibold leading-tight mb-4">Seamless Deployment for Scalable Solutions</h1>
                    <p className="text-lg md:text-2xl mb-6">We ensure a smooth launch and reliable deployment process to take your product live with confidence.</p>
                    {/*<Link to="/contact" className="bg-[#0EA288] text-white font-semibold py-2 px-6 rounded-lg hover:bg-[#0EA288]/90 transition duration-300">*/}
                    {/*    Get in Touch*/}
                    {/*</Link>*/}
                </div>
            </section>

            {/* Introduction Section */}
            <section className="container mx-auto px-6 py-12">
                <h2 className="text-3xl font-semibold text-[#0EA288] mb-6">Why Deployment is Critical</h2>
                <p className="text-justify text-lg text-[#4F6370] mb-6">
                    Deploying your application or website is a crucial step in its lifecycle. A successful deployment ensures that your product is stable, scalable, and easily maintainable. At NovaSync, we use best practices in deployment to ensure your software is ready to handle the demands of real-world usage. From seamless cloud deployments to CI/CD pipelines, we ensure that every deployment is efficient and risk-free.
                </p>

                {/* Key Features Section */}
                <h3 className="text-2xl font-semibold text-[#0EA288] mb-6">Our Deployment Solutions</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">Cloud Deployments</h4>
                        <p className="text-justify text-[#4F6370]">
                            We deploy your applications on cloud platforms like AWS, Google Cloud, or Azure, ensuring scalability, flexibility, and security.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">CI/CD Pipelines</h4>
                        <p className="text-justify text-[#4F6370]">
                            We implement continuous integration and continuous deployment (CI/CD) pipelines to automate testing, integration, and deployment, ensuring rapid, reliable delivery of new features.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">Rollback Strategy</h4>
                        <p className="text-justify text-[#4F6370]">
                            We prepare a rollback strategy to quickly revert to a stable version in case issues arise during deployment, minimizing downtime and risk.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">Production Monitoring</h4>
                        <p className="text-justify text-[#4F6370]">
                            After deployment, we implement production monitoring tools to track performance, detect anomalies, and ensure the system operates smoothly.
                        </p>
                    </div>
                </div>
            </section>

            {/* Technologies Section */}
            <section className="bg-[#f9f9f9] py-12">
                <div className="container mx-auto px-6">
                    <h3 className="text-2xl font-semibold text-[#0EA288] mb-6">Deployment Technologies We Use</h3>
                    <p className="text-justify text-lg text-[#4F6370] mb-6">
                        We use a range of modern tools to ensure your deployment is secure, fast, and scalable:
                    </p>
                    <ul className="list-disc pl-6 text-[#4F6370]">
                        <li>AWS, Google Cloud, Azure for cloud-based deployments</li>
                        <li>Docker and Kubernetes for containerization and orchestration</li>
                        <li>Jenkins, GitLab CI, CircleCI for automated CI/CD pipelines</li>
                        <li>Terraform and Ansible for infrastructure as code (IaC)</li>
                    </ul>
                </div>
            </section>

            {/* Deployment Process Section */}
            <section className="container mx-auto px-6 py-12">
                <h3 className="text-2xl font-semibold text-[#0EA288] mb-6">Our Deployment Process</h3>
                <div className="space-y-6">
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">1. Pre-Deployment Testing</h4>
                        <p className="text-justify text-[#4F6370]">
                            We thoroughly test your application in a staging environment to ensure everything works as expected before going live.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">2. Cloud Infrastructure Setup</h4>
                        <p className="text-justify text-[#4F6370]">
                            We set up the cloud infrastructure, ensuring high availability, scalability, and security for your application.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">3. Deployment Automation</h4>
                        <p className="text-justify text-[#4F6370]">
                            We automate the deployment process using CI/CD pipelines to ensure quick, reliable, and error-free deployments.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">4. Post-Deployment Monitoring</h4>
                        <p className="text-justify text-[#4F6370]">
                            After deployment, we monitor the system to ensure smooth operations and address any performance issues in real-time.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">5. Ongoing Maintenance & Support</h4>
                        <p className="text-justify text-[#4F6370]">
                            We provide ongoing maintenance and support to ensure your application stays up-to-date, secure, and performs optimally.
                        </p>
                    </div>
                </div>
            </section>
            <RelatedProject keyword={keyword}/>

            <Footer />
        </div>
    );
};

export default DeploymentPage;
