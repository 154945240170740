// import { useParams } from 'react-router-dom';
import React,{useState} from 'react';
import { FaSignOutAlt, FaStar } from 'react-icons/fa';
import companyLogo from '../assets/company/image 5.png'; 
import Select from 'react-select';
import Nav from '../components/Nav';


const UpdateDaily = () => {
    // const params = useParams();
    // const {name} = params;
    const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(null);
  const [completedTickets, setCompletedTickets] = useState([]);

  const tasks = [
    { id: 1, title: "Implement login feature", code: "NS-00001" },
    { id: 2, title: "Implement UI for Attendance page", code: "NS-00002" },
    { id: 3, title: "Implement UI for Sprint Portal of Admin", code: "NS-00003" },
    { id: 4, title: "Implement UI for Sprint Portal of Employee", code: "NS-00004" },
  ];

  const handleCompletedTicketsChange = (selectedOptions) => {
    setCompletedTickets(selectedOptions);
  };




  return (
    <div className="min-h-screen">
    {/* Header Section */}
    <Nav mainHeading="Daily Updates Submission Portal" />
    {/* Content Section */}
    <div className="p-4">
    <div className="p-8">
      {/* Dropdown Section */}
      <section className="mb-6">
            <p className="block text-lg mb-2">Which tickets you’ve completed?</p>
            <div className="p-4 border rounded-lg bg-[#D9D9D980]/50">
              <Select
                isMulti
                name="completedTickets"
                options={tasks.map(task => ({ value: task.id, label: `${task.title} (${task.code})` }))}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={handleCompletedTicketsChange}
              />
            </div>
          </section>

      {/* Target Completion Section */}
      <section className="mb-6">
        <p className="text-lg mb-4">Have you completed your target of today?</p>
        <div className="flex flex-row justify-center">
          <label className="mr-20 flex flex-col text-lg" for="specifyColor">
            <input type="radio" name="target" value="yes" className="mr-1 w-6 h-6 " id='specifyColor'/>
            Yes
          </label>
          <label className='flex flex-col' for="specifyColor">
            <input type="radio" name="target" value="no" className="mr-1 w-6 h-6" id='specifyColor' />
            No
          </label>
        </div>
      </section>
      {/* Comment Section */}
      <section>
        <p className="text-lg  mb-4">Any comments on your contribution?</p>
        <textarea className="bg-[#D9D9D980]/50 w-full p-2 border rounded-lg" rows="4" resize="none" ></textarea>
      </section>
      {/* Live demo */}
      <div>
              <label className="block text-lg mb-2 mt-2" htmlFor="demoLink">Live Demo URL of today’s work :) </label>
              <input 
                id="demoLink" 
                type="url" 
                name='demo'
                placeholder='e.g https://drive.google.com/...'
                className="p-2 border rounded-lg w-full  focus:outline-none bg-[#D9D9D980]/50" 
              />
            </div>
      {/* Start section */}
      <div className="mt-4">
        <p className="text-lg mb-4">Rate your experience</p>
        <div className="flex items-center justify-center gap-8">
  {[...Array(5)].map((star, index) => {
    const ratingValue = index + 1;

    return (
      <label key={index}>
        <input 
          type="radio"
          name="rating"
          value={ratingValue}
          onClick={() => setRating(ratingValue)}
          className="hidden"
        />
        <FaStar
          size={30}
          className={`cursor-pointer ${ratingValue <= (hover || rating) ? 'text-[#0EA288]' : 'text-gray-300'}`}
          onMouseEnter={() => setHover(ratingValue)}
          onMouseLeave={() => setHover(null)}
        />
      </label>
    );
  })}
</div>
        <div className="flex justify-end">
          <button className="mt-4 px-4 py-2 bg-[#0EA288] text-white rounded hover:bg-[#2e6158]">
          Submit Daily Report
          </button>
        </div>
      </div>
    </div>
    </div>
    </div>
  );
};

export default UpdateDaily;
