import React, { useState, useEffect } from 'react';

const AddNotes = ({ show, onClose, onAddNote, note }) => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');

  useEffect(() => {
    if (note) {
      setTitle(note.title);
      setContent(note.content);
    }
  }, [note]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onAddNote({
      title,
      content,
    });
    setTitle('');
    setContent('');
    onClose();
  };

  return (
    <div className={`fixed inset-0 z-50 ${show ? 'opacity-100' : 'opacity-0 pointer-events-none'} transition-opacity duration-300 flex items-center justify-end`}>
      {/* Background overlay */}
      <div className="fixed inset-0 bg-black opacity-50" onClick={onClose}></div>
      
      {/* Modal content */}
      <div className={`bg-white w-full sm:w-80 h-full shadow-lg transform transition-transform duration-300 ease-in-out ${show ? 'translate-x-0' : 'translate-x-full'} flex flex-col`}>
        <div className="p-6 h-full flex flex-col">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-bold">{note ? 'Edit Note' : 'Add Note'}</h2>
            <button onClick={onClose} className="text-red-500 hover:text-red-700 text-3xl font-bold">
              &times;
            </button>
          </div>
          <form className="flex-grow" onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-gray-700 mb-2 text-lg font-semibold" htmlFor="noteTitle">Title</label>
              <input
                className="w-full border border-[#0EA288] focus:outline-none p-2 rounded"
                id="noteTitle"
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 mb-2 text-lg font-semibold" htmlFor="noteContent">Description</label>
              <textarea
                className="w-full border border-[#0EA288] focus:outline-none p-2 rounded"
                id="noteContent"
                rows="5"
                value={content}
                onChange={(e) => setContent(e.target.value)}
              ></textarea>
            </div>
            <button type="submit" className="bg-[#0EA288] text-white py-2 px-4 rounded hover:bg-[#2e6158]">
              {note ? 'Update Note' : 'Add Note'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddNotes;
