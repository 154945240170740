import React, { useState } from 'react';
import companyLogo from '../assets/company/image 5.png';
import plus from '../assets/images/plus.png';
import { FaSignOutAlt, FaCalendarAlt } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import Nav from './Nav';
import ReactQuill from "react-quill";

const TicketSprint = () => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [value, setValue] = useState('');
    const handleChangeDescription = (content, delta, source, editor) => {
        setValue(content);
    };

    const navigate = useNavigate();

    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    const handleMultiSelectChange = (selected) => {
        setSelectedOptions(selected);
    };

    const multiSelectOptions = [
        { value: 'option1', label: 'Option 1' },
        { value: 'option2', label: 'Option 2' },
        { value: 'option3', label: 'Option 3' },
    ];

    return (
        <div className="min-h-screen">
            {/* Header */}
            <Nav mainHeading="Sprint Management" />
            {/* Main Form */}
            <div className='p-4 sm:p-6 lg:p-8'>
                <div className="p-4 sm:p-6 lg:p-8 ">
                    <p className='text-lg font-semibold mb-6'>Sprints NovaSync Dynamics Project</p>
                    {/* Form */}
                    <form>
                        {/* title and date picker */}
                        <div className="mb-6 mt-4 flex flex-wrap md:flex-nowrap justify-between items-center">
                            <div className="flex-1 mb-4 md:mb-0 md:mr-4">
                                <input
                                    name='title'
                                    type="text"
                                    id="sprintTitle"
                                    className="w-full p-3 border rounded-lg bg-[#D9D9D980]/50 focus:outline-none"
                                    maxLength={100}
                                    placeholder='Sprint Title...'
                                />
                            </div>
                            {/* Date picker */}
                            <div className="relative w-full md:w-60 ml-0 md:ml-6 bg-[#0EA288] rounded-lg border">
                                {!startDate && !endDate && (
                                    <label
                                        className="absolute left-4 top-2 text-lg font-semibold text-white cursor-pointer"
                                        htmlFor="dateRange"
                                        onClick={() => document.getElementById('dateRange').focus()}
                                    >
                                        Date Range
                                    </label>
                                )}
                                <DatePicker
                                    name='date'
                                    id="dateRange"
                                    onChange={handleDateChange}
                                    selected={startDate}
                                    startDate={startDate}
                                    endDate={endDate}
                                    selectsRange
                                    className="w-full p-3 bg-transparent focus:outline-none peer cursor-pointer"
                                />
                                {!startDate && !endDate && (
                                    <FaCalendarAlt
                                        className="absolute right-3 top-4 text-white cursor-pointer"
                                        onClick={() => document.getElementById('dateRange').focus()}
                                    />
                                )}
                            </div>
                        </div>
                        {/* Description */}
                        <div className="mb-6">
                            <label className="block text-lg mb-2 font-semibold" htmlFor="sprintDescription">Sprint Description</label>
                            <ReactQuill value={value} onChange={handleChangeDescription} />
                        </div>
                        {/* 3 dropdown */}
                        <div className='flex flex-wrap justify-between'>
                            {/* 1 */}
                            <div className="flex items-center space-x-4 w-full md:w-[300px] mb-4">
                                <label htmlFor="priority" className="text-lg font-semibold text-black whitespace-nowrap">Priority</label>
                                <select
                                    id="priority"
                                    className="h-12 bg-[#D9D9D980]/50 text-gray-600 text-base rounded-lg py-2.5 px-4 block w-full focus:outline-none"
                                >
                                    <option value="High" selected>High</option>
                                    <option value="Low">Low</option>
                                    <option value="Medium">Medium</option>
                                </select>
                            </div>
                            {/* 2 */}
                            <div className="flex items-center space-x-4 w-full md:w-[300px] mb-4">
                                <label htmlFor="parentTask" className="text-lg font-semibold text-black whitespace-nowrap">Parent Task</label>
                                <select
                                    id="parentTask"
                                    className="h-12 bg-[#D9D9D980]/50 text-gray-600 text-base rounded-lg py-2.5 px-4 block w-full focus:outline-none"
                                >
                                    <option value="01" selected>NS-001</option>
                                    <option value="02">NS-002</option>
                                    <option value="03">NS-003</option>
                                </select>
                            </div>
                            {/* 3 */}
                            <div className="flex items-center space-x-4 w-full md:w-[300px] mb-4">
                                <label htmlFor="multiSelect" className="text-lg font-semibold text-black whitespace-nowrap">Multi Select</label>
                                <Select
                                    id="multiSelect"
                                    options={multiSelectOptions}
                                    isMulti
                                    onChange={handleMultiSelectChange}
                                    className="w-full"
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            background:'rgba(217, 217, 217, 0.5)',
                                            borderRadius:'8px'
                                        }),
                                    }}
                                />
                            </div>
                        </div>
                        {/* Add another sprint */}
                        <div className="flex justify-center items-center w-full mt-4">
                            <div
                                className="flex items-center justify-center bg-[#D9D9D94D]/30 rounded-lg py-2 px-4 cursor-pointer w-full md:w-[500px] h-[70px]"
                            >
                                <img src={plus} alt="Plus" className="w-8 mr-3" />
                                <span className="text-black font-semibold text-lg">Add another sprint</span>
                            </div>
                        </div>
                        {/* button */}
                        <div className="text-right mt-4">
                            <button type="submit" className="px-6 py-3 bg-[#0EA288] text-white rounded-lg hover:bg-[#2e6158]">
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default TicketSprint;
