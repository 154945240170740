import React from 'react';
import { Link } from 'react-router-dom';
import Footer from "./Footer";
import Navbar from "./Navbar";
import RelatedProject from "./related";

const QATestingAndAutomationsPage = () => {
    const keyword = "AI"; // Example: Filter projects with 'AI' in the title

    return (
        <div>
            <Navbar/>
            {/* Hero Section */}
            <section className="relative w-full h-96 bg-cover bg-center" style={{ backgroundImage: "url('https://example.com/your-banner-image.jpg')" }}>
                <div className="absolute inset-0 bg-black opacity-50"></div>
                <div className="absolute inset-0 flex flex-col justify-center items-center text-white text-center px-6">
                    <h1 className="text-4xl md:text-6xl font-semibold leading-tight mb-4">Ensuring Quality with Comprehensive QA Testing & Automation</h1>
                    <p className="text-lg md:text-2xl mb-6">Boost your product’s performance and reliability with our end-to-end quality assurance testing and automation solutions.</p>
                    {/*<Link to="/contact" className="bg-[#0EA288] text-white font-semibold py-2 px-6 rounded-lg hover:bg-[#0EA288]/90 transition duration-300">*/}
                    {/*    Get in Touch*/}
                    {/*</Link>*/}
                </div>
            </section>

            {/* Introduction Section */}
            <section className="container mx-auto px-6 py-12">
                <h2 className="text-3xl font-semibold text-[#0EA288] mb-6">Why QA Testing & Automation Matters</h2>
                <p className="text-justify text-lg text-[#4F6370] mb-6">
                    In today’s fast-paced digital world, it’s crucial that your software is reliable, secure, and user-friendly. Our QA testing and automation services ensure that your product meets the highest quality standards. We help you identify and fix issues early, reduce time-to-market, and improve overall product performance. Whether you need manual testing, automated testing, or a combination of both, we have the expertise to ensure your software is of the highest quality.
                </p>

                {/* Key Features Section */}
                <h3 className="text-2xl font-semibold text-[#0EA288] mb-6">Our QA Testing & Automation Solutions</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">Manual Testing</h4>
                        <p className="text-justify text-[#4F6370]">
                            Our team of experts manually tests your application across various devices and browsers to identify any functional or usability issues. This ensures your software performs as expected in real-world scenarios.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">Automated Testing</h4>
                        <p className="text-justify text-[#4F6370]">
                            We create automated test scripts to quickly validate critical features, improving speed and efficiency while reducing human error. Our test automation frameworks are built to scale as your application evolves.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">Performance Testing</h4>
                        <p className="text-justify text-[#4F6370]">
                            Performance testing ensures that your application can handle peak traffic and stress scenarios. We help identify bottlenecks, scalability issues, and optimize your system for maximum efficiency.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">Security Testing</h4>
                        <p className="text-justify text-[#4F6370]">
                            We conduct rigorous security tests to identify vulnerabilities in your application, ensuring your users' data and privacy are protected from potential threats.
                        </p>
                    </div>
                </div>
            </section>

            {/* Technologies Section */}
            <section className="bg-[#f9f9f9] py-12">
                <div className="container mx-auto px-6">
                    <h3 className="text-2xl font-semibold text-[#0EA288] mb-6">Technologies We Use for QA Testing & Automation</h3>
                    <p className="text-justify text-lg text-[#4F6370] mb-6">
                        We use state-of-the-art tools and technologies to provide the best QA testing and automation services:
                    </p>
                    <ul className="list-disc pl-6 text-[#4F6370]">
                        <li>Selenium, Cypress, Appium for automated functional testing</li>
                        <li>JUnit, Mocha, Jasmine for test case development</li>
                        <li>LoadRunner, JMeter for performance testing</li>
                        <li>OWASP ZAP, Burp Suite for security testing</li>
                        <li>Jenkins, Travis CI for continuous integration and automated test execution</li>
                    </ul>
                </div>
            </section>

            {/* QA Testing & Automation Process Section */}
            <section className="container mx-auto px-6 py-12">
                <h3 className="text-2xl font-semibold text-[#0EA288] mb-6">Our QA Testing & Automation Process</h3>
                <div className="space-y-6">
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">1. Requirement Analysis</h4>
                        <p className="text-justify text-[#4F6370]">
                            We begin by understanding your product, business goals, and the testing requirements to ensure that we develop a comprehensive QA strategy.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">2. Test Plan Development</h4>
                        <p className="text-justify text-[#4F6370]">
                            Based on the analysis, we create a detailed test plan that outlines the scope, approach, and timelines for both manual and automated testing.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">3. Test Execution</h4>
                        <p className="text-justify text-[#4F6370]">
                            Our QA team executes the tests across multiple platforms and devices to ensure compatibility, performance, and security. We automate the most repetitive tasks to speed up the process.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">4. Bug Reporting & Fixing</h4>
                        <p className="text-justify text-[#4F6370]">
                            We document all issues, track them, and work with your development team to fix them, ensuring that the final product is stable and reliable.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">5. Continuous Monitoring & Improvement</h4>
                        <p className="text-justify text-[#4F6370]">
                            Once the software is live, we continuously monitor its performance and security, running automated tests to ensure that no new issues arise and that the system operates optimally.
                        </p>
                    </div>
                </div>
            </section>
            <RelatedProject keyword={keyword}/>

            <Footer />
        </div>
    );
};

export default QATestingAndAutomationsPage;
