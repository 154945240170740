import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';
import { FiShare2 } from 'react-icons/fi';

// Job listings data
const jobListings = [
    {
        id: 1,
        title: 'MERN Stack Developer',
        company: 'NovaSync Dynamics Private Limited',
        location: 'Islamabad, Pakistan',
        jobType: 'Onsite',
        imageUrl: 'https://res.cloudinary.com/df3ok5t8p/image/upload/v1725391625/novasync/sut9lgrfpgcxzf1ll64z.png',
        technicalSkills: [
            { category: 'Proficiency in MERN Stack', skills: ['MongoDB: Experience with database design, indexing, aggregation, and optimization.', 'Express.js: Strong understanding of RESTful APIs, middleware, and routing.', 'React.js: Expertise in developing interactive UI components using React, with state management (Zustand, Context API).', 'Node.js: Proficiency in building scalable back-end services and RESTful APIs.'] },
            { category: 'Front-end Development', skills: ['Strong knowledge of HTML5, CSS3, JavaScript (ES6+).', 'Experience with responsive design, cross-browser compatibility, and front-end build tools (Webpack, Babel).', 'Familiarity with UI/UX principles and frameworks like Bootstrap, Material-UI, or Tailwind CSS.', 'Familiarity with design to development of UI from figma to react codebase.', 'Ability to integrate RESTful Services with front-end pages using efficient data handling approaches.'] },
            { category: 'Back-end Development', skills: ['Expertise in server-side logic, authentication, LLM integration, Server Side Pages, and session management.', 'Experience with integrating third-party APIs like OpenAI, mail servers, messaging services like M3, microservices, and handling large-scale data.'] },
            { category: 'Database Management', skills: ['Strong understanding of NoSQL databases, specifically MongoDB, with experience in schema design and data modeling.', 'Ability to write complex queries and optimize database performance.'] },
            { category: 'Version Control', skills: ['Proficiency in Git and GitHub for code management, branching, and collaboration.'] },
            { category: 'Deployment and DevOps', skills: ['Experience with building the application and fixing the bugs facing under development.', 'Familiarity with cloud services (AWS, Azure, Google Cloud) and containerization (Docker) would be a plus point.'] },
            { category: 'Testing', skills: ['Knowledge of unit, integration, and end-to-end testing frameworks (Jest, Mocha, Cypress).'] },
            { category: 'Security', skills: ['Understanding of web security best practices, including data encryption, OAuth, AES, and JWT.'] }
        ],
        softSkills: ['Strong problem-solving and analytical skills.', 'Excellent communication and teamwork abilities.', 'Ability to work independently with minimal supervision and as part of a team.', 'Keen attention to detail and quality.'],
        compensation: {
            description: 'Salary from 100k to 150k per month',
            additionalInfo: 'Bonus calculation every 6 months. Salary increment yearly.'
        },
        applicationProcess: 'Send your resume to hr@novasyncdynamics.com'
    },
    {
        id: 2,
        title: 'Mid Level Backend Developer',
        company: 'NovaSync Dynamics Private Limited',
        location: 'Remote',
        jobType: 'Remote',
        imageUrl: 'https://res.cloudinary.com/df3ok5t8p/image/upload/v1725471654/novasync/sxnpv8tijq5ylou7hu4b.jpg',
        technicalSkills: [
            { category: 'Node.js', skills: ['Proficient in building scalable backend services and APIs using Node.js.', 'Strong understanding of event-driven architecture, asynchronous programming, and microservices in Node.js.'] },
            { category: 'API Development and Testing', skills: ['Expertise in designing and implementing RESTful APIs, including experience with GraphQL.', 'Proficient in using Postman for testing APIs, ensuring functionality, performance, and security.', 'Experience with API documentation tools like Swagger or Postman.'] },
            { category: 'Authentication and Security', skills: ['Hands-on experience with implementing secure authentication mechanisms, including OAuth2, AES, JWT, and session management.', 'Understanding of best practices for securing APIs, including data encryption and rate limiting.'] },
            { category: 'LLM Integrations', skills: ['Experience with integrating Large Language Models (LLMs) such as GPT-4, BERT, or custom models into backend systems.', 'Familiarity with deploying and fine-tuning LLMs for tasks like natural language processing, text generation, and conversational agents.', 'Experience working with vector databases and Lama models.'] },
            { category: 'Machine Learning and AI', skills: ['Knowledge of machine learning concepts, including model training, evaluation, and deployment.', 'Experience with ML frameworks such as TensorFlow, PyTorch, or scikit-learn.'] },
            { category: 'Database Design and Management', skills: ['Proficient in designing and managing relational (SQL) and non-relational (NoSQL) databases.', 'Experience with database performance optimization, indexing, and query optimization, particularly for ML-related data.'] },
            { category: 'Cloud Services and DevOps', skills: ['Experience with cloud platforms (AWS, Azure, Google Cloud) for deploying Node.js applications and ML models.', 'Knowledge of containerization (Docker) and orchestration (Kubernetes) for scalable deployments.', 'Familiarity with CI/CD pipelines for automated testing, deployment, and monitoring of backend services.'] }
        ],
        softSkills: ['Strong analytical skills for diagnosing and resolving complex backend and ML integration issues.', 'Ability to work closely with frontend developers, data scientists, and AI specialists to deliver robust backend services.', 'Effective communication skills for explaining technical concepts to both technical and non-technical stakeholders.', 'Eagerness to learn and adapt to new tools, technologies, and industry trends in backend and AI development.'],
        compensation: {
            description: 'Salary from 100k to 150k  per month depending upon the experience ',
            additionalInfo: 'Bonus calculation every Year months. Salary increment yearly.'
        },
        applicationProcess: 'Send your resume to hr@novasyncdynamics.com'
    },
    {
        id: 3,
        title: 'Internship Program',
        company: 'NovaSync Dynamics Private Limited',
        location: 'Islamabad, Pakistan',
        jobType: 'Internship',
        imageUrl: 'https://res.cloudinary.com/df3ok5t8p/image/upload/v1726664636/novasync/e3oti9xhgual9jsegtik.jpg',
        technicalSkills: [
            { category: 'Roles Offered', skills: ['Frontend Developer', 'Backend Developer', 'MERN Stack Developer', 'UI/UX Designer'] },
            { category: 'Duration', skills: ['6 weeks'] },
            { category: 'Learning Opportunities', skills: ['Hands-on experience with real-world projects', 'Mentorship from industry experts'] },
            { category: 'Potential', skills: ['Opportunity for full-time position based on performance', 'Certificate of internship completion'] }
        ],
        softSkills: [
            'Strong communication and teamwork abilities.',
            'Eagerness to learn and adapt in a fast-paced environment.',
            'Ability to collaborate with team members to achieve project goals.',
            'Willingness to take on challenges and demonstrate problem-solving abilities.'
        ],
        compensation: {
            description: '',
            additionalInfo: 'Certificate of completion and potential full-time opportunities for top performers.'
        },
        applicationProcess: 'Send your resume to hr@novasyncdynamics.com and specify the role you are applying for.'
    },
    {
        id: 5,
        title: "Associate Backend Developer (Node.js)",
        company: "NovaSync Dynamics Private Limited",
        location: "Islamabad, Pakistan",
        jobType: "Onsite",
        imageUrl: "https://res.cloudinary.com/df3ok5t8p/image/upload/v1726832683/novasync/atoukqpixl8hrjwtjmx3.jpg",
        technicalSkills: [
            {
                category: "Node.js Backend Development",
                skills: [
                    "Build, maintain, and optimize scalable backend services using Node.js.",
                    "Implement event-driven architecture and asynchronous programming practices to enhance application performance.",
                    "Collaborate with frontend teams to ensure seamless integration between backend services and user interfaces."
                ]
            },
            {
                category: "API Design and Security",
                skills: [
                    "Design and implement secure and efficient RESTful APIs with Node.js, integrating authentication mechanisms such as OAuth2, JWT, and session management.",
                    "Incorporate AES encryption to ensure data security across all APIs.",
                    "Develop comprehensive API documentation using tools like Swagger or Postman."
                ]
            },
            {
                category: "Database Management (PostgreSQL, MongoDB)",
                skills: [
                    "Design and manage relational (PostgreSQL) and non-relational (MongoDB) databases.",
                    "Ensure efficient data querying, indexing, and performance optimization tailored to the application’s needs.",
                    "Implement database security practices, including data encryption, secure access control, and monitoring."
                ]
            },
            {
                category: "LLM and AI Integration",
                skills: [
                    "Work with Large Language Models (LLMs) such as GPT-4 and integrate them into backend services for natural language processing and AI functionalities.",
                    "Leverage frameworks like Huggingface to deploy and fine-tune models.",
                    "Collaborate with data science teams to deploy custom or pre-trained models for AI-driven applications."
                ]
            },
            {
                category: "Vector Databases and Machine Learning",
                skills: [
                    "Work with vector databases like Pinecone to handle large-scale data for ML models and AI functionalities.",
                    "Optimize and manage machine learning pipelines using frameworks like TensorFlow, PyTorch, or LangChain."
                ]
            },
            {
                category: "Cloud and DevOps",
                skills: [
                    "Deploy and manage applications in cloud environments (AWS, Google Cloud, Azure) to ensure scalable, efficient operations.",
                    "Familiarity with containerization (Docker) and orchestration (Kubernetes) is a plus for large-scale deployments.",
                    "Participate in setting up CI/CD pipelines to automate testing and deployment."
                ]
            }
        ],
        softSkills: [
            "Strong problem-solving and analytical skills.",
            "Excellent communication and teamwork abilities.",
            "Adaptability and eagerness to learn new technologies.",
            "Ability to collaborate effectively with cross-functional teams."
        ],
        compensation: {
            description: "Competitive salary based on experience",
            additionalInfo: "Bonus calculation every 6 months. Salary increment yearly."
        },
        applicationProcess: "Send your resume and cover letter to hr@novasyncdynamics.com with the subject line 'Application for Associate Backend Developer – [Your Name]'.",

    }

];

const JobDetailPage = () => {
    const { jobTitle } = useParams();  // Capture job title from URL
    const [jobDetails, setJobDetails] = useState(null);

    useEffect(() => {
        // Find job based on the title in the URL
        const job = jobListings.find((j) => j.title === decodeURIComponent(jobTitle));
        setJobDetails(job);
    }, [jobTitle]);

    const handleShare = () => {
        const baseUrl = window.location.origin;
        if (navigator.share) {
            navigator.share({
                title: `Job Opportunity: ${jobDetails?.title}`,
                text: `Hey, check out this amazing job opportunity for a ${jobDetails?.title} at ${jobDetails?.company}.`,
                url: `${baseUrl}/job/${encodeURIComponent(jobDetails?.title)}`,
            }).then(() => console.log('Job shared successfully'))
                .catch((error) => console.error('Error sharing job:', error));
        } else {
            alert('Web Share API is not supported in your browser.');
        }
    };

    if (!jobDetails) return <div>Loading...</div>;

    return (
        <>
            <Navbar />
            <div className="max-w-6xl mx-auto px-5 py-10">
                <div className="flex justify-end items-end space-x-4">
                    <button
                        className="text-[#0EA288] py-2 hover:text-[#0EA288]/90 transition duration-300 ease-in-out"
                        onClick={handleShare}
                    >
                        <FiShare2 size={24} />
                    </button>
                    <div>
                        <a target={"_blank"} href={`mailto:hr@novasyncdynamics.com`}>
                            <button
                                className="bg-[#0EA288] text-white font-semibold py-2 px-6 rounded-lg hover:bg-[#0EA288]/90 transition duration-300 ease-in-out">
                                Apply Now
                            </button>
                        </a>
                    </div>
                </div>

                <div className="flex flex-col md:flex-row items-center mt-10">
                    <img src={jobDetails.imageUrl} alt={jobDetails.title} className="w-full md:w-3/6 rounded-lg" />
                    <div className="md:ml-10 mt-4 md:mt-0 flex-1">
                        <h1 className="text-3xl font-bold text-[#4F6370] mb-4">{jobDetails.title}</h1>
                        <p className="text-[#4F6370]"><strong>Company:</strong> {jobDetails.company}</p>
                        <p className="text-[#4F6370]"><strong>Location:</strong> {jobDetails.location}</p>
                        <p className="text-[#4F6370]"><strong>Job Type:</strong> {jobDetails.jobType}</p>
                    </div>
                </div>

                <h2 className="text-[#4F6370] text-2xl font-semibold mt-10 mb-6">Technical Skills</h2>
                {jobDetails.technicalSkills.map((skill, index) => (
                    <div key={index}>
                        <h3 className="text-xl font-semibold text-[#0EA288] mb-2">{skill.category}</h3>
                        <ul className="list-disc ml-6">
                            {skill.skills.map((s, idx) => (
                                <li key={idx} className="text-[#4F6370]">{s}</li>
                            ))}
                        </ul>
                    </div>
                ))}

                <h2 className=" text-[#4F6370] text-2xl font-semibold mt-10 mb-6">Soft Skills</h2>
                <ul className="list-disc ml-6">
                    {jobDetails.softSkills.map((skill, index) => (
                        <li key={index} className="text-[#4F6370]">{skill}</li>
                    ))}
                </ul>

                <h2 className=" text-[#4F6370] text-2xl font-semibold mt-10 mb-6">Compensation</h2>
                <p className="text-[#4F6370]">{jobDetails.compensation.description}</p>
                <p className="text-[#4F6370]">{jobDetails.compensation.additionalInfo}</p>

                <h2 className=" text-[#4F6370]  text-2xl font-semibold mt-10 mb-6">Application Process</h2>
                <p className="text-[#4F6370]">{jobDetails.applicationProcess}</p>
            </div>
            <Footer />
        </>
    );
};

export default JobDetailPage;
