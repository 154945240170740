import React, { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaTrash, FaPlus, FaBars } from 'react-icons/fa';
import CeoFahadImage from '../assets/images/CeoFahad.png';
import UserIcon from '../assets/images/userIcon.png';
import LogoutIcon from '../assets/images/LogoutIcon.png';
import Nav from '../components/Nav';

const AttendancePage = () => {
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().substring(0, 10));
  const [rows, setRows] = useState([{ col1: '', col2: 'P', col3: '', col4: '' }]);
  const [sidebarOpen, setSidebarOpen] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      // For example, automatically open the sidebar if the window width is greater than 768px
      if (window.innerWidth > 768) {
        setSidebarOpen(true);
      } else {
        setSidebarOpen(false);
      }
    };

    // Add event listener when component mounts
    window.addEventListener('resize', handleResize);

    // Call handleResize initially in case the initial window size should affect the sidebar
    handleResize();

    // Remove event listener when component unmounts
    return () => window.removeEventListener('resize', handleResize);
  }, []);
 


  return (
    <div className="flex flex-col md:flex-row min-h-screen">
      {/* Sidebar */}
      <aside className={`fixed z-30 md:z-10 top-0 left-0 md:static transition-transform transform ${sidebarOpen ? 'translate-x-0' : '-translate-x-full'} w-64 bg-[#0EA288] text-white flex flex-col items-center py-4 h-full md:h-auto`}>
        <img
          src={CeoFahadImage}
          alt="Fahad Shahzad"
          className="w-24 h-24 rounded-full mb-4"
        />
        <h2 className="text-xl font-bold mb-2">Fahad Shahzad</h2>
        <p className="mb-8">CEO</p>
        <nav className="flex flex-col space-y-4 w-full px-4">
          <Link to="/dashboard">
            <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">Home</button>
          </Link>
          
            <button className="w-full py-2 bg-white border-2 border-[#0C8B76] rounded hover:bg-white font-bold text-black">Attendance</button>
          <Link to="/leaverequest">
          <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] relative font-bold text-[#0EA288]">
            Leave Requests
            <span className="absolute -top-1 -right-1 bg-[#0C8B76] text-white rounded-full flex items-center justify-center border-2 border-white" style={{ width: '24px', height: '24px', lineHeight: '22px' }}>2</span>
          </button>
          </Link>

          <Link to="/salaries">
            <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">
              Salaries
            </button>
          </Link>
          <Link to="/user-management">
          <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288] ">Users Management</button>
          </Link>
          <Link to="/all-projects">
          <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">
            Project Management
          </button>
          </Link>
          <Link to="/customers">
          <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">Customers</button>
          </Link>
          <Link to="/reports">
          <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">
            Reports
          </button>
          </Link>
          <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">Promotion</button>
        </nav>
        <div className="flex-1 flex flex-col justify-end w-full px-4 mb-4">
          <img 
            src={LogoutIcon}
            alt="logout-icon"
            className="w-6 h-6 mb-2"
          />
        </div>
      </aside>

      {/* Main Content */}
      <main className={`flex-1 p-4 md:p-8 transition-all duration-300`}>
        <header className="mb-8">
          <div className="flex justify-end items-center mb-4">
            <Nav mainHeading="Attendance Management Portal" />
            <button
              className=" md:hidden fixed top-4 right-4 z-50 bg-white text-[#0EA288] rounded-full p-3 shadow-lg"
              onClick={() => setSidebarOpen(!sidebarOpen)}
            >
              <FaBars size={24} />
            </button>
            {/* <img
              src={UserIcon}
              alt="user-icon"
              className="w-16 h-16 rounded-full flex justify-end items-center"
            /> */}
          </div>
      
          <div className="flex justify-end mt-4">
            <span className="cursor-pointer underline">Reports</span>
          </div>

          <div className="flex justify-between items-center w-full">
            <h1 className="text-3xl font-bold text-[#4F6370]">Mark Attendance</h1>
            <section className="flex-grow p-4 max-w-7xl mx-auto my-6 text-right">
              <input 
                type="date" 
                id="date" 
                value={selectedDate} 
                onChange={(e) => setSelectedDate(e.target.value)}
                className="bg-[#D9D9D980]/50 rounded-lg pl-4 pr-4 py-2 w-full sm:w-[300px] h-[50px] border border-gray-300 text-gray-700"
                style={{ appearance: 'none' }}
              />
            </section>
          </div>


        </header>

       

        <section class="p-4 max-w-7xl mx-auto my-6">
          <div class="overflow-x-auto">
            <table class="min-w-full border-2 border-gray-400">
              <thead class="border-2 border-gray-400">
                <tr class="border-2 border-gray-400">
                  <th class="py-2 px-4 border-b border-r-2 border-gray-400 w-20">Employee</th>
                  <th class="py-2 px-4 border-b border-r-2 border-gray-400 w-20">Attendance</th>
                  <th class="py-2 px-4 border-b border-r-2 border-gray-400 w-20">Spent Time(m)</th>
                  <th class="py-2 px-4 border-b  border-gray-400 w-48">Comment</th>
                  </tr></thead>
                  <tbody>
                    <tr class="border-2 border-gray-400 text-center " data-row="0">
                      <td class="py-2 px-4 border-b border-r-2 border-gray-400 " contenteditable="true" data-column="col1"></td>
                      <td class="py-2 px-4 border-b border-r-2 border-gray-400 ">
                        <select class="bg-white text-gray-700 py-1 px-2 text-green-600">
                          <option value="P">P</option><option value="A">A</option>
                          <option value="L">L</option>
                          </select></td>
                          <td class="py-2 px-4 border-b-2 border-r-2 border-gray-400 " contenteditable="true" data-column="col3">
                            </td><td class="py-2 px-4 border-b  border-gray-400" contenteditable="true" data-column="col4"></td>
                            </tr></tbody></table>
                            <button class="mt-4 bg-[#0EA288] text-white py-2 px-4 rounded hover:bg-[#2e6158] float-right">Submit Attendance</button>
                            </div></section>
        
      </main>
    </div>
  );
};

export default AttendancePage;
