import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import '../../src/index.css';

const FeedbackModal = ({ isVisible, onClose, feedbackMessage, onSubmitFeedback }) => {
  const [message, setMessage] = useState(feedbackMessage);

  if (!isVisible) return null;

  const handleSubmit = () => {
    onSubmitFeedback(message);
  };

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg shadow-lg w-1/2 relative">
        <h2 className="text-xl font-bold text-center mb-4 inter-font">Feedback</h2>
        <div className="mb-4">
          <label className="block text-[#4F6370] text-normal font-semibold mb-2" htmlFor="feedbackMessage">Customize Feedback</label>
          <textarea 
            id="feedbackMessage" 
            className="p-2 border rounded w-full text-[#4F6370]  text-normal font-semibold border-[#0EA288] focus:outline-none bg-[#D9D9D980]/50" 
            rows="8"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
        <div className="flex justify-end">
          <button className="bg-[#0EA288] text-white py-2 px-8 rounded mr-4" onClick={handleSubmit}>Submit</button>
        </div>
        <button
          className="absolute top-4 right-4 text-gray-600 hover:text-gray-800"
          onClick={onClose}
        >
          <FaTimes size={24} />
        </button>
      </div>
    </div>
  );
};

export default FeedbackModal;
