import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';

const TypewriterEffect = () => {
    const phrases = [
        "MVP Development",
        "FullStack Development Service",
        "Intelligence Software Service Provider",
        // "Technology Consultation",
        // "Proposal Writing to Complete Deployment"
    ];

    const [currentPhrase, setCurrentPhrase] = useState("");
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentCharIndex, setCurrentCharIndex] = useState(0);

    useEffect(() => {
        const typingInterval = setInterval(() => {
            // Type one character at a time
            setCurrentPhrase((prev) =>
                prev + phrases[currentIndex].charAt(currentCharIndex)
            );
            setCurrentCharIndex((prev) => prev + 1);
        }, 100);

        if (currentCharIndex === phrases[currentIndex].length) {
            clearInterval(typingInterval);
            setTimeout(() => {
                // Pause before moving to the next phrase
                setCurrentCharIndex(0);
                setCurrentPhrase("");
                setCurrentIndex((prev) => (prev + 1) % phrases.length);
            }, 2000); // 2 second delay between phrases
        }

        return () => clearInterval(typingInterval);
    }, [currentCharIndex, currentIndex]);

    return (
        <h2 className="text-2xl lg:text-3xl xl:text-4xl text-center font-bold text-[#0EA288] mt-8">
            {currentPhrase}
        </h2>
    );
};

const MyHero = () => {
    // Initialize AOS when the component mounts
    useEffect(() => {
        AOS.init({
            duration: 800, // Animation duration in milliseconds
        });
    }, []);

    return (
        <div className="bg-[#1ca78f] bg-opacity-20 py-6 mt-6 lg:mt-4 mb-6">
            <div className="mx-auto px-4 lg:px-8">
                {/* Main Title */}
                <h1 className="text-4xl lg:text-6xl xl:text-7xl font-extrabold f leading-tight text-[#4F6370] text-center mb-8 lato-black-italic">
                    Choose Us for Innovation and <br />
                    <span className="">Excellence</span>
                </h1>

                {/* Typewriter Effect for rotating phrases */}
                <TypewriterEffect />

                {/* Description Text */}
                <p className="text-lg mt-4 lg:text-xl xl:text-lg sm:text-sm text-[#4F6370] text-center font-bold leading-normal mb-8">
                    Experience the expertise of our software developers from design to product deployment. Get your <span className="font-bold text-[#0EA288]">MVP , SaaS</span> <br />
                    Products ready within a short timeframe.
                </p>

                {/* Button Container */}
                <div className="flex flex-col lg:flex-row justify-center lg:justify-center space-y-4 lg:space-y-0 lg:space-x-4 mb-6">
                    <a target="_blank" href="https://calendly.com/fahadqureshi/meeting-request">
                        <button className="border-4 border-[#FFFFFF] bg-[#0EA288] text-white py-2 px-6 lg:px-8 rounded-full w-full lg:w-auto h-14 font-bold">
                            Schedule a call now
                        </button>
                    </a>

                    <Link to="/all-projects-page">
                        <button className="border-4 bg-[#FFFFFF] border-[#0EA288] text-[#4F6370] py-2 px-6 lg:px-8 rounded-full hover:bg-[#0EA288] hover:text-white w-full lg:w-auto h-14 mt-2 lg:mt-0 font-bold">
                            See Projects Portfolio
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default MyHero;
