import React, {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import Footer from "./Footer";
import Navbar from "./Navbar";
import projects from "../projects.json";
import RelatedProject from "./related";

const WebDevelopmentPage = () => {

    const keyword = "Web"; // Example: Filter projects with 'AI' in the title

    return (
        <div>
            <Navbar/>
            {/* Hero Section */}
            <section className="relative w-full h-96 bg-cover bg-center" style={{ backgroundImage: "url('https://example.com/your-banner-image.jpg')" }}>
                <div className="absolute inset-0 bg-black opacity-50"></div>
                <div className="absolute inset-0 flex flex-col justify-center items-center text-white text-center px-6">
                    <h1 className=" text-4xl md:text-6xl font-semibold leading-tight mb-4">Empowering Your Digital Future with Cutting-Edge Web Development</h1>
                    <p className="text-lg md:text-2xl mb-6">Transforming your ideas into innovative, high-performance web applications that scale with your business.</p>
                    {/*<Link to="/contact" className="bg-[#0EA288] text-white font-semibold py-2 px-6 rounded-lg hover:bg-[#0EA288]/90 transition duration-300">*/}
                    {/*    Get in Touch*/}
                    {/*</Link>*/}
                </div>
            </section>

            {/* Introduction Section */}
            <section className="container mx-auto px-6 py-12">
                <h2 className="text-3xl font-semibold text-[#0EA288] mb-6">Why Web Development Matters</h2>
                <p className="text-justify text-lg text-[#4F6370] mb-6">
                    A website is often the first point of contact between your business and potential customers. It's not just a digital brochure — it’s the core of your online presence.
                    At NovaSync, we understand the importance of a robust, scalable website that delivers on performance, security, and user experience. Whether you need an <strong>e-commerce platform </strong>, a <strong>corporate site</strong>, or a <strong> custom web application </strong>, we are here to bring your vision to life.
                </p>

                {/* Key Features Section */}
                <h3 className="text-2xl font-semibold text-[#0EA288] mb-6">Our Web Development Solutions</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">Custom Web Applications</h4>
                        <p className="text-justify text-[#4F6370]">
                            We specialize in developing tailor-made applications that meet your business needs. Whether it’s a customer portal, an internal tool, or an online platform, we create solutions that enhance your operations.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">Responsive Design</h4>
                        <p className="text-justify text-[#4F6370]">
                            Every website we build is optimized to work seamlessly on any device — desktop, tablet, or mobile. With a mobile-first approach, we ensure your users have the best experience, no matter how they access your site.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">E-commerce Development</h4>
                        <p className="text-justify text-[#4F6370]">
                            We design and develop secure, scalable, and user-friendly e-commerce platforms that help you connect with your customers and grow your business. From payment gateway integration to inventory management, we provide a complete solution.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">CMS Development</h4>
                        <p className="text-justify text-[#4F6370]">
                            NovaSync builds content management systems that allow you to easily update and manage your website content. Whether it’s a blog, product pages, or service offerings, our CMS solutions are intuitive and flexible.
                        </p>
                    </div>
                </div>
            </section>

            {/* Technologies Section */}
            <section className="bg-[#f9f9f9] py-12">
                <div className="container mx-auto px-6">
                    <h3 className="text-2xl font-semibold text-[#0EA288] mb-6">Technologies We Use</h3>
                    <p className="text-lg text-[#4F6370] mb-6">
                        We leverage the latest technologies to deliver high-quality web development services:
                    </p>
                    <ul className="list-disc pl-6 text-[#4F6370]">
                        <li>Frontend: React.js, Vue.js, Tailwind CSS, HTML5, CSS3, JavaScript</li>
                        <li>Backend: Node.js, Express.js, MongoDB, and RESTful APIs</li>
                        <li>Tools: Git, Docker, AWS, CI/CD pipelines for seamless deployments</li>
                    </ul>
                </div>
            </section>

            {/* Web Development Process Section */}
            <section className="container mx-auto px-6 py-12">
                <h3 className="text-2xl font-semibold text-[#0EA288] mb-6">Our Web Development Process</h3>
                <div className="space-y-6">
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">1. Discovery & Planning</h4>
                        <p className="text-justify text-[#4F6370]">
                            We take the time to understand your business needs, goals, and challenges.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">2. Design & Prototyping</h4>
                        <p className="text-justify text-[#4F6370]">
                            Our design team creates wireframes and prototypes to give you a preview of your site before development begins.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">3. Development</h4>
                        <p className="text-justify text-[#4F6370]">
                            Our developers bring your ideas to life by writing clean, maintainable code that is optimized for performance and security.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">4. Testing & QA</h4>
                        <p className="text-justify text-[#4F6370]">
                            Every site undergoes thorough testing to ensure it works seamlessly across all devices and browsers.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">5. Deployment & Maintenance</h4>
                        <p className="text-justify text-[#4F6370]">
                            We handle the deployment process, ensuring a smooth launch and provide ongoing support and maintenance to ensure your website continues to perform optimally.
                        </p>
                    </div>
                </div>
            </section>
            <RelatedProject  keyword={keyword} />
            <Footer/>
        </div>
    );
};

export default WebDevelopmentPage;
