import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import CeoFahadImage from '../assets/images/CeoFahad.png';
import LogoutIcon from '../assets/images/LogoutIcon.png';
import SitIcon from '../assets/images/SitIcon.png';
import Nav from '../components/Nav';

const ReportCard = ({ title }) => (
    <div className="flex flex-col items-center justify-center border-2 border-[#0EA288] rounded-lg p-6 bg-white m-8">
        <div><img src={SitIcon} style={{ height: '40px', width: '40px' }}></img></div>
        <p className="text-center text-lg font-bold mt-6">{title}</p>
    </div>
);

const Reports = () => {
    const [sidebarOpen, setSidebarOpen] = useState(true);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 768) {
                setSidebarOpen(true);
            } else {
                setSidebarOpen(false);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className="flex flex-col md:flex-row min-h-screen bg-white">
            {/* Sidebar */}
            <aside className={`fixed z-30 md:z-10 top-0 left-0 md:static transition-transform transform ${sidebarOpen ? 'translate-x-0' : '-translate-x-full'} w-64 bg-[#0EA288] text-white flex flex-col items-center py-4 h-full md:h-auto`}>
                <img
                    src={CeoFahadImage}
                    alt="Fahad Shahzad"
                    className="w-24 h-24 rounded-full mb-4"
                />
                <h2 className="text-xl font-bold mb-2">Fahad Shahzad</h2>
                <p className="mb-8">CEO</p>
                <nav className="flex flex-col space-y-4 w-full px-4">
                    <Link to="/dashboard">
                        <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">Home</button>
                    </Link>
                    <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">Attendance</button>
                    <Link to="/leaverequest">
                        <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] relative font-bold text-[#0EA288]">
                            Leave Requests
                            <span className="absolute -top-1 -right-1 bg-[#0C8B76] text-white rounded-full flex items-center justify-center border-2 border-white" style={{ width: '24px', height: '24px', lineHeight: '22px' }}>2</span>
                        </button>
                    </Link>
                    <Link to="/salaries">
                        <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">Salaries</button>
                    </Link>
                    <Link to="/user-management">
                        <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">Users Management</button>
                    </Link>
                    <Link to="/all-projects">
                        <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">Project Management</button>
                    </Link>
                    <Link to="/all-customer">
                        <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">Customers</button>
                    </Link>
                    <button className="w-full py-2 bg-white border-2 border-[#0C8B76] rounded hover:bg-white font-bold text-black">Reports</button>
                    <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">Promotion</button>
                </nav>
                <div className="flex-1 flex flex-col justify-end w-full px-4 mb-4">
                    <img
                        src={LogoutIcon}
                        alt="logout-icon"
                        className="w-6 h-6 mb-2"
                    />
                </div>
            </aside>

            {/* Main Content */}
            <main className={`flex-1 p-4 md:p-8 transition-all duration-300`}>
                <header className="mb-8">
                    <div className="flex justify-between items-center mb-4">
                        <Nav mainHeading="Report Management Portal" />
                        <button
                            className="md:hidden fixed top-4 right-4 z-50 bg-white text-[#0EA288] rounded-full p-3 shadow-lg"
                            onClick={() => setSidebarOpen(!sidebarOpen)}
                        >
                            <FaBars size={24} />
                        </button>
                    </div>
                </header>

                <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-8  font-bold text-black ">
                    <div className="bg-[#D9D9D9] bg-opacity-50  p-4 rounded-lg shadow-md text-center border-2 border-[#0EA288]">
                        <p className="text-xl font-bold">5</p>
                        <p >Active Projects</p>
                    </div>
                    <div className="bg-[#D9D9D9] bg-opacity-50 p-4 rounded-lg shadow-md text-center border-2 border-[#0EA288]">
                        <p className="text-xl">20</p>
                        <p >Employment Resource</p>
                    </div>
                    <div className="bg-[#D9D9D9] bg-opacity-50 p-4 rounded-lg shadow-md text-center border-2 border-[#0EA288]">
                        <p className="text-xl font-bold">2</p>
                        <p >Projects on hold</p>
                    </div>
                    <div className="bg-[#D9D9D9] bg-opacity-50 p-4 rounded-lg shadow-md text-center border-2 border-[#0EA288]">
                        <p className="text-xl font-bold">10</p>
                        <p>Completed Projects</p>
                    </div>
                </div>
                <div class="w-3/4 h-3 mt-10 mb-5 mx-auto bg-[#0EA288] rounded-full"></div>

                <section className="mb-8 ">
                    <h2 className="text-2xl font-semibold mb-4">Workforce Reports</h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 bg-[#0EA288] bg-opacity-25">
                        <ReportCard title="Attendance and Time Reports" />
                        <ReportCard title="Project Reports" />
                        <ReportCard title="Performance Report" />
                        <ReportCard title="Employee Satisfaction and Feedback Reports" />
                    </div>
                </section>
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">Financial Reports</h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 bg-[#0EA288] bg-opacity-25">
                        <ReportCard title="Income Statement (Profit and Loss Report)" />
                        <ReportCard title="Balance Sheet" />
                        <ReportCard title="Cash Flow Statement" />
                        <ReportCard title="Accounts Receivable and Payable Aging Reports" />
                        <ReportCard title="Expense Reports" />
                        <ReportCard title="Financial Forecast and Projections" />
                        <ReportCard title="Audit Reports" />
                    </div>
                </section>
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">Customer Reports</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 bg-[#0EA288] bg-opacity-25">
                        <ReportCard title="Customer Acquisition Report" />
                        <ReportCard title="Customer Satisfaction and Feedback Report" />
                    </div>
                </section>
            </main>
        </div>
    );
};

export default Reports;
