import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const AddCustomer = ({ isVisible, onClose, addCustomer }) => {
  const [toggleActive, setToggleActive] = useState(false);
  const [customerData, setCustomerData] = useState({
    cusName: '',
    cusEmail: '',
    cusNo: '',
    cusCountry: '',
    status: false,
  });

  const [image, setImage] = useState(null); 
  const [imageError, setImageError] = useState(''); 

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (image && !['image/jpeg', 'image/png'].includes(image.type)) {
      setImageError('Please upload a valid image (JPEG/PNG)');
      return;
    }
    if (image && image.size > 1024 * 1024) {
      setImageError('Image size should not exceed 1MB');
      return;
    }
    addCustomer({ ...customerData, status: toggleActive ? 'Active' : 'Inactive' , image: image  });
    navigate('/all-customer'); // Navigate to AllCustomer page
    
  };

  const handleChange = (e) => {
    setCustomerData({ ...customerData, [e.target.name]: e.target.value });
  };

  const handleToggle = () => {
    setToggleActive(!toggleActive);
  };

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    if (selectedImage) {
      setImage(selectedImage);
      setImageError(''); // Clear any previous errors
    }
  };

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-4 md:p-6 rounded-lg shadow-lg w-full md:w-1/2 relative">
        <h2 className="text-lg md:text-xl font-bold underline mb-4">Add Customer</h2>
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div>
              <label className="block text-sm font-semibold mb-2" htmlFor="customerName">Customer Name</label>
              <input 
                id="customerName" 
                type="text" 
                name='cusName'
                value={customerData.cusName}
                onChange={handleChange}
                className="p-2 border rounded w-full border-[#0EA288] focus:outline-none bg-[#D9D9D980]/50" 
              />
            </div>
            <div>
              <label className="block text-sm font-semibold mb-2" htmlFor="customerEmail">Customer Email</label>
              <input 
                id="customerEmail" 
                type="email" 
                name='cusEmail'
                value={customerData.cusEmail}
                onChange={handleChange}
                className="p-2 border rounded w-full border-[#0EA288] focus:outline-none bg-[#D9D9D980]/50" 
              />
            </div>
            <div>
              <label className="block text-sm font-semibold mb-2" htmlFor="customerNo">Customer Contact No</label>
              <input 
                id="customerNo" 
                type="number" 
                name='cusNo'
                value={customerData.cusNo}
                onChange={handleChange}
                className="p-2 border rounded w-full border-[#0EA288] focus:outline-none bg-[#D9D9D980]/50" 
              />
            </div>
            <div>
              <label className="block text-sm font-semibold mb-2" htmlFor="country">Country</label>
              <input 
                id="country" 
                type="text" 
                name='cusCountry'
                value={customerData.cusCountry}
                onChange={handleChange}
                className="p-2 border rounded w-full border-[#0EA288] focus:outline-none bg-[#D9D9D980]/50" 
              />
            </div>
            {/* upload img */}
            <div className="col-span-1 md:col-span-2">
              <label className="block text-sm font-semibold mb-2">Customer Image</label>
              <input
                type="file"
                accept=".jpg, .png"
                onChange={handleImageChange}
                className="p-2 border rounded w-full border-[#0EA288] focus:outline-none bg-[#D9D9D980]/50"
              />
              {imageError && <p className="text-red-500 text-sm mt-1">{imageError}</p>}
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:items-center mb-4">
            <label className="block text-sm font-semibold mb-2 md:mb-0 md:mr-2">Active Status</label>
            <div
              className={`w-10 h-5 flex items-center rounded-full p-1 cursor-pointer ${toggleActive ? 'bg-[#0EA288]' : 'bg-gray-300'}`}
              onClick={handleToggle}
            >
              <div className={`bg-white w-4 h-4 rounded-full shadow-md transform ${toggleActive ? 'translate-x-5' : ''}`}></div>
            </div>
          </div>
          <div className="flex justify-end">
            <button type="submit" className="bg-[#0EA288] text-white py-2 px-4 rounded mr-4">Save</button>
          </div>
        </form>
        <button
          className="absolute top-4 right-4 text-gray-600 hover:text-gray-800"
          onClick={onClose}
        >
          <FaTimes size={24} />
        </button>
      </div>
    </div>
  );
};

export default AddCustomer;
