export const navItems = [
  { label: "About Us", href: "#about-us" },
  {
    label: "Services",
    href: "#services",
    submenu: [
      { label: "Web Development", href: "/web-development" },
      { label: "Mobile Application", href: "/mob-application-development" },
      { label: "Generative AI", href: "/generative-ai-development" },
      { label: "Custom Software Development", href: "/custom-development-application" },
      { label: "QA Testing and Automations", href: "/QA-testing" },
      { label: "UI/UX Designing", href: "/UI-UX-Desiging" },
      { label: "Deployment", href: "/deployment" },
      { label: "Software Architect and Design", href: "/software-architecture-design" },
    ],
  },
  { label: "Portfolio", href: "/all-projects-page" },
  { label: "Blogs", href: "#" },
  { label: "Careers", href: "/careers" },
  { label: "Reviews", href: "#reviews" },
  { label: "Contact us", href: "#contact-us" },
];
