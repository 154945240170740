import React from 'react';
import { Link } from 'react-router-dom';
import Footer from "./Footer";
import Navbar from "./Navbar";
import RelatedProject from "./related";

const MobileApplicationPage = () => {
    const keyword = "AI"; // Example: Filter projects with 'AI' in the title

    return (
        <div>
            <Navbar/>

            {/* Hero Section */}
            <section className="relative w-full h-96 bg-cover bg-center" style={{ backgroundImage: "url('https://example.com/your-banner-image.jpg')" }}>
                <div className="absolute inset-0 bg-black opacity-50"></div>
                <div className="absolute inset-0 flex flex-col justify-center items-center text-white text-center px-6">
                    <h1 className="text-4xl md:text-6xl font-semibold leading-tight mb-4">Building Powerful Mobile Experiences with Cutting-Edge Development</h1>
                    <p className="text-lg md:text-2xl mb-6">Creating innovative, user-centric mobile applications that deliver seamless experiences on iOS and Android devices.</p>
                    {/*<Link to="/contact" className="bg-[#0EA288] text-white font-semibold py-2 px-6 rounded-lg hover:bg-[#0EA288]/90 transition duration-300">*/}
                    {/*    Get in Touch*/}
                    {/*</Link>*/}
                </div>
            </section>

            {/* Introduction Section */}
            <section className="container mx-auto px-6 py-12">
                <h2 className=" text-3xl font-semibold text-[#0EA288] mb-6">Why Mobile Applications Matter</h2>
                <p className="text-justify text-lg text-[#4F6370] mb-6">
                    In today’s mobile-first world, mobile applications are essential for businesses looking to engage with their audience on-the-go. At NovaSync, we specialize in crafting intuitive and high-performance mobile applications that cater to both iOS and Android platforms. Whether you're building an <strong>e-commerce app</strong>, a <strong>social media platform</strong>, or a <strong>service-oriented mobile app</strong>, we have the expertise to turn your vision into reality.
                </p>

                {/* Key Features Section */}
                <h3 className="text-2xl font-semibold text-[#0EA288] mb-6">Our Mobile App Development Solutions</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">Custom Mobile Applications</h4>
                        <p className="text-justify text-[#4F6370]">
                            We create mobile apps that are tailored to meet your specific business needs. From custom user interfaces to complex backend systems, we build solutions that provide real value to your users.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">Cross-Platform Development</h4>
                        <p className="text-justify text-[#4F6370]">
                            Using frameworks like React Native and Flutter, we develop mobile apps that work seamlessly across both iOS and Android devices, ensuring broad reach and performance without compromise.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">App UI/UX Design</h4>
                        <p className="text-justify text-[#4F6370]">
                            Our design team creates intuitive, user-friendly interfaces that provide seamless navigation and a delightful user experience, ensuring users stay engaged with your mobile app.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">App Integration & Maintenance</h4>
                        <p className="text-justify text-[#4F6370]">
                            We integrate your app with third-party APIs, payment gateways, and databases, ensuring smooth operation. Additionally, we provide ongoing support and maintenance to ensure your app stays up-to-date with the latest features and security updates.
                        </p>
                    </div>
                </div>
            </section>

            {/* Technologies Section */}
            <section className="bg-[#f9f9f9] py-12">
                <div className="container mx-auto px-6">
                    <h3 className="text-2xl font-semibold text-[#0EA288] mb-6">Technologies We Use</h3>
                    <p className="text-lg text-[#4F6370] mb-6">
                        We utilize the best technologies and frameworks for mobile app development:
                    </p>
                    <ul className="list-disc pl-6 text-[#4F6370]">
                        <li>Frontend: React Native, Flutter, Swift (iOS), Kotlin (Android)</li>
                        <li>Backend: Node.js, Express.js, Firebase, REST APIs</li>
                        <li>Tools: Git, Docker, AWS, CI/CD pipelines, Firebase Cloud Messaging</li>
                    </ul>
                </div>
            </section>

            {/* Mobile App Development Process Section */}
            <section className="container mx-auto px-6 py-12">
                <h3 className="text-2xl font-semibold text-[#0EA288] mb-6">Our Mobile App Development Process</h3>
                <div className="space-y-6">
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">1. Discovery & Planning</h4>
                        <p className="text-justify text-[#4F6370]">
                            We start by understanding your business goals, target audience, and technical requirements, ensuring that we create an app that fulfills your needs.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">2. Design & Prototyping</h4>
                        <p className="text-justify text-[#4F6370]">
                            Our design team develops wireframes and prototypes to visualize the app’s structure, ensuring a user-friendly experience from the get-go.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">3. Development</h4>
                        <p className="text-justify text-[#4F6370]">
                            Our developers bring the design to life with clean, optimized code for both iOS and Android platforms.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">4. Testing & QA</h4>
                        <p className="text-justify text-[#4F6370]">
                            We rigorously test the app across devices to ensure smooth performance and flawless user experience.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">5. Launch & Support</h4>
                        <p className="text-justify text-[#4F6370]">
                            After launching the app, we offer continuous support to address any bugs, ensure updates, and provide ongoing improvements.
                        </p>
                    </div>
                </div>
            </section>
            <RelatedProject  keyword={keyword}/>

            <Footer/>
        </div>
    );
};

export default MobileApplicationPage;
