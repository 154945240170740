import React from 'react';
import { Link } from 'react-router-dom';
import Footer from "./Footer";
import Navbar from "./Navbar";
import RelatedProject from "./related";

const GenerativeAIPage = () => {
    const keyword = "AI"; // Example: Filter projects with 'AI' in the title

    return (
        <div>
            <Navbar/>

            {/* Hero Section */}
            <section className="relative w-full h-96 bg-cover bg-center" style={{ backgroundImage: "url('https://example.com/your-banner-image.jpg')" }}>
                <div className="absolute inset-0 bg-black opacity-50"></div>
                <div className="absolute inset-0 flex flex-col justify-center items-center text-white text-center px-6">
                    <h1 className="text-4xl md:text-6xl font-semibold leading-tight mb-4">Unleashing the Power of Generative AI to Transform Your Business</h1>
                    <p className="text-lg md:text-2xl mb-6">Harnessing the latest advancements in AI to create innovative solutions that drive business growth and efficiency.</p>
                    {/*<Link to="/contact" className="bg-[#0EA288] text-white font-semibold py-2 px-6 rounded-lg hover:bg-[#0EA288]/90 transition duration-300">*/}
                    {/*    Get in Touch*/}
                    {/*</Link>*/}
                </div>
            </section>

            {/* Introduction Section */}
            <section className="container mx-auto px-6 py-12">
                <h2 className="text-3xl font-semibold text-[#0EA288] mb-6">What is Generative AI?</h2>
                <p className="text-lg text-justify text-[#4F6370] mb-6">
                    Generative AI is a cutting-edge technology that leverages machine learning models to generate new content, from text and images to videos and code. At NovaSync, we harness the power of Generative AI to solve complex problems, optimize workflows, and create novel, data-driven solutions that help your business stay ahead of the curve.
                </p>

                {/* Key Features Section */}
                <h3 className="text-2xl font-semibold text-[#0EA288] mb-6">Our Generative AI Solutions</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    <div>
                        <h4 className="text-xl text-justify font-semibold text-[#4F6370]">AI-Generated Content</h4>
                        <p className="text-[#4F6370]">
                            We build AI-powered tools that generate high-quality written content, images, and more. From marketing copy to personalized content creation, we help businesses scale content production.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">Automated Data Generation</h4>
                        <p className="text-justify text-[#4F6370]">
                            Leveraging AI algorithms, we automate the creation of synthetic datasets that can be used for training machine learning models, data analysis, and enhancing business intelligence.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">AI-Driven Design</h4>
                        <p className="text-justify text-[#4F6370]">
                            Our generative AI solutions can assist in creating designs and layouts automatically, improving creativity and efficiency for marketing materials, product prototypes, and user interfaces.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">Personalized Experiences</h4>
                        <p className="text-justify text-[#4F6370]">
                            We use Generative AI to deliver personalized user experiences, creating tailored content, product recommendations, and services that adapt to individual user behaviors.
                        </p>
                    </div>
                </div>
            </section>

            {/* Technologies Section */}
            <section className="bg-[#f9f9f9] py-12">
                <div className="container mx-auto px-6">
                    <h3 className="text-2xl font-semibold text-[#0EA288] mb-6">Technologies We Use</h3>
                    <p className="text-justify text-lg text-[#4F6370] mb-6">
                        We use the latest advancements in artificial intelligence to provide top-tier generative solutions:
                    </p>
                    <ul className="list-disc pl-6 text-[#4F6370]">
                        <li>Natural Language Processing (NLP)</li>
                        <li>Generative Adversarial Networks (GANs)</li>
                        <li>Deep Learning Models (Transformers, GPT-3, BERT)</li>
                        <li>AI Image Generation (DALL·E, MidJourney)</li>
                        <li>AI-Driven Design Tools (DeepDream, RunwayML)</li>
                    </ul>
                </div>
            </section>

            {/* Generative AI Development Process Section */}
            <section className="container mx-auto px-6 py-12">
                <h3 className="text-2xl font-semibold text-[#0EA288] mb-6">Our Generative AI Development Process</h3>
                <div className="space-y-6">
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">1. Discovery & Ideation</h4>
                        <p className="text-justify text-[#4F6370]">
                            We begin by understanding your business goals, challenges, and the specific use cases where generative AI can make an impact.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">2. Model Selection & Customization</h4>
                        <p className="text-justify text-[#4F6370]">
                            After identifying the problem, we select and customize the most appropriate AI models for your use case, ensuring the best results.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">3. Training & Optimization</h4>
                        <p className="text-justify text-[#4F6370]">
                            We train the models on high-quality data to ensure they perform at their best, optimizing for accuracy, speed, and scalability.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">4. Integration & Testing</h4>
                        <p className="text-justify text-[#4F6370]">
                            Our team integrates the AI solution into your existing systems, followed by rigorous testing to ensure smooth functionality and seamless user experience.
                        </p>
                    </div>
                    <div>
                        <h4 className="text-xl font-semibold text-[#4F6370]">5. Deployment & Monitoring</h4>
                        <p className="text-justify text-[#4F6370]">
                            After deployment, we monitor the AI solution's performance and provide continuous optimization, ensuring that it adapts to changing data and business needs.
                        </p>
                    </div>
                </div>
            </section>
            <RelatedProject  keyword={keyword}/>

            <Footer />
        </div>
    );
};

export default GenerativeAIPage;
