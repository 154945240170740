export const API_BASE_URL = 'http://localhost:4000/api'
export const APIs = {
    LOGIN: `${API_BASE_URL}/auth/login`,
    VERIFY_TOKEN: `${API_BASE_URL}/auth/verify-otp`,
    RESET_PASSWORD: `${API_BASE_URL}/users/sendResetpasswordOtp`,
    RESET_PASSWORD_CONFIRM: `${API_BASE_URL}/users/rsetPassword`,
    ADMIN: {
        USERS: {
            ADD_USER: `${API_BASE_URL}/users`,
            GET_ALL_USERS: `${API_BASE_URL}/users`,
            DELETE_USER: `${API_BASE_URL}/users/`,
            GET_USER_BY_USER_ID: `${API_BASE_URL}/users/`,
        },
        SALARY: {
            GENERATE_INVOICE_NUMBER: `${API_BASE_URL}/salaries/generate-invoice`,
            SAVE_SLIP: `${API_BASE_URL}/salaries/save-slip`,
            GET_ALL_SALARY_SLIPS: `${API_BASE_URL}/salaries/get-all-slips`,
            GET_SALARY_SLIP_BY_EMPLOYEE_ID: `${API_BASE_URL}/salaries/get-slips-by-employee-id`,
            UPDATE_SALARY: `${API_BASE_URL}/salaries-update-slip/`,
            DELETE_SALARY_SLIP: `${API_BASE_URL}/salaries/delete-slip/`,
            GENERATE_SALARY_SLIP: `${API_BASE_URL}/salaries/ganerateSalarySlip`,
            GET_ALL_SALARIES: `${API_BASE_URL}/salaries/getAllSalaries`,
        },
        ATTENDANCE: {
            MARK_ATTENDANCE: `${API_BASE_URL}/attendance/markAttendance`,
            UPDATE_ATTENDANCE: `${API_BASE_URL}/attendance/markAttendance`,
            UPDATE_ATTENDANCE_BY_SUPPER_ADMIN: `${API_BASE_URL}/attendance/updateAttendanceByAdmin`,
            GET_ATTENDANCE_BY_DATE: `${API_BASE_URL}/attendance/getAttendanceByDate`,
        },
        LEAVE_REQUESTS: {
            GET_ALL_LEAVE_REQUESTS: `${API_BASE_URL}/attendance/getAllLeaves`,
            UPDATE_LEAVE_REQUEST: `${API_BASE_URL}/attendance/updateLeaveRequest`,
        },
        PROJECT_MANAGEMENT: {
            ADD_PROJECT: `${API_BASE_URL}/api/projects/createProject`,
            GET_PROJECT_BY_ID: `${API_BASE_URL}/projects/getProjectByProjectId/`,
            UPDATE_PROJECT: `${API_BASE_URL}projects/updateproject/`,
            DELETE_PROJECT: `${API_BASE_URL}/projects/deleteproject/`,
            GET_PROJECT_BY_CUSTOMER_ID: `${API_BASE_URL}/projects/getProjectByCustomerId`,
        },
        SPRINTS: {
            ADD_SPRINT: `${API_BASE_URL}/sprints/createSprints`,
            GET_SPRINT_BY_EMPLOYEE_ID: `${API_BASE_URL}/sprints/getSprintByEmployeeId/`,
            DELETE_SPRINT: `${API_BASE_URL}/sprints/deletesprint`,
            UPDATE_SPRINT: `${API_BASE_URL}/sprints/updatesprint/`,
            NOTES: {
                ADD_NOTES: `${API_BASE_URL}/sprints/addNotes`,
                DELETE_NOTES: `${API_BASE_URL}/sprints/deleteNoteByNoteId/`,
                GET_ALL_NOTES_BY_SPRINT_ID: `${API_BASE_URL}/sprints/getAllNotesBySprintId/`,
            },
            GET_TODAYS_TASKS_LIST: `${API_BASE_URL}/sprints/todaytasklist`,
            GET_ALL_SPRINTS_BY_PROJECT_IDS: `${API_BASE_URL}/sprints/getAllSprintsByProjectIds`,
            GET_SPRINT_BY_SPRINT_NUMBER: `${API_BASE_URL}/sprints/getgetSprintByTicketNumber/`,
            GET_SPRINTS_BY_PROJECTS_ID: `${API_BASE_URL}/sprints/getSprintByprojectId/`,
        },
        REPORTS: {
            SUBMIT_DAILY_REPORT: `${API_BASE_URL}/reports/sumbitReport`,
            GET_ALL_REPORTS: `${API_BASE_URL}/reports/getAllReports`,
            GET_EMPLOYEE_REPORTS: `${API_BASE_URL}/reports/getAllReportsByEmployeeId/`,
        },
        STATS: {
            GET_DASHBOARD_STATISTICS: `${API_BASE_URL}/statistics/getStatistics`,
            GET_ATTENDANCE_STATISTICS: `${API_BASE_URL}/statistics/getAttendanceStatistics`,
        },
        CUSTOMERS: {
            GET_ALL_CUSTOMERS: `${API_BASE_URL}/customers/getAllCustomers`,
            GET_PROJECT_BY_CUSTOMER_ID: `${API_BASE_URL}/projects/getProjectByCustomerId`,
            ADD_CUSTOMER: `${API_BASE_URL}/customers/addCustomer`,
            INVITE_CUSTOMER: `${API_BASE_URL}/customers/invite`,
            FILTER_CUSTOMERS: `${API_BASE_URL}/customers/filterredQueries`,
        },
        PROMOTIONS: {
            ADD_PROMOTIONAL_USER: `${API_BASE_URL}`,
            GET_ALL_PROMOTIONAL_USERS: `${API_BASE_URL}`,
            UPDATE_PROMOTIONAL_USER: `${API_BASE_URL}`,
            DELETE_PROMOTIONAL_USERS: `${API_BASE_URL}`,
        },
    },
    CONTACT_FORM: {
        CUSTOMER_QUERY: `${API_BASE_URL}/proposals/requestProposal`,
        REPLY_CUSTOMER_QUERY: `${API_BASE_URL}/proposals/adminReply/`,
    },
    EMPLOYEES: {
        LEAVE_REQUESTS: {
            GET_ALL_REPORTS: `${API_BASE_URL}`,
            GET_EMPLOYEE_REPORTS: `${API_BASE_URL}`,
        },
        SPRINTS: {
            GET_SPRINT_BY_EMPLOYEE_ID: `${API_BASE_URL}/sprints/getSprintByEmployeeId/`,
        },
        REPORTS: {
            SUBMIT_DAILY_REPORT: `${API_BASE_URL}`,
        }
    },
    CUSTOMER: {
        REQUEST_PROPOSAL: `${API_BASE_URL}`,
        ADMIN_REPLY: `${API_BASE_URL}`,
    },
    WCM: {
        REVIEWS: {
            ADD_REVIEW: `${API_BASE_URL}`,
            GET_ALL_REVIEWS: `${API_BASE_URL}`,
        },
        PORTFOLIO: {
            ADD_PROJECT: `${API_BASE_URL}`,
            GET_ALL_PROJECTS: `${API_BASE_URL}`,
            DELETE_PROJECT: `${API_BASE_URL}`,
            UPDATE_PROJECT: `${API_BASE_URL}`,
        },
        BLOGS: {
            CREATE_BLOG: `${API_BASE_URL}`,
            GET_ALL_BLOGS: `${API_BASE_URL}`,
            UPDATE_BLOG: `${API_BASE_URL}`,
            DELETE_BLOG: `${API_BASE_URL}`,
        }
    }
}
