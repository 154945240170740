import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import projects from '../projects.json';
import Navbar from './Navbar';
import RelatedProject from "../components/related";
import Contact from '../components/contact';
import Footer from './Footer';


const ProjectDetailPageOne = () => {


    const { projectName } = useParams();
    const [selectedProject, setSelectedProject] = useState(null);

    const keyword = "AI";

    useEffect(() => {
        if (!projectName) return;

        const project = projects.find(proj => proj.title === projectName);
        setSelectedProject(project);
    }, [projectName]);

    if (!selectedProject) {
        return (
            <div className="flex items-center justify-center h-screen">
                <div className="text-center">
                    <h3 className=" text-2xl">Loading or project not found...</h3>
                </div>
            </div>
        );
    }

    return (
        <div className="bg-white">
            <Navbar/>
            <div className="container  mx-auto  py-8 mt-8">
                <div className="flex res-m-3 flex-col lg:flex-row ml-0 lg:ml-10 w-full justify-between">
                    <div className=" res-m-10 flex-1 mb-8 lg:mb-0">
                        <h1 className="lato-regular  text-[#4F6370] text-2xl lg:text-4xl font-bold">{selectedProject.title}</h1>
                        <p className="lato-regular text-lg text-[#4F6370]  lg:text-xl mb-4">{selectedProject.subTitle}</p>
                        <p className="lato-regular text-justify text-[#4F6370]  mb-8">{selectedProject.description}</p>
                    </div>
                    {selectedProject.title !== "Try Dice" && (
                        <div className="res-m-10 flex-1 mb-8 lg:ml-16 lg:pl-16 ">
                            <div
                                className="max-w-full lg:max-w-xs cursor-pointer rounded-lg bg-white p-2 shadow duration-150 hover:scale-105 hover:shadow-md">
                                <ul className="list-disc list-inside m-4 lg:m-10">
                                    <li className="lato-regular text-[#4F6370] flex items-center">
                                        <img
                                            src="https://res.cloudinary.com/df3ok5t8p/image/upload/v1724805222/novasync/zgztnvrnhs03lte9oiok.png"
                                            alt="Location Icon"
                                            className="w-5 h-5 mr-2"
                                        />
                                        <strong>Location:</strong> {selectedProject.location}
                                    </li>
                                    <li className="lato-regular text-[#4F6370] flex items-center">
                                        <img
                                            src="https://res.cloudinary.com/df3ok5t8p/image/upload/v1724805183/novasync/fc3eegc3ofrglic4erdh.png"
                                            alt="Client Icon"
                                            className="w-5 h-5 mr-2"
                                        />
                                        <strong>Client:</strong> {selectedProject.client}
                                    </li>
                                    <li className="lato-regular text-[#4F6370] flex items-center">
                                        <img
                                            src="https://res.cloudinary.com/df3ok5t8p/image/upload/v1724805222/novasync/ldsypsihptraeadz4zaj.png"
                                            alt="Project Type Icon"
                                            className="w-5 h-5 mr-2"
                                        />
                                        <strong>Project Type:</strong> {selectedProject.projectType}
                                    </li>
                                    <li className="lato-regular text-[#4F6370] flex items-center">
                                        <img
                                            src="https://res.cloudinary.com/df3ok5t8p/image/upload/v1724805223/novasync/eci8najpcwwtn3zdyacj.png"
                                            alt="Duration Icon"
                                            className="w-5 h-5 mr-2"
                                        />
                                        <strong>Duration:</strong> {selectedProject.duration}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    )}
                </div>


                {selectedProject.screenshots && selectedProject.screenshots.length > 0 && (
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
                        {selectedProject.screenshots.map((screenshot, index) => (
                            <div
                                key={index}
                                className="cursor-pointer bg-white rounded-lg shadow-md hover:shadow-lg transition duration-300"
                                onClick={() => window.open(screenshot.url, '_blank')}
                            >
                                <img
                                    src={screenshot.thumbnail}
                                    alt={`Thumbnail for ${screenshot.title}`}
                                    className="rounded-t-lg w-full h-48 object-cover"
                                />
                                <div className="p-4">
                                    <h5 className="text-lg font-semibold text-[#4F6370]">{screenshot.title}</h5>
                                </div>
                            </div>
                        ))}
                    </div>
                )}

                <div className="  grid grid-cols-1 w-full md:grid-cols-2 gap-6 mb-8">
                    <div className="w-full lg:w-[200%] wpo-project-single-main-img">
                        <div className="thumbnail">
                            <img src={selectedProject.ps1img} alt="" className="w-full"/>
                        </div>
                    </div>
                </div>


                {/*------------------------------------------------------------------------------------------------------------------ */}
                <div>
                    <div className="res-m-10 ml-10 mr-10 mb-8">
                        <h4 className="text-3xl font-semibold text-[#0EA288] mb-4">Our Strategies</h4>
                        <p className="text-lg text-justify text-[#4F6370]">
                            {selectedProject?.strategy || "Strategy content is not available."}
                        </p>
                    </div>

                    {/* Project Explanation */}
                    {selectedProject?.["project-explanation"] && (
                        <div className="res-m-10 ml-10 mr-10 mb-8">
                            <h4 className="text-3xl font-semibold text-[#0EA288] mb-4">
                                {selectedProject["project-explanation"]?.title || "Project Explanation"}
                            </h4>
                            <p className="text-lg text-justify text-[#4F6370] mb-6">
                                {selectedProject["project-explanation"]?.description || "Description not available."}
                            </p>
                            <div className="mt-6 space-y-6">
                                {Object.values(selectedProject["project-explanation"]["key-components"] || {}).map(
                                    (component, index) => (
                                        <div key={index} className="flex flex-col space-y-4">
                                            <h5 className="text-2xl font-semibold text-[#4F6370]">{component?.title || "Component Title"}</h5>
                                            <ul className="list-disc pl-6 space-y-2">
                                                {(component?.points || []).map((point, pointIndex) => (
                                                    <li key={pointIndex}
                                                        className="text-lg text-[#4F6370]">{point || "Point not available."}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    )}

                    {/* Comprehensive Features */}
                    {selectedProject?.["comprehensive-features"] && (
                        <div className="res-m-10 ml-10 mr-10 mb-8">
                            <h4 className="text-3xl font-semibold text-[#0EA288] mb-4">
                                {selectedProject["comprehensive-features"]?.title || "Comprehensive Features"}
                            </h4>
                            <div className="mt-6 space-y-6">
                                {selectedProject["comprehensive-features"].features.map((feature, index) => (
                                    <div key={index} className="flex flex-col space-y-4">
                                        <h5 className="text-2xl font-semibold text-[#4F6370]">{feature?.title || "Feature Title"}</h5>
                                        <ul className="list-disc pl-6 space-y-2">
                                            {(feature?.points || []).map((point, pointIndex) => (
                                                <li key={pointIndex}
                                                    className="text-lg text-[#4F6370]">{point || "Feature point not available."}</li>
                                            ))}
                                        </ul>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    {/* Admin Panel */}
                    {selectedProject?.["amin-title"] && (
                        <div className="res-m-10 ml-10 mr-10 mb-8">
                            <h4 className="text-3xl font-semibold text-[#0EA288] mb-4">
                                {selectedProject["amin-title"] || "Admin Panel"}
                            </h4>
                            <p className="text-lg text-justify text-[#4F6370] mb-6">
                                {selectedProject["admin-des"] || "Admin description not available."}
                            </p>
                            <h5 className="text-2xl font-semibold text-[#4F6370] mt-4">
                                {selectedProject["admin-key"] || "Admin Key Features"}
                            </h5>
                            <ul className="list-disc pl-6 mt-6 space-y-4">
                                {Array.from({length: 6}).map((_, i) => (
                                    <li key={i}>
                                        <h6 className="text-lg font-medium text-[#4F6370]">
                                            {selectedProject[`admin-key-${i + 1}`] || `Key ${i + 1}`}
                                        </h6>
                                        <p className="text-[#4F6370]">{selectedProject[`admin-key-${i + 1}-des`] || "Description not available."}</p>
                                    </li>
                                ))}
                            </ul>
                            <p className="text-lg text-justify text-[#4F6370] mt-8">
                                {selectedProject["admin-overall-sum"] || "Overall summary not available."}
                            </p>
                        </div>
                    )}
                </div>
                {/*--------------------------------------------------------- ---------------------------------------------------------*/}
                <div className="ml-10 mr-10 mb-8">
                    <h4 className="lato-regular text-[#4F6370]  text-2xl font-semibold mb-4">Technologies</h4>
                    <div className="space-y-4 text-[#4F6370]">
                        {selectedProject.technologies.map((tech, index) => (
                            <div key={index} className="text-[#4F6370] flex items-center">
                                <div
                                    className="text-[#4F6370] flex items-center justify-center w-8 h-8 rounded-full bg-green-100">
                                    <svg
                                        className="w-5 h-5 text-green-500"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M5 13l4 4L19 7"
                                        ></path>
                                    </svg>
                                </div>
                                <span className="text-[#4F6370] ml-4 ">{tech}</span>
                            </div>
                        ))}
                    </div>

                </div>
            </div>
            <RelatedProject keyword={keyword} excludeTitle={selectedProject.title}/>
            <div className="wpo-contact-form-area" style={{width: "100%"}}>
                <h2 style={{width: "30%", marginTop: "2%", marginLeft: "22%", position: "absolute"}}
                    className='res-max-w-100 text-[#4F6370] text-2xl lg:text-4xl font-bold leading-[60px]  mb-6'>Have a
                    Similar Idea ?</h2>
                <Contact/>
            </div>
            <Footer/>
        </div>
    );
};

export default ProjectDetailPageOne;
