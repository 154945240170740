import React, { useState } from 'react';

const steps = [
    {
        id: '01',
        title: 'Have a Meetup',
        description: 'We begin by discussing your objectives, understanding your needs, and setting the foundation for our partnership.',
        detailedDescription: 'This initial step is crucial for us to fully understand your requirements. We dive deep into your goals and ensure we are aligned before we proceed further.'
    },
    {
        id: '02',
        title: 'Proposal Writeup',
        description: 'We prepare a detailed proposal, outlining the strategy, technology stack, and timeline required to achieve your goals.',
        detailedDescription: 'After understanding your needs, we put together a comprehensive proposal that includes timelines, milestones, and a clear tech stack that aligns with your goals.'
    },
    {
        id: '03',
        title: 'Milestones Wise Deliverable',
        description: 'We divide the entire proposal into key milestones to execute the project step by step to ensure progress and quality.',
        detailedDescription: 'Breaking down the project into achievable milestones ensures the project remains on track and transparent, allowing you to see steady progress at each stage.'
    },
    {
        id: '04',
        title: 'Maintenance and Support',
        description: 'We provide ongoing support and maintenance to ensure your systems are running smoothly and efficiently.',
        detailedDescription: 'Once the project is completed, we continue to offer support to address any issues, apply updates, and ensure your system is always optimized.'
    },
    {
        id: '05',
        title: 'NovaSync Keeps You in Touch',
        description: 'We continue to collaborate, keeping you informed on security, scalability, and new features to drive your success further.',
        detailedDescription: 'Even after delivery, we keep you engaged through regular updates, ensuring your system scales and adapts to changing requirements.'
    }
];

const ProcessPage = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedStep, setSelectedStep] = useState(null);

    const openModal = (step) => {
        setSelectedStep(step);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedStep(null);
    };

    return (
        <div className="bg-white py-16 px-6 lg:px-24">
            {/* Left Section */}
            <div className="flex flex-col lg:flex-row lg:justify-between">
                <div className="lg:w-1/2 mb-12 lg:mb-0">
                    {/* Heading remains the same */}
                    <h2 className="text-4xl lg:text-5xl font-bold mb-4 text-[#0EA288]">How we work</h2>

                    {/* Adjusted paragraph class for larger font size and line height */}
                    <p className="text-xl leading-relaxed lg:text-2xl lg:leading-loose text-[#4F6370]">
                        Our process is a structured journey that ensures we understand your needs, develop a strategy,
                        and provide ongoing support to help you achieve long-term success.
                    </p>
                </div>

                {/* Right Section with responsive grid */}
                <div className="lg:w-1/2">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        {/* First row with 3 circles */}
                        {steps.slice(0, 3).map((step) => (
                            <div
                                key={step.id}
                                className="group flex flex-col items-center justify-center bg-white border-2 border-[#0EA288] rounded-full w-72 h-72 mx-auto text-center p-8 transition-transform transform hover:scale-105 hover:bg-[#0EA288]"
                                onClick={() => openModal(step)} // Open modal on click
                            >
                <span className="text-4xl font-bold text-[#0EA288] group-hover:text-white">
                  {step.id}
                </span>
                                <h3 className="text-xl font-semibold mt-4 text-[#0EA288] group-hover:text-white">
                                    {step.title}
                                </h3>
                                <p className="mt-2 text-[#4F6370] text-sm group-hover:text-white">
                                    {step.description}
                                </p>
                            </div>
                        ))}

                        {/* Second row with 2 centered circles */}
                        <div className="md:col-span-3 flex flex-col md:flex-row justify-center md:space-x-8 space-y-8 md:space-y-0">
                            {steps.slice(3).map((step) => (
                                <div
                                    key={step.id}
                                    className="group flex flex-col items-center justify-center bg-white border-2 border-[#0EA288] rounded-full w-72 h-72 mx-auto text-center p-8 transition-transform transform hover:scale-105 hover:bg-[#0EA288]"
                                    onClick={() => openModal(step)} // Open modal on click
                                >
                  <span className="text-4xl font-bold text-[#0EA288] group-hover:text-white">
                    {step.id}
                  </span>
                                    <h3 className="text-xl font-semibold mt-4 text-[#0EA288] group-hover:text-white">
                                        {step.title}
                                    </h3>
                                    <p className="mt-2 text-[#4F6370] text-sm  group-hover:text-white">
                                        {step.description}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal */}
            {isModalOpen && selectedStep && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white p-8 rounded-lg w-96">
                        <h2 className="text-2xl font-bold text-[#0EA288] mb-4">{selectedStep.title}</h2>
                        <p className=" text-justify text-lg text-[#4F6370] mb-6">{selectedStep.detailedDescription}</p>
                        <button
                            onClick={closeModal}
                            className="bg-[#0EA288] text-white py-2 px-6 rounded-lg hover:bg-[#0EA288]/90 transition duration-300"
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProcessPage;
