import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaPlus, FaRegEdit, FaTrashAlt } from 'react-icons/fa';
import CeoFahadImage from '../assets/images/CeoFahad.png';
import LogoutIcon from '../assets/images/LogoutIcon.png';
import ZoomIcon from '../assets/images/zoomforcard.png';
import Nav from '../components/Nav';

const UserManagement = () => {
  
  const [users, setUsers] = useState([
    {
      id: 1,
      name: 'Ahmad Sadiq',
      email: 'ahmad@novasyncdynamics.com',
      designation: 'Backend Developer',
      phone: '+92 90320320',
      status: 'active',
    },
    {
      id: 2,
      name: 'Ali Raza',
      email: 'aliraza@novasyncdynamics.com',
      designation: 'Full Stack Developer',
      phone: '+92 90320320',
      status: 'inactive',
    },
    {
      id: 3,
      name: 'Zoya',
      email: 'zoya@novasyncdynamics.com',
      designation: 'Full Stack Developer',
      phone: '+92 90320320',
      status: 'inactive',
    },
  ]);

  const [activeFilter, setActiveFilter] = useState('all');
  const [newUserName, setNewUserName] = useState('');
  const [newUserEmail, setNewUserEmail] = useState('');
  const [newUserDesignation, setNewUserDesignation] = useState('');
  const [newUserPhone, setNewUserPhone] = useState('');
  const [showAddUserForm, setShowAddUserForm] = useState(false); // State to toggle the form visibility

  const handleApprove = (userId) => {
    const newUsers = users.map((user) =>
      user.id === userId ? { ...user, status: 'active' } : user
    );
    setUsers(newUsers);
  };

  const handleReject = (userId) => {
    const newUsers = users.map((user) =>
      user.id === userId ? { ...user, status: 'inactive' } : user
    );
    setUsers(newUsers);
  };

  const handleAddUser = () => {
    setShowAddUserForm(true); // Show the form when "Add User" is clicked
  };

  const handleSaveUser = () => {
    // Create a new user object
    const newUser = {
      id: users.length + 1, // Generate new ID (replace with actual ID generation logic)
      name: newUserName,
      email: newUserEmail,
      designation: newUserDesignation,
      phone: newUserPhone,
      status: 'active', // Assuming new users are active by default
    };

    // Add the new user to the users state
    setUsers([...users, newUser]);

    // Clear input fields after adding user
    setNewUserName('');
    setNewUserEmail('');
    setNewUserDesignation('');
    setNewUserPhone('');

    // Hide the form after saving user
    setShowAddUserForm(false);
  };

  const handleFilterChange = (event) => {
    setActiveFilter(event.target.value);
  };

  const filteredUsers =
    activeFilter === 'all'
      ? users
      : users.filter((user) => user.status === activeFilter);

  const [sidebarOpen, setSidebarOpen] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setSidebarOpen(true);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="flex flex-col md:flex-row min-h-screen bg-gray-100">
      <aside
        className={`fixed z-30 md:z-10 top-0 left-0 md:static transition-transform transform ${
          sidebarOpen ? 'translate-x-0' : '-translate-x-full'
        } w-64 bg-[#0EA288] text-white flex flex-col items-center py-4 h-full md:h-auto`}
      >
        <img
          src={CeoFahadImage}
          alt="Fahad Shahzad"
          className="w-24 h-24 rounded-full mb-4"
        />
        <h2 className="text-xl font-bold mb-2">Fahad Shahzad</h2>
        <p className="mb-8">CEO</p>
        <nav className="flex flex-col space-y-4 w-full px-4">
          <Link to="/dashboard">
            <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">
              Home
            </button>
          </Link>
          <Link to="/attendance">
            <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">
              Attendance
            </button>
          </Link>
          <Link to="/leaverequest">
            <button className="w-full py-2 bg-white border-2 border-[#0EA288] rounded hover:bg-[#0EA288] hover:text-white relative font-bold text-[#0EA288]">
              Leave Requests
              <span
                className="absolute -top-1 -right-1 bg-[#0EA288] text-white rounded-full flex items-center justify-center border-2 border-white"
                style={{ width: '24px', height: '24px', lineHeight: '22px' }}
              >
                2
              </span>
            </button>
          </Link>
          <Link to="/salaries">
            <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">
              Salaries
            </button>
          </Link>
          <button className="w-full py-2 bg-white border-2 border-[#0C8B76] rounded hover:bg-white font-bold text-black">
            Users Management
          </button>
          <Link to="/all-projects">
          <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">
            Project Management
          </button>
          </Link>
          <Link to="/all-customer">
            <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">Customers</button>
          </Link>
          <Link to="/reports">
          <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">
            Reports
          </button>
          </Link>
          <button className="w-full py-2 bg-white rounded hover:bg-[#0C8B76] font-bold text-[#0EA288]">
            Promotion
          </button>
        </nav>
        <div className="flex-1 flex flex-col justify-end w-full px-4 mb-4">
          <img src={LogoutIcon} alt="logout-icon" className="w-6 h-6 mb-2" />
        </div>
      </aside>

      <main className="flex-1 p-4 md:p-8 transition-all duration-300">
        <header className="mb-8">
        <div className="flex justify-end items-center mb-4">
          <Nav mainHeading="User Management Portal" />
            <button
              className=" md:hidden fixed top-4 right-4 z-50 bg-white text-[#0EA288] rounded-full p-3 shadow-lg"
              onClick={() => setSidebarOpen(!sidebarOpen)}
            >
              <FaBars size={24} />
            </button>
              </div>
        </header>

        <header className="mb-8 flex justify-between items-center">
          <div className="flex items-center justify-start">
            <select
              value={activeFilter}
              onChange={handleFilterChange}
              className="border rounded px-3 py-2 focus:outline-none  font-bold text-black bg-[#0EA288] bg-opacity-60"
            >
              <option value="all">All Users</option>
              <option value="active">Active Users</option>
              <option value="inactive">Inactive Users</option>
            </select>
          </div>

          <div>
            <Link to="/add-user">
            <button
              className="flex items-center justify-center w-8 h-8 bg-[#0EA288] text-white rounded-full hover:bg-green-700 font-bold"
            >
              <FaPlus size={18} />
            </button>
            </Link>
          </div>
        </header>

        {/* User Cards */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {filteredUsers.map((user) => (
            <div
              key={user.id}
              className="bg-white rounded shadow-md p-4 flex flex-col border border-[#0EA288] w-full h-auto sm:w-72 sm:h-80 md:w-96 md:h-90"
            >
              <div className="flex items-center justify-center mb-8">
                <div className="flex flex-col items-center justify-center">
                  <div className="w-24 h-24 rounded-full overflow-hidden">
                    {/* Placeholder for user image */}
                    <img
                      src={`https://i.pravatar.cc/150?u=${user.id}`} // Example placeholder image
                      alt={user.name}
                      className="w-full h-full object-cover bg-[#D9D9D9]"
                    />
                  </div>
                  <h3 className="text-lg font-bold text-[#0EA288] mt-2 font-normal">
                    {user.name}
                  </h3>
                </div>
              </div>
              <div className="text-left mb-2 font-normal">
                <p className="text-sm text-black">{user.designation}</p>
                <p className="text-sm text-black">{user.phone}</p>
                <p className="text-sm text-black">{user.email}</p>
              </div>
              <div className="flex justify-between mt-auto">
              <Link to={`/user-details`}>
                <button className="flex items-center justify-center w-10 h-10 text-[#0EA288] ">
                  
                  <img
                    src={ZoomIcon}
                    alt="zoom-icon"
                    className="w-6 h-6 mb-2"
                  />
            
                </button>
                </Link>
                
                <div className="flex">
                <Link to={`/edit-user`}>
                  <button className="flex items-center justify-center w-10 h-10 text-[#0EA288] ">
                    <FaRegEdit size={18} />
                  </button>
                  </Link>
                  <button className="flex items-center justify-center w-10 h-10  text-red-700 ">
                    <FaTrashAlt size={18} />
                  </button>
                </div>
              </div>
            </div>
          ))}

          {/* Display new user form if showAddUserForm is true */}
          {showAddUserForm && (
            <div className="bg-white rounded shadow-md p-4 flex flex-col border border-[#0EA288] w-full h-auto sm:w-72 sm:h-80 md:w-96 md:h-90">
              <input
                type="text"
                placeholder="Name"
                value={newUserName}
                onChange={(e) => setNewUserName(e.target.value)}
                className="border rounded px-3 py-2 mb-2 focus:outline-none"
              />
              <input
                type="email"
                placeholder="Email"
                value={newUserEmail}
                onChange={(e) => setNewUserEmail(e.target.value)}
                className="border rounded px-3 py-2 mb-2 focus:outline-none"
              />
              <input
                type="text"
                placeholder="Designation"
                value={newUserDesignation}
                onChange={(e) => setNewUserDesignation(e.target.value)}
                className="border rounded px-3 py-2 mb-2 focus:outline-none"
              />
              <input
                type="text"
                placeholder="Phone"
                value={newUserPhone}
                onChange={(e) => setNewUserPhone(e.target.value)}
                className="border rounded px-3 py-2 mb-2 focus:outline-none"
              />
              <button
                className="bg-[#0EA288] text-white py-2 px-4 rounded mt-2 hover:bg-green-700 font-bold"
                onClick={handleSaveUser}
              >
                Save
              </button>
            </div>
          )}
        </div>
      </main>
    </div>
  );
};

export default UserManagement;
